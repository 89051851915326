import { Drawer, Input, Menu, Popover } from "antd";
import { ReactNode, useEffect, useState } from "react";
import {
  Link,
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useWindowDimensions } from "../home/home";
import {
  MenuOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { filterByKeyword, setSearchKeyword } from "../state/productsSlice";
import { useSelector } from "react-redux";
import { RootState } from "../state/store";
import useIsHomepage from "../../hooks/useIsHomePage";
import UserRegisterProccessModal from "../../user/register-modal.wrapper.";
import {
  useAppDispatch,
  useAppSelector,
} from "../../hooks/useAppDispatchSelector";
import { getInitials } from "../../util/util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faCartShopping,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { setopenLoginPopup, userLogout } from "../state/userAuthSlice";
import CartPopoverContent from "./cartPopover";
export type HeaderRoutesType =
  | {
      key: string;
      submenu?: false;
      label: string | ReactNode;
      route: string;
      icon?: React.ReactNode;
    }
  | {
      key: string;
      submenu: true;
      label: string | ReactNode;
      children: HeaderRoutesType[];
      route?: string;
      icon?: React.ReactNode;
    };

const ProfileItem = ({
  title,
  icon,
  onClick,
}: {
  title: string;
  icon: IconDefinition;
  onClick?: () => void;
}) => {
  return (
    <div
      onClick={() => {
        onClick && onClick();
      }}
      className="flex justify-between items-center cursor-pointer hover:bg-[rgba(0,0,0,0.1)] p-4"
    >
      <p className="text-xl">{title}</p>
      <FontAwesomeIcon icon={icon} width={14} />
    </div>
  );
};

export default function Header() {
  const location = useLocation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const openRegistrationForm = useAppSelector(
    (state) => state.userAuthenticationReducer.openLoginPopup
  );
  const isHomepage = useIsHomepage();
  const dispatch = useAppDispatch();
  const searchKeyword = useSelector(
    (state: RootState) => state.productsReducer.searchKeyword
  );
  const products = useSelector(
    (state: RootState) => state.productsReducer.defaultProducts
  );
  const { totalItemsCount, cartItems } = useSelector(
    (state: RootState) => state.CartReducer
  );
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (totalItemsCount > 0) {
      // Trigger animation
      setAnimate(true);

      // Remove animation class after the animation is done
      const timer = setTimeout(() => setAnimate(false), 300); // Duration matches animation duration
      return () => clearTimeout(timer);
    }
  }, [totalItemsCount]);

  const { width } = useWindowDimensions();
  const [openDrawer, setOpenDrawer] = useState(false);
  const closeDrawer = () => {
    setOpenDrawer(false);
  };
  const linksStyles: { color?: string } = { color: "#383f51" };
  let headerIconsStyling =
    "p-4 rounded-full border-white border flex items-center justify-center w-16 h-16 cursor-pointer ";

  if (isHomepage) {
    linksStyles.color = "white";
  } else {
    headerIconsStyling = headerIconsStyling + "!border-black";
  }
  if (isHomepage && width < 1024) {
    linksStyles.color = "#383f51";
  }
  const menuItems: HeaderRoutesType[] = [
    {
      key: "allproducts",
      route: "/products/allproducts",
      label: (
        <Link style={linksStyles} to={"/products/allproducts"}>
          All Products
        </Link>
      ),
    },
    {
      key: "bodyParts",
      route: "/products/bodyParts",
      label: (
        <Link style={linksStyles} to={"/products/bodyParts"}>
          MG Body Parts
        </Link>
      ),
    },
    {
      key: "lights",
      label: (
        <Link style={linksStyles} to={"/products/lights"}>
          MG Lights
        </Link>
      ),
      route: "/products/lights",
    },
    {
      key: "accessories",
      label: (
        <Link style={linksStyles} to={"/products/accessories"}>
          MG Accessories
        </Link>
      ),
      route: "/products/accessories",
    },
    {
      key: "createEstimationRequest",
      label: (
        <Link style={linksStyles} to={"/estimation-request"}>
          Create Estimation
        </Link>
      ),
      route: "/estimation-request",
    },
    {
      key: "createPartRequest",
      label: (
        <Link style={linksStyles} to={"/part-request"}>
          Request Part
        </Link>
      ),
      route: "/part-request",
    },
    // {
    //   key: "Accessories",
    //   label: <p style={linksStyles}>Accessories</p>,
    //   submenu: true,

    //   children: [
    //     {
    //       key: "menClothes",
    //       label: (
    //         <Link
    //           // style={
    //           //   isHomepage
    //           //     ? { color: "white" }
    //           //     : { color: "black" }
    //           // }
    //           to={"/men-clothes"}
    //         >
    //           Men Clothes
    //         </Link>
    //       ),

    //       route: "/men-clothes",
    //     },
    //     {
    //       key: "womenClothes",
    //       label: (
    //         <Link
    //           // style={
    //           //   isHomepage
    //           //     ? { color: "black" }
    //           //     : { color: "black" }
    //           // }
    //           to={"/women-clothes"}
    //         >
    //           Women Clothes
    //         </Link>
    //       ),
    //       route: "/women-clothes",
    //     },
    //   ],
    // },
    // {
    //   key: "mugs",
    //   label: (
    //     <Link style={linksStyles} to={"/mugs"}>
    //       Mugs
    //     </Link>
    //   ),
    //   route: "/mugs",
    // },
    // {
    //   key: "albums",
    //   label: (
    //     <Link style={linksStyles} to={"/albums"}>
    //       Albums
    //     </Link>
    //   ),
    //   route: "/albums",
    // },
    // {
    //   key: "others",
    //   label: (
    //     <Link style={linksStyles} to={"/others"}>
    //       others
    //     </Link>
    //   ),
    //   route: "/others",
    // },
  ];
  // const menuItems: HeaderRoutesType[] = [
  //   // {
  //   //   key: "allproducts",
  //   //   route: "/allproducts",
  //   //   label: (
  //   //     <Link style={linksStyles} to={"allproducts"}>
  //   //       All Products
  //   //     </Link>
  //   //   ),
  //   // },
  //   // {
  //   //   key: "Body Parts",
  //   //   route: "/bodyParts",
  //   //   label: (
  //   //     <Link style={linksStyles} to={"/bodyParts"}>
  //   //       Body Parts
  //   //     </Link>
  //   //   ),
  //   // },
  //   // {
  //   //   key: "Lights",
  //   //   label: (
  //   //     <Link style={linksStyles} to={"/lights"}>
  //   //       Lights
  //   //     </Link>
  //   //   ),
  //   //   route: "/lights",
  //   // },
  //   // {
  //   //   key: "Accessories",
  //   //   label: (
  //   //     <Link style={linksStyles} to={"/accessories"}>
  //   //       Accessories
  //   //     </Link>
  //   //   ),
  //   //   route: "/accessories",
  //   // },
  //   {
  //     key: "MG",
  //     label: <p style={linksStyles}>MG</p>,
  //     submenu: true,
  //     children: [
  //       {
  //         key: "MG",
  //         label: (
  //           <p
  //             style={{
  //               color: "black !important",
  //             }}
  //           >
  //             MG5
  //           </p>
  //         ),
  //         submenu: true,
  //         children: [
  //           {
  //             key: "menClothes",
  //             label: (
  //               <Link
  //                 // style={
  //                 //   isHomepage
  //                 //     ? { color: "white" }
  //                 //     : { color: "black" }
  //                 // }
  //                 to={"/men-clothes"}
  //               >
  //                 All products
  //               </Link>
  //             ),

  //             route: "/men-clothes",
  //           },
  //           {
  //             key: "menClothes",
  //             label: (
  //               <Link
  //                 // style={
  //                 //   isHomepage
  //                 //     ? { color: "white" }
  //                 //     : { color: "black" }
  //                 // }
  //                 to={"/men-clothes"}
  //               >
  //                 Body Parts
  //               </Link>
  //             ),

  //             route: "/men-clothes",
  //           },
  //           {
  //             key: "menClothes",
  //             label: (
  //               <Link
  //                 // style={
  //                 //   isHomepage
  //                 //     ? { color: "white" }
  //                 //     : { color: "black" }
  //                 // }
  //                 to={"/men-clothes"}
  //               >
  //                 Lights
  //               </Link>
  //             ),

  //             route: "/men-clothes",
  //           },
  //           {
  //             key: "menClothes",
  //             label: (
  //               <Link
  //                 // style={
  //                 //   isHomepage
  //                 //     ? { color: "white" }
  //                 //     : { color: "black" }
  //                 // }
  //                 to={"/men-clothes"}
  //               >
  //                 Accessories
  //               </Link>
  //             ),

  //             route: "/men-clothes",
  //           },
  //         ],
  //       },
  //     ],
  //   },
  //   // {
  //   //   key: "mugs",
  //   //   label: (
  //   //     <Link style={linksStyles} to={"/mugs"}>
  //   //       Mugs
  //   //     </Link>
  //   //   ),
  //   //   route: "/mugs",
  //   // },
  //   // {
  //   //   key: "albums",
  //   //   label: (
  //   //     <Link style={linksStyles} to={"/albums"}>
  //   //       Albums
  //   //     </Link>
  //   //   ),
  //   //   route: "/albums",
  //   // },
  //   // {
  //   //   key: "others",
  //   //   label: (
  //   //     <Link style={linksStyles} to={"/others"}>
  //   //       others
  //   //     </Link>
  //   //   ),
  //   //   route: "/others",
  //   // },
  // ];
  const isActive = (menuItem: HeaderRoutesType) => {
    if (menuItem?.submenu) {
      return menuItem.children.some((Item) => Item.route === location.pathname);
    }
    return location.pathname === menuItem.route;
  };
  const { userAuthenticated, user } = useAppSelector(
    (state) => state.userAuthenticationReducer
  );

  const { Search } = Input;
  useEffect(() => {
    dispatch(
      setSearchKeyword(
        searchParams.get("keyword") === null
          ? undefined
          : searchParams.get("keyword")
      )
    );
    dispatch(filterByKeyword());
  }, [location.search]);
  useEffect(() => {
    // Because of filtering on frontend only, initially the products are empty array so i have to re-filter when the products are fetched,, this case happends when refreshing the page
    dispatch(filterByKeyword());
  }, [products]);

  return (
    <>
      <header
        className={
          isHomepage
            ? "w-full top-0 mx-auto z-30  bg-backdrop text-white py-5 px-10 fixed"
            : "w-full top-0 mx-auto border-b-1 py-5 px-10 fixed bg-white z-10 text-gray_primary"
        }
      >
        <div className="container mx-auto flex items-center sm:w-full justify-between">
          <Link
            className="text-2xl w-max sm:w-48 font-semibold italic"
            to={"/"}
          >
            <img
              className="w-32 mt-4"
              src={
                isHomepage ? "/images/logo-white.svg" : "/images/logo-red.svg"
              }
              alt="logo"
            />
          </Link>
          {width >= 1024 && (
            <div className="flex items-center justify-between w-full ">
              <Menu
                selectedKeys={menuItems.filter(isActive).map((item) => {
                  if (item.submenu) {
                    const filteredItem: HeaderRoutesType | undefined =
                      item.children.find(
                        (item) => item.route === location.pathname
                      );
                    if (filteredItem) {
                      return filteredItem.key;
                    }
                  }

                  return item.key;
                })}
                mode="horizontal"
                className="menu text-red-100 gap-2  bg-transparent flex justify-center  h-28 items-center w-full"
                items={menuItems.map((menuItem) => {
                  return {
                    ...menuItem,
                    submenu: undefined,
                  };
                })}
              ></Menu>
              {/* <Search
              value={searchKeyword ? searchKeyword : ""}
              placeholder="Search for items"
              className={"searchButton ml-0 xl:ml-10"}
              enterButton="Search"
              onSearch={(e) => {
                if (searchKeyword) {
                  navigate({
                    pathname: "/allproducts",
                    search: createSearchParams({
                      keyword: searchKeyword,
                    }).toString(),
                  });
                } else navigate(`/allproducts`);
              }}
              onChange={(e) => {
                if (e.target.value.trim().length === 0) {
                  dispatch(setSearchKeyword(undefined));
                  return;
                }
                dispatch(setSearchKeyword(e.target.value));
              }}
            /> */}
              <div className="flex items-center gap-3 ml-4 search-popover">
                {/* Search icon */}
                <Popover
                  overlayInnerStyle={{
                    padding: "6px",
                  }}
                  content={
                    <Search
                      value={searchKeyword ? searchKeyword : ""}
                      placeholder="Search for items"
                      className={"searchButton "}
                      enterButton="Search"
                      onSearch={(e) => {
                        if (searchKeyword) {
                          navigate({
                            pathname: "/products/allproducts",
                            search: createSearchParams({
                              keyword: searchKeyword,
                            }).toString(),
                          });
                        } else navigate(`/products/allproducts`);
                      }}
                      onChange={(e) => {
                        if (e.target.value.trim().length === 0) {
                          dispatch(setSearchKeyword(undefined));
                          return;
                        }
                        dispatch(setSearchKeyword(e.target.value));
                      }}
                    />
                  }
                  trigger="click"
                >
                  <div className="relative">
                    <div className={headerIconsStyling}>
                      <SearchOutlined className="text-3xl" />
                    </div>
                  </div>
                </Popover>

                <Popover
                  overlayInnerStyle={{
                    padding: "12px 0",
                  }}
                  content={<CartPopoverContent cartItems={cartItems} />}
                  trigger="click"
                >
                  {/* Cart icon */}
                  <div className="relative">
                    <div className={headerIconsStyling}>
                      <ShoppingCartOutlined className="text-3xl" />
                      <div
                        className={`min-w-9 min-h-9 p-1 rounded-full absolute -top-5 -right-2 flex items-center justify-center ${
                          isHomepage ? "bg-white" : "bg-blue_primary"
                        } ${animate ? "animate-scale" : ""}`}
                      >
                        <p
                          className={`${
                            isHomepage ? "text-black font-bold" : "text-white"
                          } text-lg  `}
                        >
                          {totalItemsCount}
                        </p>
                      </div>
                    </div>
                  </div>
                </Popover>
                {/* User icon */}
                <div className="relative">
                  {userAuthenticated ? (
                    <Popover
                      overlayInnerStyle={{
                        padding: "0px",
                        borderRadius: "4px",
                      }}
                      content={
                        <div className="w-52 h-max flex flex-col py-4">
                          {/* <ProfileItem icon={faGear} title="Profile" /> */}
                          <ProfileItem
                            icon={faCartShopping}
                            title="My Orders"
                            onClick={() => {
                              navigate(`/orders`);
                            }}
                          />

                          <div className="w-full h-[1px] bg-[rgba(0,0,0,0.2)] my-2" />
                          <ProfileItem
                            icon={faArrowRightFromBracket}
                            title="Logout"
                            onClick={() => {
                              dispatch(userLogout());
                            }}
                          />
                        </div>
                      }
                      trigger="click"
                    >
                      <div className={headerIconsStyling}>
                        <p className="text-2xl">
                          {user && getInitials(user?.name)}
                        </p>
                      </div>
                    </Popover>
                  ) : (
                    <div
                      className={headerIconsStyling}
                      onClick={() => {
                        dispatch(setopenLoginPopup({ state: true }));
                      }}
                    >
                      <UserOutlined className="text-3xl" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {width < 1024 && (
            <div className="flex flex-col flex-1 min-h-[50px]">
              <div className="flex flex-1 items-center justify-end">
                <div
                  className=" cursor-pointer w-max sm:w-24 text-right"
                  onClick={() => {
                    setOpenDrawer(true);
                  }}
                >
                  <MenuOutlined className="text-3xl mt-2 sm:mt-0 " />
                </div>
              </div>
              <Drawer
                closeIcon={<MenuOutlined />}
                placement="top"
                open={openDrawer}
                onClose={() => {
                  closeDrawer();
                }}
                style={{
                  width: "100vw ",
                  height: "max-content",
                }}
              >
                <Menu
                  selectedKeys={menuItems.filter(isActive).map((item) => {
                    if (item.submenu) {
                      const filteredItem: HeaderRoutesType | undefined =
                        item.children.find(
                          (item) => item.route === location.pathname
                        );
                      if (filteredItem) {
                        return filteredItem.key;
                      }
                    }
                    return item.key;
                  })}
                  mode="vertical"
                  className="menu  gap-2 flex-col bg-transparent flex h-max items-start w-full "
                  onClick={(e) => {
                    closeDrawer();
                  }}
                  items={menuItems.map((menuItem) => {
                    return {
                      ...menuItem,
                      submenu: undefined,
                    };
                  })}
                ></Menu>
              </Drawer>
              <div className="flex items-center gap-3 search-popover absolute right-0 sm:left-0 left-16 mx-auto w-max justify-center top-8">
                <Popover
                  overlayInnerStyle={{
                    padding: "6px",
                  }}
                  content={
                    <Search
                      value={searchKeyword ? searchKeyword : ""}
                      placeholder="Search for items"
                      className={"searchButton "}
                      enterButton="Search"
                      onSearch={() => {
                        if (searchKeyword) {
                          navigate({
                            pathname: "/products/allproducts",
                            search: createSearchParams({
                              keyword: searchKeyword,
                            }).toString(),
                          });
                        } else navigate(`/products/allproducts`);
                      }}
                      onChange={(e) => {
                        if (e.target.value.trim().length === 0) {
                          dispatch(setSearchKeyword(undefined));
                          return;
                        }
                        dispatch(setSearchKeyword(e.target.value));
                      }}
                    />
                  }
                  trigger="click"
                >
                  <div className="relative">
                    <div className={headerIconsStyling}>
                      <SearchOutlined className="text-3xl" />
                    </div>
                  </div>
                </Popover>
                <Popover
                  overlayInnerStyle={{
                    padding: "12px 0",
                  }}
                  content={<CartPopoverContent cartItems={cartItems} />}
                  trigger="click"
                >
                  {/* Cart icon */}
                  <div className="relative">
                    <div className={headerIconsStyling}>
                      <ShoppingCartOutlined className="text-3xl" />
                      <div
                        className={`min-w-9 min-h-9 p-1 rounded-full absolute -top-5 -right-2 flex items-center justify-center ${
                          isHomepage ? "bg-white" : "bg-blue_primary"
                        } ${animate ? "animate-scale" : ""}`}
                      >
                        <p
                          className={`${
                            isHomepage ? "text-black font-bold" : "text-white"
                          } text-lg  `}
                        >
                          {totalItemsCount}
                        </p>
                      </div>
                    </div>
                  </div>
                </Popover>
                {/* User icon */}
                <div className="relative">
                  {userAuthenticated ? (
                    <Popover
                      overlayInnerStyle={{
                        padding: "0px",
                        borderRadius: "4px",
                      }}
                      content={
                        <div className="w-52 h-max flex flex-col py-4">
                          {/* <ProfileItem icon={faGear} title="Profile" /> */}
                          <ProfileItem
                            icon={faCartShopping}
                            title="My Orders"
                            onClick={() => {
                              navigate(`/orders`);
                            }}
                          />

                          <div className="w-full h-[1px] bg-[rgba(0,0,0,0.2)] my-2" />
                          <ProfileItem
                            icon={faArrowRightFromBracket}
                            title="Logout"
                            onClick={() => {
                              dispatch(userLogout());
                            }}
                          />
                        </div>
                      }
                      trigger="click"
                    >
                      <div className={headerIconsStyling}>
                        <p className="text-2xl">
                          {user && getInitials(user?.name)}
                        </p>
                      </div>
                    </Popover>
                  ) : (
                    <div
                      className={headerIconsStyling}
                      onClick={() => {
                        dispatch(setopenLoginPopup({ state: true }));
                      }}
                    >
                      <UserOutlined className="text-3xl" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </header>

      <UserRegisterProccessModal open={openRegistrationForm} />
    </>
  );
}
