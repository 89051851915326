import { Button, Form, Input } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../hooks/useAppDispatchSelector";
import {
  clearErrorMessage,
  resetSuccessVariable,
  userResetPasswordThunk,
} from "../state/userResetPasswordSlice";
import { BackendStringResponses } from "../../util/constants";
import { useEffect } from "react";
import { CheckOutlined } from "@ant-design/icons";
const ResetPasswordForm = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [resetPassword] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const errorMessage = useAppSelector(
    (state) => state.userResetPasswordReducer.errorMessage
  );
  const loading = useAppSelector(
    (state) => state.userResetPasswordReducer.loadingResetPassword
  );
  const success = useAppSelector(
    (state) => state.userResetPasswordReducer.successResetPassword
  );
  useEffect(() => {
    dispatch(resetSuccessVariable());
  }, []);
  const submitForm = () => {
    resetPassword
      .validateFields()
      .then(
        async (values: { newPassword: string; confirmPassword: string }) => {
          dispatch(
            userResetPasswordThunk({
              token: token || "",
              newPassword: values.newPassword,
              confirmPassword: values.confirmPassword,
            })
          );
        }
      )
      .catch((err) => {});
  };
  useEffect(() => {
    console.log(success);
  }, [success]);
  return (
    <div>
      <p className="text-center text-4xl font-semibold capitalize mb-20">
        Reset password
      </p>
      {success ? (
        <div className="flex flex-col items-center bg-gray-50 rounded-lg py-10 gap-5  mx-auto mt-44">
          <div className="flex items-center justify-center border p-3 rounded-full border-blue_primary">
            <CheckOutlined className="text-5xl text-blue_primary" />
          </div>
          <p className="text-4xl font-semibold capitalize">Password Reset</p>
          <p className="text-2xl font text-center">
            Password reset successfully! , You can now login with the new
            password
          </p>
          <Button
            onClick={() => {
              navigate("/products/allproducts");
            }}
            className="bg-blue_primary text-white flex items-center justify-between gap-3 w-max "
          >
            <p>Shop Now</p>
          </Button>
        </div>
      ) : (
        <Form form={resetPassword} onFinish={submitForm}>
          <Form.Item
            labelCol={{ span: 24 }}
            name={"newPassword"}
            className="flex-1"
            label="Password"
            required
            rules={[
              {
                type: "string",
                required: true,
                message: "Password field is required ",
              },
            ]}
          >
            <Input.Password
              onChange={() => {
                dispatch(clearErrorMessage());
              }}
              placeholder="Password"
              className="formInput !rounded-lg"
            />
          </Form.Item>
          <div className="relative">
            <Form.Item
              labelCol={{ span: 24 }}
              name={"confirmPassword"}
              className="flex-1"
              label="Confirm Password"
              required
              rules={[
                {
                  type: "string",
                  required: true,
                  message: "Confirm Password field is required ",
                },
              ]}
            >
              <Input.Password
                onChange={() => {
                  dispatch(clearErrorMessage());
                }}
                placeholder="Confirm password"
                className="formInput !rounded-lg"
              />
            </Form.Item>
            {errorMessage &&
            (errorMessage === BackendStringResponses.INVALID_RESET_TOKEN ||
              errorMessage ===
                BackendStringResponses.FORGET_EMAIL_NOT_EXIST) ? (
              <p className=" absolute -bottom-10 text-lg text-red_primary">
                Reset email has expired. Please click on{" "}
                <span
                  className="text-blue_primary cursor-pointer font-bold uppercase"
                  onClick={() => {
                    navigate("/forget-password");
                  }}
                >
                  forgot password
                </span>{" "}
                again.
              </p>
            ) : errorMessage === BackendStringResponses.PASSWORD_MISMATCH ? (
              <p className=" absolute -bottom-10 text-lg text-red_primary">
                Passwords do not match
              </p>
            ) : errorMessage === BackendStringResponses.SHORT_PASSWORD ? (
              <p className=" absolute -bottom-10 text-lg text-red_primary">
                Password must include at least one letter, one number, and one
                special character
              </p>
            ) : null}
          </div>

          <Button
            loading={loading}
            className=" px-10 w-max flex items-center justify-center relative z-10 h-full bg-none border-none text-white hover:!text-white disabled:!text-[rgba(0,0,0,0.25)] text-lg font-medium cursor-pointer rounded-3xl bg-blue_primary py-5 hover:!bg-blue_primary disabled:!bg-[rgba(0,0,0,0.04)] mx-auto mt-20"
            onClick={() => {
              resetPassword.submit();
            }}
          >
            <p className=" text-2xl">Change Password</p>
          </Button>
        </Form>
      )}
    </div>
  );
};
export default ResetPasswordForm;
