import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ErrorObject } from "./errorSlice";
import { fetchRequest } from "../../util/util";

type FetchResponse = {
  message: string;
  error?: ErrorObject;
  success: boolean;
};

type UserResetPasswordState = {
  loadingResetPassword: boolean;
  loadingForgetPassword: boolean;
  errorMessage?: string;
  successResetPassword: boolean;
  successForgetPassword: boolean;
};
const initialState: UserResetPasswordState = {
  loadingResetPassword: false,
  loadingForgetPassword: false,
  errorMessage: undefined,
  successResetPassword: false,
  successForgetPassword: false,
};

const userResetPassword = createSlice({
  name: "UserResetPassword",
  initialState: initialState,
  reducers: {
    clearErrorMessage: (state) => {
      state.errorMessage = undefined;
    },
    resetSuccessVariable: (state) => {
      state.successForgetPassword = false;
      state.successResetPassword = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userResetPasswordThunk.pending, (state) => {
      state.loadingResetPassword = true;
      state.errorMessage = undefined;
      state.successResetPassword = false;
    });
    builder.addCase(
      userResetPasswordThunk.fulfilled,
      (state, action: PayloadAction<FetchResponse>) => {
        state.loadingResetPassword = false;
        if (action.payload.error) {
          state.errorMessage = action.payload.error.message;
          return;
        }
        state.successResetPassword = action.payload.success;
        state.errorMessage = undefined;
      }
    );
    builder.addCase(userResetPasswordThunk.rejected, (state) => {
      state.loadingResetPassword = false;
    });
    builder.addCase(forgetPasswordThunk.pending, (state) => {
      state.loadingForgetPassword = true;
      state.errorMessage = undefined;
      state.successForgetPassword = false;
    });
    builder.addCase(
      forgetPasswordThunk.fulfilled,
      (state, action: PayloadAction<FetchResponse>) => {
        state.loadingForgetPassword = false;
        if (action.payload.error) {
          state.errorMessage = action.payload.error.message;
          return;
        }
        state.successForgetPassword = action.payload.success;
        state.errorMessage = undefined;
      }
    );
    builder.addCase(forgetPasswordThunk.rejected, (state) => {
      state.loadingForgetPassword = false;
    });
  },
});
export const userResetPasswordThunk = createAsyncThunk(
  "resetPassword/userResetPassword",
  async (
    {
      token,
      confirmPassword,
      newPassword,
    }: { token: string; newPassword: string; confirmPassword: string },
    { dispatch }
  ) => {
    const data: FetchResponse = await fetchRequest<FetchResponse>({
      url: `${process.env.REACT_APP_BACKEND_DOMAIN}/reset-password`,
      method: "POST",
      body: { token, confirmPassword, newPassword },
      dispatch,
    });
    return data;
  }
);
export const forgetPasswordThunk = createAsyncThunk(
  "resetPassword/userForgetPassword",
  async ({ email }: { email: string }, { dispatch }) => {
    const data: FetchResponse = await fetchRequest<FetchResponse>({
      url: `${process.env.REACT_APP_BACKEND_DOMAIN}/forget-password`,
      method: "POST",
      body: { email },
      dispatch,
    });
    return data;
  }
);
export const { clearErrorMessage, resetSuccessVariable } =
  userResetPassword.actions;
export const userResetPasswordReducer = userResetPassword.reducer;
