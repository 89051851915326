import { Button, Select, Spin } from "antd";
import { EstimationStatus } from "../util/adminTypes";
import { useEffect, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../hooks/useAppDispatchSelector";
import {
  editDetailedEstimationsThunk,
  getDetailedEstimationsThunk,
} from "../components/state/adminEstimationSlice";
import { LoadingOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import Carousell from "../components/carousel";
import { formatDate } from "../util/util";
const ViewingContent = ({ text, label }: { text: string; label: string }) => {
  return (
    <div className="w-full flex flex-col gap-2">
      <p className="text-blue_primary text-xl font-semibold">{label}</p>
      <div className="w-full border p-3 rounded-xl border-blue_primary text-black_primary font-semibold">
        <p className="text-2xl">{text}</p>
      </div>
    </div>
  );
};
const AdminDetailedEstimation = () => {
  const dispatch = useAppDispatch();
  const params = useParams();

  const estimationDetails = useAppSelector(
    (state) => state.estimationsReducer.estimationDetails
  );
  const loadingDetailedEstimation = useAppSelector(
    (state) => state.estimationsReducer.loadingDetailedEstimation
  );
  const loadingEditingEstimation = useAppSelector(
    (state) => state.estimationsReducer.loadingEditingEstimation
  );
  const [currentEstimationStatus, setcurrentEstimationStatus] = useState(
    estimationDetails?.estimationStatus
  );
  useEffect(() => {
    setcurrentEstimationStatus(estimationDetails?.estimationStatus);
  }, [estimationDetails]);
  useEffect(() => {
    dispatch(
      getDetailedEstimationsThunk({
        id: params.id as string,
      })
    );
  }, []);

  const handleSubmit = () => {
    if (estimationDetails && currentEstimationStatus) {
      dispatch(
        editDetailedEstimationsThunk({
          estimationStatus: currentEstimationStatus,
          id: estimationDetails?._id,
        })
      );
    }
  };
  return (
    <div>
      {!estimationDetails || loadingDetailedEstimation ? (
        <Spin
          indicator={<LoadingOutlined />}
          size="large"
          fullscreen
          className="addProductSpinner"
          style={{
            transform: "scale(1.2)",
          }}
          tip="Loading Estimation..."
          spinning={loadingDetailedEstimation}
        ></Spin>
      ) : (
        <>
          <h2 className="mb-24 text-6xl text-center font-semibold">
            Detailed Estimation
          </h2>

          <div className="container mx-auto flex flex-col gap-10 px-10">
            <div className="flex gap-10">
              <ViewingContent
                label="Customer Name"
                text={estimationDetails.name}
              />
              <ViewingContent
                label="Customer Email"
                text={estimationDetails.email}
              />
            </div>
            <div className="flex gap-10">
              <ViewingContent
                label="Customer Number"
                text={estimationDetails.phoneNumber}
              />
              <div className="w-full flex flex-col gap-2">
                <p className="text-blue_primary text-xl font-semibold">
                  Estimation Status
                </p>
                <div className="w-full flex-1 flex gap-5">
                  <Select
                    className="w-full h-full"
                    options={Object.values(EstimationStatus).map((status) => ({
                      className: "capitalize",
                      label: status.split("_").join(" "),
                      value: status,
                    }))}
                    onChange={(e) => {
                      setcurrentEstimationStatus(e);
                    }}
                    value={currentEstimationStatus}
                  ></Select>
                  <Button
                    loading={loadingEditingEstimation}
                    onClick={() => {
                      handleSubmit();
                    }}
                    className="h-full"
                    disabled={
                      estimationDetails.estimationStatus ===
                      currentEstimationStatus
                    }
                  >
                    Change
                  </Button>
                </div>
              </div>
            </div>
            <div className="flex gap-10">
              <ViewingContent
                label="Description"
                text={estimationDetails.description}
              />
              <ViewingContent
                label="Request Date"
                text={formatDate(estimationDetails.createdAt)}
              />
            </div>
            {estimationDetails.images.length && (
              <div className="w-1/2 h-[300px] flex flex-col items-center mx-auto">
                <p className="mb-10 text-blue_primary text-2xl font-semibold text-center">
                  Images
                </p>
                <Carousell
                  images={estimationDetails.images.map((image) => image.url)}
                />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
export default AdminDetailedEstimation;
