import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../state/store";
import { useParams } from "react-router-dom";
import ProductCard from "./productCard";
import { useEffect, useState } from "react";
import { Products, pagination } from "../state/productsSlice";
import { isArrayIncludeText } from "../../util/util";

export default function FilteredProducts() {
  const [totalPreviewedProducts, setTotalPreviewedProducts] = useState<
    Products[]
  >([]);
  const dispatch = useDispatch<AppDispatch>();
  const products = useSelector(
    (state: RootState) => state.productsReducer.filteredProducts
  );
  const minPriceFilter = useSelector(
    (state: RootState) => state.productsReducer.minPriceFilter
  );
  const maxPriceFilter = useSelector(
    (state: RootState) => state.productsReducer.maxPriceFilter
  );

  const pageNumebr = useSelector(
    (state: RootState) => state.productsReducer.pageNumber
  );
  const endingNumber = pageNumebr * 12;
  const startinNumber = endingNumber - 12;
  const params = useParams() as {
    category: "allproducts" | "bodyParts" | "accessories" | "lights";
  };

  // const [filterValue, setFilterValue] = useState();
  useEffect(() => {
    let filterValuee: string = params.category;
    dispatch(pagination({ pageNumber: 1 }));
    setTotalPreviewedProducts(() => {
      if (maxPriceFilter !== undefined && minPriceFilter !== undefined) {
        return products
          ?.filter((product) => {
            if (filterValuee === "allproducts") return true;
            return isArrayIncludeText({
              arrayStrings: product.category,
              searchTerm: filterValuee,
            });
          })
          ?.filter((product) => {
            let price = product.price;
            if (product.hasDiscount && product.discountPrice) {
              price = product.discountPrice;
            }

            return price >= minPriceFilter && price <= maxPriceFilter;
          });
      }
      return products?.filter((product) => {
        if (filterValuee === "allproducts") return true;

        return isArrayIncludeText({
          arrayStrings: product.category,
          searchTerm: filterValuee,
        });
      });
    });
  }, [products, maxPriceFilter, minPriceFilter, params]);

  const previewedProducts = totalPreviewedProducts?.slice(
    startinNumber,
    endingNumber
  );
  useEffect(() => {
    dispatch(pagination({ productsNumber: totalPreviewedProducts?.length }));
  }, [totalPreviewedProducts]);

  return (
    <>
      {previewedProducts.length ? (
        previewedProducts?.map((product) => {
          return (
            <ProductCard
              imageSource={product.coverPhoto.url}
              product={product}
              key={product._id}
            />
          );
        })
      ) : (
        <>
          <div className="flex items-center justify-center flex-col gap-2 border py-5 rounded-2xl border-dashed">
            <p className="text-2xl">No Products Found</p>
            <p className="text-2xl">Try to change filters</p>
          </div>
        </>
      )}
    </>
  );
}
