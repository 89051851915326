import { Link } from "react-router-dom";
import "./styles/home.css";
import { Button } from "antd";
export default function HomeFirstSection() {
  return (
    <>
      {/* first section */}
      <div className="home_image">
        <div
          className="absolute top-0 w-screen h-full bg-gradient-to-bl from-black_primary to-blue_primary opacity-30 "
          style={{}}
        />
      </div>
      <div className="w-full absolute h-full top-0 right-0 left-0  px-10">
        <div className="flex h-full container mx-auto flex-col justify-center gap-7 w-full  items-center md:items-start">
          <h1 className="z-10 text-[32px] sm:text-7xl md:text-9xl text-white font-semibold  text-center sm:text-left uppercase glow">
            Parts you
            <span className="text-red_primary">&nbsp;need</span>
          </h1>
          <h1 className="z-10 text-[32px] sm:text-7xl md:text-9xl text-white font-semibold  text-center sm:text-left uppercase glow">
            Speed you
            <span className="text-red_primary">&nbsp;deserve</span>
          </h1>
          <div className="flex sm:flex-row flex-col gap-10">
            <Link to={"/products/allproducts"} className="w-[85vw] sm:w-max">
              <Button className="shopButton w-full text-white text-3xl ">
                Shop Now
              </Button>
            </Link>
            <Link to={"/part-request"} className="w-[85vw] sm:w-max">
              <Button className="shopButton w-full text-white text-3xl ">
                Request Part
              </Button>
            </Link>
          </div>
          {/* <Search
            placeholder="Search for items"
            className={"searchButton "}
            enterButton="Search"
          />
        </div> */}
        </div>
      </div>
    </>
  );
}
