import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { RootState } from "../components/state/store";
import { useEffect } from "react";
import { fethcingAdminCredentials } from "../components/state/adminAuthSlice";
import { AppDispatch } from "../components/state/store";
import { ErrorType, clearError } from "../components/state/errorSlice";
import {
  SuccessType,
  clearSuccessMessage,
} from "../components/state/successSlice";
import { message } from "antd";
export default function SuperAdminRoutes() {
  const errorSelector = useSelector<RootState>(
    (state) => state.errorReducer
  ) as ErrorType;
  const successSelector = useSelector<RootState>(
    (state) => state.successReducer
  ) as SuccessType;
  const dispatch = useDispatch<AppDispatch>();
  const [messageApi, contextHolder] = message.useMessage();

  const feedbackMessage = (message: string, type: "success" | "error") => {
    messageApi.open({
      type: type,
      content: message,
    });
  };

  useEffect(() => {
    if (successSelector.success) {
      feedbackMessage(successSelector.success.message, "success");
      dispatch(clearSuccessMessage());
    }
  }, [successSelector]);

  useEffect(() => {
    if (errorSelector.error) {
      feedbackMessage(errorSelector.error.message, "error");
    }
    dispatch(clearError());
  }, [errorSelector]);

  const isAuth = useSelector(
    (state: RootState) => state.adminAuthenticationReducer.authenticated
  );
  const role = useSelector(
    (state: RootState) => state.adminAuthenticationReducer.admin?.role
  );

  return isAuth && role === "SUPER_ADMIN" ? (
    <>
      {contextHolder}
      <Outlet />
    </>
  ) : (
    <Navigate to="/admin/login" />
  );
}
