import { Button, Form, Input, Tooltip } from "antd";
import "./styles/settings.css";
import "./styles/addProduct.css";
import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../components/state/store";
import {
  AdminType,
  updateAdminSettings,
} from "../components/state/adminAuthSlice";

import { validateUserName } from "../util/adminUtil";
import { adminResetPasswordThunk } from "../components/state/adminResetPasswordSlice";

export default function Settings() {
  const [resetPassword, setResetPassword] = useState<boolean>(false);
  const [valuesChanged, setValuesChanged] = useState(false);

  let [form] = Form.useForm();
  let [Passwordform] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const userSelector = useSelector<RootState>(
    (state) => state.adminAuthenticationReducer.admin
  ) as AdminType;
  const loadingResetPasswordButton = useSelector<RootState>(
    (state) => state.adminResetPasswordReducer.loading
  ) as boolean;
  const loadingupdateAdminSettingsdButton = useSelector<RootState>(
    (state) => state.adminAuthenticationReducer.updateAdminSettingsLoading
  ) as boolean;
  const passwordErrorMessage = useSelector<RootState>(
    (state) => state.adminResetPasswordReducer.errorMessage
  ) as string;

  useEffect(() => {
    if (!userSelector) return;
    form.setFieldsValue({
      name: userSelector.name,
    });
  }, [userSelector]);

  const hideResetPassword = (): void => {
    setResetPassword(false);
    form.resetFields(["newPassword", "currentPassword", "confirmPassword"]);
  };
  const validatePassword = (_: any, value: string) => {
    // Password must be at least 8 characters long
    if (!value) {
      return Promise.reject("Password must be at least 8 characters long");
    }
    if (value.length < 8) {
      return Promise.reject("Password must be at least 8 characters long");
    }

    // Check if the password contains at least one lowercase letter
    const lowercaseRegex = /[a-z]/;
    if (!lowercaseRegex.test(value)) {
      return Promise.reject(
        "Password must contain at least one lowercase letter"
      );
    }

    // Check if the password contains at least one uppercase letter
    const uppercaseRegex = /[A-Z]/;
    if (!uppercaseRegex.test(value)) {
      return Promise.reject(
        "Password must contain at least one uppercase letter"
      );
    }

    // Check if the password contains at least one number
    const numberRegex = /[0-9]/;
    if (!numberRegex.test(value)) {
      return Promise.reject("Password must contain at least one number");
    }

    // Check if the password contains at least one special character
    const specialCharacterRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    if (!specialCharacterRegex.test(value)) {
      return Promise.reject(
        "Password must contain at least one special character"
      );
    }
    if (_.field === "confirmPassword") {
      const newPassword = Passwordform.getFieldValue("newPassword");
      if (newPassword !== value) {
        return Promise.reject(
          "New Password and current password must be the same"
        );
      }
    }
    // If all checks pass, the password is valid
    return Promise.resolve();
  };
  const passwordTooltipTitle = (
    <ul className="flex flex-col items-start text-lg gap-2">
      <li>Password must be at least 8 characters length</li>
      <li>Password must contain at least 1 uppercase letter</li>
      <li>Password must contain at least 1 lowercase letter</li>
      <li> Password must contain at least 1 number</li>
      <li>Password must contain at least 1 special character</li>
    </ul>
  );
  const submitResetPassword = () => {
    Passwordform.validateFields().then(
      async (values: {
        currentPasswrod: string;
        newPassword: string;
        confirmPasswords: string;
      }) => {
        await dispatch(adminResetPasswordThunk(values));
      }
    );
  };
  const submitupdateAdminSettings = () => {
    if (!valuesChanged) return;
    form.validateFields().then(async (values: { name: string }) => {
      await dispatch(updateAdminSettings({ name: values.name }));
      setValuesChanged(false);
    });
  };
  return (
    <>
      <h2 className="mb-24 text-6xl text-center font-semibold">
        User Settings
      </h2>
      <Form
        onValuesChange={() => {
          setValuesChanged(true);
        }}
        form={form}
        onFinish={submitupdateAdminSettings}
        className="userSettingsForm container mx-auto flex flex-col"
      >
        <Form.Item
          labelCol={{ span: 24 }}
          className="w-full"
          rules={[
            {
              validator: validateUserName,
            },
          ]}
          required
          name={"name"}
          label="User Name"
        >
          <Input />
        </Form.Item>
        <Form.Item
          labelCol={{ span: 24 }}
          className="w-full"
          // name={"email"}
          label="User Email"
        >
          <div
            className="bg-white w-full  rounded-md"
            style={{
              border: "1px solid #d9d9d9",
              height: "32px",
              cursor: "no-drop",
              padding: "4px 11px",
            }}
          >
            {userSelector?.email}
          </div>
        </Form.Item>
      </Form>
      <div className=" container mx-auto flex flex-col bg-white p-10 rounded-md">
        <h2 className="mb-14 text-4xl text-center font-semibold ">
          Sign-in Mehthods
        </h2>
        <div className="flex gap-10 items-center justify-between ">
          {!resetPassword ? (
            <>
              <div className="text-2xl flex flex-col gap-5">
                <p className="font-semibold">Password</p>
                <p>**************</p>
              </div>
              <Button
                onClick={() => {
                  setResetPassword(true);
                }}
                className="generalAdminButton"
              >
                Reset Password
              </Button>
            </>
          ) : (
            <Form
              onFinish={submitResetPassword}
              form={Passwordform}
              className="flex w-full flex-col "
            >
              <div className="flex w-full gap-10 justify-between">
                <Form.Item
                  labelCol={{ span: 24 }}
                  className="w-full mb-0"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (value && value.trim().length !== 0) {
                          // If the value is not empty and doesn't contain any whitespace
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Please enter your current password")
                        );
                      },
                    },
                  ]}
                  required
                  name={"currentPassword"}
                  label="Current Password"
                >
                  <Input.Password
                    className={passwordErrorMessage ? "border-red-500" : ""}
                  />
                </Form.Item>
                <Tooltip placement="bottom" title={passwordTooltipTitle}>
                  <Form.Item
                    labelCol={{ span: 24 }}
                    className="w-full mb-0"
                    rules={[
                      {
                        validateTrigger: "onSubmit",
                        validator: validatePassword,
                      },
                    ]}
                    required
                    name={"newPassword"}
                    label="New Password"
                  >
                    <Input.Password
                      className={passwordErrorMessage ? "border-red-500" : ""}
                    />
                  </Form.Item>
                </Tooltip>
                <Tooltip placement="bottom" title={passwordTooltipTitle}>
                  <Form.Item
                    labelCol={{ span: 24 }}
                    className="w-full mb-0"
                    rules={[
                      {
                        validateTrigger: "onSubmit",
                        validator: validatePassword,
                      },
                    ]}
                    required
                    name={"confirmPassword"}
                    label="Confirm New Password"
                  >
                    <Input.Password
                      className={passwordErrorMessage ? "border-red-500" : ""}
                    />
                  </Form.Item>
                </Tooltip>
              </div>
              {passwordErrorMessage && (
                <div className="w-max text-red-500 capitalize">
                  <p>{passwordErrorMessage}</p>
                </div>
              )}
              <div className="mt-10 flex items-center gap-5">
                <Button
                  onClick={() => {
                    hideResetPassword();
                  }}
                  className="h-16 w-32"
                >
                  Cancel
                </Button>
                <Button
                  loading={loadingResetPasswordButton}
                  onClick={() => {
                    Passwordform.submit();
                  }}
                  className="generalAdminButton"
                >
                  Update Password
                </Button>
              </div>
            </Form>
          )}
        </div>
      </div>
      <div className="mt-10 flex items-center container mx-auto gap-5 justify-center">
        <Button
          loading={loadingupdateAdminSettingsdButton}
          onClick={() => {
            form.submit();
          }}
          className="generalAdminButton"
        >
          Update Settings
        </Button>
      </div>
    </>
  );
}
