import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clearSuccessMessage, setSuccessMessage } from "./successSlice";
import { clearError, setError } from "./errorSlice";
import {
  uploadMultipeImagesToS3,
  uploadSignleImageToS3,
} from "../../util/adminUtil";
import { FetchResponse, ProductQualityTypes } from "../../util/adminTypes";

export type DatabasePhoto = {
  key?: string;
  url?: string;
  name: string; // Name of the file
};

export type FileItem = {
  key?: string;
  error?: boolean;
  errorMessage?: string;
  uid?: string; // Unique ID of the file
  name: string; // Name of the file
  size?: number; // Size of the file in bytes (optional)
  type?: string; // MIME type of the file (optional)
  url?: string; // URL of the uploaded file (optional, if the file is already uploaded)
  // Add more properties as needed
} & DatabasePhoto;

export type NewProductValues = {
  name: string;
  price: number;
  stockNumber: number;
  description: string;
  additionalInfo?: string;
  category: string[];
  colors: string[];
  discountPrice: number | null;
  hasDiscount: boolean;
  descriptionLanguage: "ar" | "en" | null;
  inStock: boolean;
  coverPhoto: FileItem | null | DatabasePhoto;
  additionalPhotos?: FileItem[] | [] | DatabasePhoto[];
  isShown: boolean;
  productQuality: ProductQualityTypes;
  make: string;
  model: string;
  yearFrom: string;
  yearTo: string;
  productNumber: string;
};

type AddedProduct = {
  values: NewProductValues;
  coverPhoto: FileItem | null;
  additionalPhotos?: FileItem[];
};

export type EditedProductValues = {
  deletedCoverPhoto: string | null;
  deletedAdditionalPhotos: string[] | null;
  id: string;
} & Omit<AddedProduct, "coverPhoto" | "additionalPhotos">;

export type AdminProduct = {
  _id: string;
  updatedAt?: string;
  coverPhoto: DatabasePhoto;
  additionalPhotos?: DatabasePhoto[];
} & NewProductValues;

type AdminProductState = {
  loading: boolean;
  addProductLoading: boolean;
  deleteProductLoading: boolean;
  hideProductLoading: boolean;
  products: AdminProduct[] | [];
};
const initialState: AdminProductState = {
  loading: true,
  products: [],
  addProductLoading: false,
  deleteProductLoading: false,
  hideProductLoading: false,
};

const getAdminProducts = createSlice({
  name: "addProduct",
  initialState: initialState,
  reducers: {
    setProducts(state, action: PayloadAction<string[]>) {},
  },
  extraReducers: (builder) => {
    builder.addCase(getAdminProductsThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getAdminProductsThunk.fulfilled,
      (state, action: PayloadAction<FetchResponse<AdminProduct[] | []>>) => {
        state.loading = false;
        if (action.payload.error) {
          return;
        }
        state.products = action.payload.data;
      }
    );
    builder.addCase(getAdminProductsThunk.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(addProductThunk.pending, (state) => {
      state.addProductLoading = true;
    });

    builder.addCase(
      addProductThunk.fulfilled,
      (state, action: PayloadAction<FetchResponse<AdminProduct[] | []>>) => {
        state.addProductLoading = false;
        if (action.payload.error) {
          return;
        }
        state.products = action.payload.data;
      }
    );
    builder.addCase(addProductThunk.rejected, (state) => {
      state.addProductLoading = false;
    });
    builder.addCase(deleteProductThunk.pending, (state) => {
      state.deleteProductLoading = true;
    });

    builder.addCase(
      deleteProductThunk.fulfilled,
      (state, action: PayloadAction<FetchResponse<AdminProduct[] | []>>) => {
        state.deleteProductLoading = false;
        if (action.payload.error) {
          return;
        }
        state.products = action.payload.data;
      }
    );
    builder.addCase(deleteProductThunk.rejected, (state) => {
      state.deleteProductLoading = false;
    });
    builder.addCase(editProductThunk.pending, (state) => {
      state.addProductLoading = true;
    });

    builder.addCase(
      editProductThunk.fulfilled,
      (state, action: PayloadAction<FetchResponse<AdminProduct[] | []>>) => {
        state.addProductLoading = false;
        if (action.payload.error) {
          return;
        }
        state.products = action.payload.data;
      }
    );
    builder.addCase(editProductThunk.rejected, (state) => {
      state.addProductLoading = false;
    });
    builder.addCase(hideProductThunk.pending, (state) => {
      state.hideProductLoading = true;
    });

    builder.addCase(
      hideProductThunk.fulfilled,
      (state, action: PayloadAction<FetchResponse<AdminProduct[] | []>>) => {
        state.hideProductLoading = false;
        if (action.payload.error) {
          return;
        }
        state.products = action.payload.data;
      }
    );
    builder.addCase(hideProductThunk.rejected, (state) => {
      state.hideProductLoading = false;
    });
  },
});

export const getAdminProductsThunk = createAsyncThunk(
  "adminProducts/getAdminProducts",
  async (_, { dispatch }) => {
    const data: FetchResponse<AdminProduct[] | []> = await fetch(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/admin/products`,
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data: FetchResponse<AdminProduct[] | []>) => {
        if (data.error) {
          throw data;
        }
        return data;
      })
      .catch((err) => {
        dispatch(setError(err));
        return err;
      });

    return data;
  }
);
export const addProductThunk = createAsyncThunk(
  "adminProducts/addProduct",
  async (product: AddedProduct, { dispatch }) => {
    // const formData: any = new FormData();
    // formData.append("coverPhoto", product.coverPhoto);
    // formData.append("data", JSON.stringify(product.values));

    // const allImages = [product.coverPhoto];
    // if (product.additionalPhotos) {
    //   product.additionalPhotos.forEach((photo) => allImages.push(photo));
    // }

    // if (product.additionalPhotos) {
    //   for (let photo of product.additionalPhotos) {
    //     formData.append("additionalPhotos", photo);
    //   }
    // }

    const fileNames = product.additionalPhotos?.map((file) => file?.name);
    const responseCoverImage = await uploadSignleImageToS3({
      image: product.coverPhoto as File,
      fileNames: product.coverPhoto?.name || "",
      imageType: product.coverPhoto?.type || "",
      folderName: "products",
    });
    const uploadAdditionalImages = async () => {
      if (!product.additionalPhotos?.length || !fileNames) return false;
      return await uploadMultipeImagesToS3({
        fileNames: fileNames,
        images: product.additionalPhotos,
        folderName: "products",
      });
    };
    const hasAdditionalImages = await uploadAdditionalImages();
    const bodyData = {
      ...product.values,
      coverPhoto: { url: responseCoverImage.url.split("?")[0] },
      additionalPhotos: hasAdditionalImages
        ? hasAdditionalImages.map((img) => {
            return { url: img?.url.split("?")[0] };
          })
        : [],
    };
    const data: FetchResponse<AdminProduct[] | []> = await fetch(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/admin/add-product`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
        credentials: "include",
        // mode: "cors",
        body: JSON.stringify({ data: bodyData }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data: FetchResponse<AdminProduct[] | []>) => {
        if (data.error) {
          throw data;
        }

        dispatch(setSuccessMessage(data.message));
        dispatch(clearError());
        return data;
      })
      .catch((err) => {
        dispatch(clearSuccessMessage());
        dispatch(setError(err));
        return err;
      });

    return data;
  }
);
export const deleteProductThunk = createAsyncThunk(
  "adminProducts/deleteProduct",
  async (_id: string, { dispatch }) => {
    const data: FetchResponse<AdminProduct[] | []> = await fetch(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/admin/delete-product`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ _id }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data: FetchResponse<AdminProduct[] | []>) => {
        if (data.error) {
          throw data;
        }
        console.log(data);
        dispatch(setSuccessMessage(data.message));
        dispatch(clearError());
        return data;
      })
      .catch((err) => {
        dispatch(clearSuccessMessage());
        dispatch(setError(err));
        return err;
      });

    return data;
  }
);
export const editProductThunk = createAsyncThunk(
  "adminProducts/editProduct",
  async (product: EditedProductValues, { dispatch }) => {
    const modifiedData = product.values;

    if (product.values.coverPhoto instanceof File) {
      const responseCoverImage = await uploadSignleImageToS3({
        fileNames: product.values.coverPhoto.name,
        image: product.values.coverPhoto,
        imageType: product.values.coverPhoto.type,
        folderName: "products",
      });
      modifiedData.coverPhoto = {
        url: responseCoverImage.url.split("?")[0],
      } as DatabasePhoto;
    }
    if (product.values.additionalPhotos) {
      const newImages: FileItem[] = [];
      const oldImages: { url: string }[] = [];
      for (let photo of product.values.additionalPhotos) {
        if (photo instanceof File) {
          newImages.push(photo);
        } else {
          photo.url && oldImages.push({ url: photo.url });
        }
      }
      if (newImages.length > 0) {
        const fileNames = newImages?.map((file) => file?.name);
        const responseAdditionalImages = await uploadMultipeImagesToS3({
          fileNames: fileNames,
          images: newImages,
          folderName: "products",
        });

        const newUrls = responseAdditionalImages.map((img) => {
          return { url: img?.url.split("?")[0] };
        }) as DatabasePhoto[];
        modifiedData.additionalPhotos = [
          ...newUrls,
          ...oldImages,
        ] as DatabasePhoto[];
      }
    }
    const bodyData = {
      ...modifiedData,
      id: product.id,
      deletedCoverPhoto: product.deletedCoverPhoto,
      deletedAdditionalPhotos: product.deletedAdditionalPhotos,
    };
    const data: FetchResponse<AdminProduct[] | []> = await fetch(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/admin/edit-product`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ data: bodyData }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data: FetchResponse<AdminProduct[] | []>) => {
        if (data.error) {
          throw data;
        }

        dispatch(setSuccessMessage(data.message));
        dispatch(clearError());
        return data;
      })
      .catch((err) => {
        dispatch(clearSuccessMessage());
        dispatch(setError(err));
        return err;
      });

    return data;
  }
);
export const hideProductThunk = createAsyncThunk(
  "adminProducts/hideProduct",
  async (product: { isShown: boolean; id: string }, { dispatch }) => {
    const data: FetchResponse<AdminProduct[] | []> = await fetch(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/admin/hide-product`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
        credentials: "include",
        body: JSON.stringify(product),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data: FetchResponse<AdminProduct[] | []>) => {
        if (data.error) {
          throw data;
        }
        dispatch(setSuccessMessage(data.message));
        dispatch(clearError());
        return data;
      })
      .catch((err) => {
        dispatch(clearSuccessMessage());
        dispatch(setError(err));
        return err;
      });

    return data;
  }
);
export const adminProductsReducer = getAdminProducts.reducer;
