import { Button, Form, Input } from "antd";
import { adminLogin } from "../components/state/adminAuthSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../components/state/store";
import "./styles/login.css";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export type AdminLoginValues = {
  email: string;
  password: string;
};
export default function AdminLogin() {
  const [form] = Form.useForm();
  const disptach = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const loginLoading = useSelector(
    (state: RootState) => state.adminAuthenticationReducer.loginLoading
  ) as boolean;
  const errorMessage = useSelector(
    (state: RootState) => state.adminAuthenticationReducer.errorMessage
  ) as string;

  const onSubmit = (): void => {
    form.validateFields().then(async (values: AdminLoginValues) => {
      const isAuth = await disptach(
        adminLogin({
          email: values.email,
          password: values.password,
        })
      );
      if (isAuth.payload?.authenticated) {
        navigate("/admin/products");
      }
    });
  };
  return (
    <div className="h-screen flex items-center bg-slate-400 w-screen">
      <div className=" bg-white container mx-auto flex flex-col items-center justify-center w-max p-24 rounded-2xl shadow-xl gap-10">
        <h2 className="capitalize text-4xl font-semibold">
          Login to admin panel
        </h2>
        <Form
          form={form}
          className="loginForm w-[400px] flex flex-col justify-center"
          onFinish={onSubmit}
        >
          <Form.Item
            required
            name={"email"}
            label="Email"
            rules={[
              {
                validateTrigger: "onSubmit",
                type: "email",
                message: "The input is not a valid email",
              },
              {
                required: true,
                message: "Please enter your email",
              },
            ]}
          >
            <Input
              className={errorMessage ? "border-red-500" : ""}
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item
            required
            name={"password"}
            label="Password"
            rules={[
              {
                required: true,
                validator: (_: any, value: string) => {
                  if (value && value.trim().length !== 0) {
                    // If the value is not empty and doesn't contain any whitespace
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Password field can not be empty")
                  );
                },
              },
            ]}
            className="mb-0"
          >
            <Input.Password
              className={errorMessage ? "border-red-500" : ""}
              placeholder="Password"
            />
          </Form.Item>
          {errorMessage && (
            <p className="mx-auto text-red-500 capitalize font-semibold">
              {errorMessage}
            </p>
          )}
          <div className="flex justify-center mt-5">
            <Button
              loading={loginLoading}
              className="generalAdminButton "
              onClick={() => {
                form.submit();
              }}
            >
              Login
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}
