import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AdminLoginValues } from "../../admin/login";
import { ErrorObject, clearError, setError } from "./errorSlice";
import { setSuccessMessage } from "./successSlice";

export type AdminType = {
  name: string;
  email: string;
  role: "USER" | "ADMIN" | "SUPER_ADMIN";
};

type AdminAuthenticationState = {
  loading: boolean;
  loginLoading: boolean;
  updateAdminSettingsLoading: boolean;
  authenticated: boolean;
  admin: AdminType | null;
  errorMessage?: string;
};

export type FetchResponse = {
  authenticated: boolean;
  admin: AdminType;
  error?: ErrorObject;
  message: string;
};
const initialState: AdminAuthenticationState = {
  loading: true,
  loginLoading: false,
  authenticated: false,
  admin: null,
  updateAdminSettingsLoading: false,
  errorMessage: undefined,
};

const adminAuthenticationSlice = createSlice({
  name: "authentication",
  initialState: initialState,
  reducers: {
    unAuthicatedAdmin: (state) => {
      state.authenticated = false;
      state.admin = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fethcingAdminCredentials.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fethcingAdminCredentials.fulfilled,
      (state, action: PayloadAction<FetchResponse>) => {
        if (action.payload.error) {
        }

        state.loading = false;
        state.authenticated = action.payload.authenticated;
        state.admin = action.payload.admin;
      }
    );
    builder.addCase(fethcingAdminCredentials.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(adminLogin.pending, (state) => {
      state.loginLoading = true;
      state.errorMessage = undefined;
    });
    builder.addCase(
      adminLogin.fulfilled,
      (state, action: PayloadAction<FetchResponse>) => {
        state.errorMessage = action.payload.error?.message;
        state.loginLoading = false;
        state.authenticated = action.payload.authenticated;
        state.admin = action.payload.admin;
      }
    );
    builder.addCase(adminLogin.rejected, (state) => {
      state.loginLoading = false;
      state.errorMessage = undefined;
    });
    builder.addCase(adminLogout.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      adminLogout.fulfilled,
      (state, action: PayloadAction<FetchResponse>) => {
        state.loading = false;

        state.authenticated = action.payload.authenticated;
        state.admin = action.payload.admin;
      }
    );
    builder.addCase(adminLogout.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateAdminSettings.pending, (state) => {
      state.updateAdminSettingsLoading = true;
    });
    builder.addCase(
      updateAdminSettings.fulfilled,
      (state, action: PayloadAction<FetchResponse>) => {
        state.updateAdminSettingsLoading = false;
        state.admin = action.payload.admin;
      }
    );
    builder.addCase(updateAdminSettings.rejected, (state) => {
      state.updateAdminSettingsLoading = false;
    });
  },
});
export const fethcingAdminCredentials = createAsyncThunk(
  "authentication/fethcingAdminCredentials",
  async () => {
    const data: FetchResponse = await fetch(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/admin/checkAuth`,
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data: FetchResponse) => {
        return data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });

    return data;
  }
);
export const adminLogin = createAsyncThunk(
  "authentication/adminLogin",
  async (credentials: AdminLoginValues, { dispatch }) => {
    const data = await fetch(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/admin/login`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(credentials),
      }
    )
      .then((res) => res.json())
      .then((data: FetchResponse) => {
        if (data.error) throw data;
        dispatch(clearError());

        dispatch(
          setSuccessMessage(`${data.message}, Welcome ${data?.admin?.name}`)
        );
        return data;
      })
      .catch((err) => {
        dispatch(setError(err));
        return err;
      });

    return data;
  }
);
export const updateAdminSettings = createAsyncThunk(
  "authentication/updateAdminSettings",
  async (updatedData: { name: string }, { dispatch }) => {
    const data = await fetch(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/admin/update-settings`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(updatedData),
      }
    )
      .then((res) => res.json())
      .then((data: FetchResponse) => {
        if (data.error) throw data;
        dispatch(setSuccessMessage(data.message));
        dispatch(clearError());
        return data;
      })
      .catch((err) => {
        dispatch(setError(err));
        return err;
      });

    return data;
  }
);
export const adminLogout = createAsyncThunk(
  "authentication/adminLogout",
  async (_, { dispatch }) => {
    const data = await fetch(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/admin/logout`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data: FetchResponse) => {
        if (data.error) throw data;
        dispatch(clearError());

        return data;
      })
      .catch((err) => {
        dispatch(setError(err));
        return err;
      });

    return data;
  }
);
export const { unAuthicatedAdmin } = adminAuthenticationSlice.actions;
export const adminAuthenticationReducer = adminAuthenticationSlice.reducer;
