import { ErrorType } from "../components/state/errorSlice";

export enum EstimationStatus {
  IN_PROGRESS = "in_progress",
  DELIEVERED = "delievered",
  CANCELED_BY_USER = "canceled_by_user",
  CANCELED_BY_ADMIN = "canceled_by_admin",
  PART_NOT_FOUND = "part_not_found",
}
export enum ProductQualityTypes {
  ORIGINAL = "original",
  HIGH_QUALITY = "high_quality",
}
export type FetchResponse<T> = {
  message: string;
  error?: ErrorType;
  data: T;
  success: boolean;
};
export type EstimationType = {
  _id: string;
  email: string;
  name: string;
  description: string;
  phoneNumber: string;
  images: { url: string; key: string; name: string }[];
  estimationStatus: EstimationStatus;
  createdAt: Date;
};
