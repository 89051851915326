import { Button } from "antd";
import { CartItem } from "../../util/types";
import { formatMakeAndModel, formatYearString } from "../../util/util";
import { RightOutlined, CloseOutlined } from "@ant-design/icons";
import { useAppDispatch } from "../../hooks/useAppDispatchSelector";
import { deleteFromCart } from "../state/cartSlice";
import { useNavigate } from "react-router-dom";
import { Fragment } from "react";
const CartPopoverContent = ({ cartItems }: { cartItems: CartItem[] }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return (
    <div className="sm:w-[350px] w-[250px] min-max">
      {cartItems.length ? (
        <div className="flex flex-col gap-4">
          {cartItems.map((item) => {
            return (
              <Fragment key={item.product._id}>
                <div className="px-4 w-full gap-2 flex items-center h-20">
                  <div
                    className="w-7 h-7  rounded-full hover:border-black border-transparent  flex items-center justify-center mr-2 cursor-pointer border"
                    onClick={() => {
                      dispatch(deleteFromCart({ _id: item.product._id }));
                    }}
                  >
                    <CloseOutlined className="w-[12px]" />
                  </div>
                  <div className="w-20 h-20">
                    <img
                      src={item.product.coverPhoto.url}
                      alt="coverphoto"
                      className=" w-20 h-20 rounded-lg"
                    />
                  </div>
                  <div className="flex-1 flex flex-col">
                    <p className="text-xl font-semibold line-clamp-1 text-black">
                      {item.product.name}
                    </p>
                    <p className="text-base line-clamp-1 text-black">
                      {formatMakeAndModel({
                        make: item.product.make,
                        model: item.product.model,
                      })}
                    </p>
                    <p className="text-base line-clamp-1 text-black">
                      {formatYearString({
                        yearFrom: item.product.yearFrom,
                        yearTo: item.product.yearTo,
                      })}
                    </p>
                  </div>
                  <div className="rounded-full flex items-center justify-center ">
                    <p className="text-black text-lg">
                      {item.count} x{" "}
                      {item.product.hasDiscount
                        ? item.product.discountPrice
                        : item.product.price}{" "}
                      EGP
                    </p>
                  </div>
                </div>
                <div className="w-full h-0.5 bg-black opacity-15" />
              </Fragment>
            );
          })}
          <div className="px-4 w-full">
            <Button
              className="flex items-center justify-center gap-2 w-full !hover:border-black"
              onClick={() => {
                navigate("/cart");
              }}
            >
              <p className="sm:text-xl text-2xl">Proceed to cart</p>
              <RightOutlined className="text-[12px] mt-1" />
            </Button>
          </div>
        </div>
      ) : (
        <div className="w-full flex justify-center items-center h-24 text-gray_primary">
          <p>Your cart is empty</p>
        </div>
      )}
    </div>
  );
};
export default CartPopoverContent;
