import { Button, Form, Modal, Space, Spin, Table, Tag, Tooltip } from "antd";
import type { TableProps } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../components/state/store";
import { LoadingOutlined } from "@ant-design/icons";
import {
  AdminProduct,
  deleteProductThunk,
  getAdminProductsThunk,
  hideProductThunk,
} from "../components/state/adminProductsSlice";
import "./styles/adminProducts.css";
import AddEditProduct from "./addProduct";
import { getCategoriesThunk } from "../components/state/categoriesSlice";
import { ProductQualityTypes } from "../util/adminTypes";

type CloseEditModalFunction = () => void;

export default function AdminProducts() {
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deletedProduct, setDeletedProduct] = useState<{
    name: string | null;
    _id: string | null;
    isShown: boolean | null;
  }>({
    name: null,
    _id: null,
    isShown: null,
  });
  const [editForm] = Form.useForm();
  const [editProductModal, setEditProductModal] = useState<boolean>(false);
  const [editedProduct, setEditedProduct] = useState<AdminProduct | null>(null);
  const dispatch = useDispatch<AppDispatch>();
  const loadingAdminProducts = useSelector<RootState>(
    (state) => state.adminProductsReducer.loading
  ) as boolean;
  const loadingDeleteProduct = useSelector<RootState>(
    (state) => state.adminProductsReducer.deleteProductLoading
  ) as boolean;
  const loadingHideProduct = useSelector<RootState>(
    (state) => state.adminProductsReducer.hideProductLoading
  ) as boolean;
  const adminProducts = useSelector<RootState>(
    (state) => state.adminProductsReducer.products
  ) as [] | AdminProduct[];
  const categoriesSelector = useSelector<RootState>(
    (state) => state.categoriesReducer.categories
  ) as { category: string; id: string }[];
  useEffect(() => {
    dispatch(getCategoriesThunk());
  }, []);

  const openDeleteModal = (): void => {
    setDeleteModal(true);
  };
  const closeDeleteModal = (): void => {
    setDeleteModal(false);
    setDeletedProduct({
      name: null,
      _id: null,
      isShown: null,
    });
  };
  const openEditModal = (): void => {
    setEditProductModal(true);
  };

  const closeEditModal: CloseEditModalFunction = () => {
    setEditProductModal(false);

    editForm.resetFields();
  };
  useEffect(() => {
    dispatch(getAdminProductsThunk());
  }, []);
  const editShownStatus = async () => {
    if (!deletedProduct._id || !deletedProduct.isShown) return;

    await dispatch(
      hideProductThunk({
        id: deletedProduct._id,
        isShown: deletedProduct.isShown,
      })
    );
    closeDeleteModal();
  };
  const columns: TableProps<AdminProduct>["columns"] = [
    {
      title: "Product Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        return (
          <div className="flex gap-10 items-center">
            <div className="rounded-xl wt-20 h-20 ">
              <img
                src={record.coverPhoto?.url}
                alt="cover"
                className="h-full w-full rounded-xl"
              />
            </div>
            <p>{text}</p>
          </div>
        );
      },
      sorter: (a, b) => {
        return a.name.localeCompare(b.name);
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      sorter: (a, b) => {
        return a.price - b.price;
      },
    },
    {
      title: "category",
      key: "category",
      dataIndex: "category",
      render: (_, { category }) => {
        const filteredCategories = categoriesSelector?.filter((cat) =>
          category?.includes(cat.id)
        );

        return (
          <>
            {filteredCategories.map((cat) => {
              let color = filteredCategories.length > 5 ? "geekblue" : "green";

              return (
                <Tag color={color} key={cat.id}>
                  {cat.category.toUpperCase()}
                </Tag>
              );
            })}
          </>
        );
      },
    },
    {
      title: "Availability",
      dataIndex: "inStock",
      key: "inStock",
      render: (_, { inStock }) => {
        if (inStock) {
          return <p>In Stock</p>;
        } else return <p>Out Of Stock</p>;
      },
      filters: [
        { text: "In Stock", value: true },
        { text: "Out Of Stock", value: false },
      ],
      onFilter: (value, record) => {
        if (record.inStock) {
          return value === true;
        } else return value === false;
      },
    },
    {
      title: "Product Quality",
      dataIndex: "productQuality",
      key: "productQuality",
      render: (_, { productQuality }) => {
        if (productQuality === ProductQualityTypes.HIGH_QUALITY) {
          return <p>High Quality</p>;
        } else if (productQuality === ProductQualityTypes.ORIGINAL) {
          return <p>Original</p>;
        }
      },
      filters: [
        { text: "Original", value: ProductQualityTypes.ORIGINAL },
        { text: "High Quality", value: ProductQualityTypes.HIGH_QUALITY },
      ],
      onFilter: (value, record) => {
        if (record.productQuality === ProductQualityTypes.ORIGINAL) {
          return value === ProductQualityTypes.ORIGINAL;
        } else if (record.productQuality === ProductQualityTypes.HIGH_QUALITY) {
          return value === ProductQualityTypes.HIGH_QUALITY;
        } else return false;
      },
    },
    {
      title: "Make",
      dataIndex: "make",
      key: "make",
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "Stock Number",
      dataIndex: "stockNumber",
      key: "stockNumber",
      sorter: (a, b) => {
        return a.stockNumber - b.stockNumber;
      },
    },
    {
      title: "Shown",
      dataIndex: "isShown",
      key: "isShown",
      render: (_, { isShown }) => {
        if (isShown) {
          return (
            <Tooltip title={"Product is shown on website"} placement="left">
              <p>Shown</p>
            </Tooltip>
          );
        } else
          return (
            <Tooltip placement="left" title={"Product is hidden from website"}>
              <p>Hidden</p>
            </Tooltip>
          );
      },
      filters: [
        { text: "Shown", value: true },
        { text: "Hidden", value: false },
      ],
      onFilter: (value, record) => {
        if (record.isShown) {
          return value === true;
        } else return value === false;

        // return record.inStock.includes(value);
      },
    },
    {
      title: "Has Discount",
      dataIndex: "hasDiscount",
      key: "hasDiscount",
      render: (text, { hasDiscount }, index) => {
        if (hasDiscount) {
          return <p>Yes</p>;
        } else return <p>No</p>;
      },
      filters: [
        { text: "Discount", value: true },
        { text: "No Discount", value: false },
      ],
      onFilter: (value, record) => {
        if (record.hasDiscount) {
          return value === true;
        } else return value === false;
      },
    },
    {
      title: "Discount Price",
      dataIndex: "discountPrice",
      key: "discountPrice",
      render: (_, { discountPrice }) => {
        if (discountPrice) {
          return <p>{discountPrice}</p>;
        } else return <p>-</p>;
      },
      sorter: (a, b) => {
        return `${a.discountPrice}`.localeCompare(`${b.discountPrice}`);
      },
    },
    // {
    //   title: "colors",
    //   key: "colors",
    //   dataIndex: "colors",
    //   render: (_, { colors }) => (
    //     <>
    //       {colors.map((tag) => {
    //         let color = tag.length > 5 ? "geekblue" : "green";
    //         if (tag === "loser") {
    //           color = "volcano";
    //         }
    //         return (
    //           <Tag color={color} key={tag}>
    //             {tag.toUpperCase()}
    //           </Tag>
    //         );
    //       })}
    //     </>
    //   ),
    // },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => {
        return (
          <Space size="middle">
            <p
              className="cursor-pointer hover:text-main_color_darker"
              onClick={() => {
                setEditedProduct(record);
                openEditModal();
              }}
            >
              Edit
            </p>
            <p
              className="cursor-pointer hover:text-red-500"
              onClick={() => {
                openDeleteModal();
                setDeletedProduct({
                  name: record.name,
                  _id: record._id,
                  isShown: record.isShown,
                });
              }}
            >
              Delete
            </p>
          </Space>
        );
      },
    },
  ];
  const data: AdminProduct[] = adminProducts?.map((adminProduct) => {
    return { ...adminProduct, key: adminProduct._id };
  });

  return (
    <div>
      <Modal
        open={deleteModal}
        className="productDeleteModal"
        onCancel={() => {
          if (!loadingDeleteProduct) {
            closeDeleteModal();
          }
        }}
        footer={[
          <div
            className="gap-10 w-full flex items-center justify-center"
            key={"cancelButton"}
          >
            <Button
              disabled={loadingDeleteProduct || loadingHideProduct}
              className=" w-48 h-16"
              onClick={(e) => closeDeleteModal()}
            >
              Cancel
            </Button>
            {deletedProduct.isShown && (
              <Button
                loading={loadingHideProduct}
                onClick={() => {
                  editShownStatus();
                }}
                disabled={loadingDeleteProduct}
                className="generalAdminButton w-48 "
              >
                Hide
              </Button>
            )}
            <Button
              disabled={loadingHideProduct}
              loading={loadingDeleteProduct}
              key={"removeButton"}
              className="bg-red-500 w-48 text-white h-16 deleteButton"
              onClick={async () => {
                if (deletedProduct._id) {
                  await dispatch(deleteProductThunk(deletedProduct._id));
                  closeDeleteModal();
                }
              }}
            >
              Delete
            </Button>
          </div>,
        ]}
      >
        <div className="w-full flex flex-col justify-center items-center ">
          <h2 className="border-b-2 border-main_color_darker2 pb-5 px-5 mb-16 text-5xl font-semibold">
            Delete Product
          </h2>
          <p className="text-3xl font-semibold mb-5">
            Are you sure you want to delete
            <span className="uppercase text-main_color_darker2">
              &nbsp;(
              {deletedProduct.name})&nbsp;
            </span>
            product?
          </p>
          <p className="text-3xl  mb-5">
            You can hide it instead of deleting it
          </p>
        </div>
      </Modal>
      <Modal
        onCancel={() => {
          closeEditModal();
        }}
        className="editModal"
        open={editProductModal}
        footer={[]}
      >
        <AddEditProduct
          editForm={editForm}
          addOrEdit="edit"
          product={editedProduct}
          closeEditModal={closeEditModal}
        />
      </Modal>
      <Spin
        indicator={<LoadingOutlined />}
        size="large"
        fullscreen
        className="addProductSpinner"
        style={{
          transform: "scale(1.2)",
        }}
        tip="Loading Products..."
        spinning={loadingAdminProducts}
      ></Spin>
      <h2 className="mb-24 text-6xl text-center font-semibold">All Products</h2>
      <div className="container mx-auto">
        <Table
          className="adminProductsTable"
          columns={columns}
          dataSource={data}
        />
      </div>
    </div>
  );
}
