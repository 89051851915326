import Cookies from "js-cookie";
import { userLogout } from "../components/state/userAuthSlice";

export function capitalizeWord(word: string): string {
  const words = word?.split(" ");
  const capitalizedWords = words?.map((word) => {
    return word?.charAt(0).toUpperCase() + word?.slice(1);
  });

  return capitalizedWords?.join(" ");
}
export function copyObjectProperties<T extends Record<string, any>>(
  sourceObject: T
): T {
  const newObject: Partial<T> = {};
  // Loop over each property of the source object
  for (const key in sourceObject) {
    // Check if the property is not inherited from the prototype chain

    // Copy the property to the new object
    newObject[key] = sourceObject[key];
  }
  return newObject as T;
}

export function getInitials(name: string) {
  // Split the name by spaces
  const nameParts = name.split(" ");

  // Get the first and last name
  const firstName = nameParts[0];
  const lastName = nameParts[nameParts.length - 1];

  // Get initials
  const initials =
    firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();

  return initials;
}

export function formatMakeAndModel({
  make,
  model,
}: {
  make: string;
  model: string;
}) {
  return `${make} ${model}`;
}

export function formatYearString({
  yearFrom,
  yearTo,
}: {
  yearFrom: string;
  yearTo: string;
}) {
  const isSameYear = yearFrom === yearTo;

  if (isSameYear) return `For ${yearFrom} Models`;
  else return `For ${yearFrom} - ${yearTo} Models`;
}

export const isArrayIncludeText = ({
  arrayStrings,
  searchTerm,
}: {
  arrayStrings: string[];
  searchTerm: string;
}) => {
  return arrayStrings.some((item) =>
    item
      .toLowerCase()
      .replace(/\s+/g, "")
      .includes(searchTerm.toLowerCase().replace(/\s+/g, ""))
  );
};

export async function fetchRequest<T>({
  url,
  method,
  dispatch,
  body,
}: {
  url: string;
  method: "GET" | "POST" | "PUT" | "DELETE";
  dispatch?: any; // Optional dispatch function
  body?: Record<string, any>; // Only required for POST and PUT
}): Promise<T> {
  try {
    // Validate body for POST and PUT requests
    if ((method === "POST" || method === "PUT") && !body) {
      throw new Error("Body is required for POST or PUT requests.");
    }
    const token = localStorage.getItem("spare_auth_token");
    const response = await fetch(url, {
      method,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: body ? JSON.stringify(body) : undefined,
    });

    if (!response.ok) {
      if (response.status === 401) {
        if (dispatch) {
          dispatch(userLogout()); // Trigger logout action
        }
      }
    }

    const data = await response.json();
    if (data.error) {
      if (data.error.code === 401) {
        if (dispatch) {
          dispatch(userLogout()); // Trigger logout action
        }
      }
      throw data;
    }
    return data;
  } catch (err: any) {
    return err;
  }
}

export const formatDate = (dateString: Date): string => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short", // "short" gives abbreviated month names like "Feb"
    year: "numeric",
  });
};
export const setCookie = ({ name, value }: { name: string; value: string }) => {
  Cookies.set(name, value, {
    expires: new Date(Date.now() + 7200000), // Expires in 2 hours
    secure: true, // Send only over HTTPS
    sameSite: "Strict", // Allow cross-site cookies (requires HTTPS)
    path: "/", // Make cookie accessible to the entire site
  });
};
export const setLocalStorage = ({
  name,
  value,
}: {
  name: string;
  value: string;
}) => {
  localStorage.setItem(name, value);
};
export const removeCookie = ({ name }: { name: string }) => {
  Cookies.remove(name, {
    path: "/", // Must match the path used when setting the cookie
  });
};
export const removeLocalStorage = ({ name }: { name: string }) => {
  localStorage.removeItem(name);
};
