import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clearSuccessMessage, setSuccessMessage } from "./successSlice";
import { ErrorObject, clearError, setError } from "./errorSlice";

type FetchResponse = {
  message: string;
  error?: ErrorObject;
  success: boolean;
};
export type NewUserCred = {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
  role: "admin" | "user" | "SUPER_ADMIN" | "tester";
};
type ResetPasswordState = {
  loading: boolean;
  errorMessage?: string;
};
const initialState: ResetPasswordState = {
  loading: false,
  errorMessage: undefined,
};

const createUser = createSlice({
  name: "resetPassword",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createUserThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      createUserThunk.fulfilled,
      (state, action: PayloadAction<FetchResponse>) => {
        state.loading = false;
        if (action.payload.error) {
          state.errorMessage = action.payload.error.message;

          return;
        }
        state.errorMessage = undefined;
      }
    );
    builder.addCase(createUserThunk.rejected, (state) => {
      state.loading = false;
    });
  },
});
export const createUserThunk = createAsyncThunk(
  "createUser/createUser",
  async (newUser: NewUserCred, { dispatch }) => {
    const data: FetchResponse = await fetch(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/admin/create-user`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          newUser,
        }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data: FetchResponse) => {
        if (data.error) {
          throw data;
        }
        dispatch(setSuccessMessage(data.message));
        dispatch(clearError());
        return data;
      })
      .catch((err) => {
        dispatch(clearSuccessMessage());
        dispatch(setError(err));
        return err;
      });

    return data;
  }
);

// export const { logOut } = resetPassword.actions;
export const createUserdReducer = createUser.reducer;
