import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { RootState } from "../components/state/store";
import { useAppDispatch } from "../hooks/useAppDispatchSelector";
import { setopenLoginPopup } from "../components/state/userAuthSlice";
import { useEffect } from "react";
export default function UserProtectedRoutes() {
  const isAuth = useSelector(
    (state: RootState) => state.userAuthenticationReducer.userAuthenticated
  );
  const isAuthLoading = useSelector(
    (state: RootState) => state.userAuthenticationReducer.loading
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!isAuthLoading && !isAuth) {
      dispatch(setopenLoginPopup({ state: true }));
    }
  }, [isAuthLoading, isAuth]);
  return isAuthLoading ? (
    <></>
  ) : isAuth ? (
    <>
      <Outlet />
    </>
  ) : (
    <Navigate to="/" />
  );
}
