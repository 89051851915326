import { DeleteOutlined } from "@ant-design/icons";
import { FileItem } from "../state/adminProductsSlice";
type props =
  | {
      name: string;
      thumbUrl?: string;
      remove: () => void;
      error?: boolean | undefined;
      errorMessage?: string;
      type: "coverPhoto";
      originalPhoto?: undefined;
    }
  | {
      originalPhoto: FileItem;
      name: string;
      thumbUrl?: string;
      remove: (fileName: FileItem) => void;
      error?: boolean | undefined;
      errorMessage?: string;
      type: "additionalPhoto";
    };
export default function AdminUploadImage({
  name,
  thumbUrl,
  remove,
  error,
  errorMessage = "Error uploading image",
  type,
  originalPhoto,
}: props) {
  const errorStyle = { borderColor: "red", color: "red" };

  return (
    <div
      className="w-full flex justify-between items-center p-2 border-1 border-gray-300 h-28 mt-4 rounded-xl"
      style={error ? errorStyle : {}}
    >
      <div className="flex items-center  justify-start gap-2 overflow-hidden text-ellipsis">
        <img src={thumbUrl} className="w-24 h-24" alt="preview" />
        {!error && <p className=" h-full">{name}</p>}
        {error && (
          <p
            style={{
              width: "80%",
            }}
          >
            {" "}
            {errorMessage}
          </p>
        )}
      </div>
      <div className="mr-1">
        <DeleteOutlined
          onClick={(e) => {
            type === "additionalPhoto" && remove(originalPhoto);
            type === "coverPhoto" && remove();
          }}
        />
      </div>
    </div>
  );
}
