import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserOrderResponse, UserOrder } from "../../util/types";
import { fetchRequest } from "../../util/util";
import { ErrorType } from "./errorSlice";

type CheckoutInitialState = {
  loadingResponse: boolean;
  userOrders: UserOrder[] | null;
  error: ErrorType | null;
};
const initialState: CheckoutInitialState = {
  loadingResponse: false,
  userOrders: null,
  error: null,
};

const userOrderSlice = createSlice({
  name: "user-order",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(userOrderThunk.pending, (state) => {
      state.loadingResponse = true;
    });
    builder.addCase(
      userOrderThunk.fulfilled,
      (state, action: PayloadAction<UserOrderResponse>) => {
        state.loadingResponse = false;
        if (action.payload.error) {
          state.error = action.payload.error;
          return;
        }
        state.userOrders = action.payload.orders;
      }
    );
    builder.addCase(userOrderThunk.rejected, (state) => {
      state.loadingResponse = false;
    });
  },
});

export const userOrderThunk = createAsyncThunk(
  "get/user-order",
  async (_, { dispatch }) => {
    const data: UserOrderResponse = await fetchRequest<UserOrderResponse>({
      url: `${process.env.REACT_APP_BACKEND_DOMAIN}/userOrders`,
      method: "GET",
      dispatch,
    });
    return data;
  }
);

// export const {} = userOrderSlice.actions;
export const userOrderReducer = userOrderSlice.reducer;
