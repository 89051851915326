import { Button, Form, Input, Modal, Select, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "./styles/addProduct.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../components/state/store";

import {
  addCategoryThunk,
  getCategoriesThunk,
  removeCategoryThunk,
} from "../components/state/categoriesSlice";
import "./styles/addRemoveCategories.css";

export default function AddRemoveCategories() {
  const categoriesSelector = useSelector<RootState>(
    (state) => state.categoriesReducer.categories
  ) as { category: string; id: string }[];
  const loadingCategoriesSelector = useSelector<RootState>(
    (state) => state.categoriesReducer.loading
  ) as boolean;

  const loadingAddButtonSelector = useSelector<RootState>(
    (state) => state.categoriesReducer.addButtonLoading
  ) as boolean;
  const loadingRemoveButtonSelector = useSelector<RootState>(
    (state) => state.categoriesReducer.removeButtonLoading
  ) as boolean;

  const [addCategory] = Form.useForm();
  const [removeCategory] = Form.useForm();
  const [removedCategory, setRemovedCategory] = useState<{
    category: string;
    id: string;
  } | null>(null);
  const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);
  const closeRemoveModalHandler = () => {
    setOpenRemoveModal(false);
  };
  const openRemoveModalHandler = () => {
    setOpenRemoveModal(true);
  };

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getCategoriesThunk());
  }, []);

  const hanldeAddCategory = (): void => {
    addCategory.validateFields().then((values: { addedCategory: string }) => {
      dispatch(addCategoryThunk(values.addedCategory));
    });
  };
  const hanldeRemoveCategory = (): void => {
    removeCategory
      .validateFields()
      .then(async (values: { removedCategory: string }) => {
        await dispatch(removeCategoryThunk(values.removedCategory));
      })
      .then(() => {
        removeCategory.resetFields();
        closeRemoveModalHandler();
      });
  };

  return (
    <>
      <Modal
        className="removeCategoryModal"
        open={openRemoveModal}
        onCancel={() => closeRemoveModalHandler()}
        footer={[
          <div
            className="gap-10 w-full flex items-center justify-center"
            key={"cancelButton"}
          >
            <Button
              disabled={loadingRemoveButtonSelector}
              className=" generalAdminButton w-48"
              onClick={() => closeRemoveModalHandler()}
            >
              Cancel
            </Button>
            <Button
              loading={loadingRemoveButtonSelector}
              onClick={() => {
                removeCategory.submit();
              }}
              key={"removeButton"}
              className=" generalAdminButton w-48"
            >
              Remove
            </Button>
          </div>,
        ]}
      >
        <div className="w-full flex flex-col justify-center items-center ">
          <h2 className="border-b-2 border-main_color_darker2 pb-5 px-5 mb-16 text-5xl font-semibold">
            Remove Category
          </h2>
          <p className="text-3xl font-semibold mb-5">
            Are you sure you want to remove
            <span className="uppercase text-main_color_darker2">
              &nbsp;(
              {removedCategory?.category})&nbsp;
            </span>
            category?
          </p>
        </div>
      </Modal>
      <Spin
        indicator={<LoadingOutlined />}
        size="large"
        fullscreen
        className="addProductSpinner"
        tip="Loading form"
        spinning={loadingCategoriesSelector}
      ></Spin>

      <h2 className="mb-24 text-6xl text-center font-semibold">
        Add / Remove Category
      </h2>
      <Form
        onFinish={hanldeAddCategory}
        form={addCategory}
        className="addRemoveCategoriesForm flex gap-8 container mx-auto"
      >
        <Form.Item
          required
          name={"addedCategory"}
          className="w-full"
          label="Add New Category"
          rules={[
            {
              validator: (_, value) => {
                if (value && value.trim().length !== 0) {
                  // If the value is not empty and doesn't contain any whitespace
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Please enter a valid category name")
                );
              },
            },
          ]}
        >
          <Input className="h-16" placeholder="Enter category name" />
        </Form.Item>
        <Button
          loading={loadingAddButtonSelector}
          onClick={() => {
            addCategory.submit();
          }}
          className=" generalAdminButton w-96"
        >
          Add Category
        </Button>
      </Form>
      <Form
        onFinish={hanldeRemoveCategory}
        form={removeCategory}
        className="addRemoveCategoriesForm flex gap-8 container mx-auto"
      >
        <Form.Item
          required
          name={"removedCategory"}
          className="w-full"
          label="Product Category"
          rules={[
            {
              required: true,
              message: "Please select a category",
            },
          ]}
        >
          <Select
            className="h-16"
            allowClear
            onChange={(e) => {
              if (categoriesSelector) {
                const currentCat = categoriesSelector.find(
                  (category) => category.id === e
                );
                if (currentCat) {
                  setRemovedCategory(currentCat);
                }
              }
            }}
          >
            {categoriesSelector?.map((category) => {
              return (
                <Select.Option key={category.id}>
                  {category.category}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Button
          loading={loadingRemoveButtonSelector}
          onClick={() => {
            removeCategory
              .validateFields()
              .then(() => {
                openRemoveModalHandler();
              })
              .catch((err) => {});
          }}
          className="w-96 generalAdminButton "
        >
          Remove category
        </Button>
      </Form>
      <Form className="addRemoveCategoriesForm flex gap-8 container mx-auto ">
        <Form.Item
          name={"showCategories"}
          className="w-full"
          label="All Categories (Display)"
        >
          <Select
            popupClassName={"disabledSelect"}
            className="h-16 "
            options={[
              ...categoriesSelector?.map((category) => {
                return {
                  value: category.id,
                  label: <p>{category.category}</p>,
                  disabled: true,
                };
              }),
            ]}
          ></Select>
        </Form.Item>
      </Form>
    </>
  );
}
