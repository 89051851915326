import { useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import "./styles/adminLayout.css";
import { HeaderRoutesType } from "../header/header";
import { Button, Menu } from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SkinOutlined,
  SettingFilled,
  LogoutOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { AppDispatch, RootState } from "../state/store";
import { useDispatch, useSelector } from "react-redux";
import { AdminType, adminLogout } from "../state/adminAuthSlice";
export default function AdminLayout() {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const admin = useSelector<RootState>(
    (state) => state.adminAuthenticationReducer.admin
  ) as AdminType;

  const linkStyles = (
    route: string,
    icon?: boolean,
    submenu?: boolean
  ): {
    color: string;
  } => {
    let style: { color: string } | null = { color: "white" };
    if (location.pathname === route) {
      style.color = "black";
    }
    if (icon) return style;

    if (collapsed) {
      style.color = "white";
    }
    if (collapsed && submenu) {
      style.color = "black";
    }
    return style;
  };
  const menuItems: HeaderRoutesType[] = [
    // {
    //   key: "dashboard",
    //   label: (
    //     <Link
    //       className={"linkColor"}
    //       style={linkStyles("/admin/dashboard")}
    //       to="/admin/dashboard"
    //     >
    //       Dashboard
    //     </Link>
    //   ),
    //   route: "/admin/dashboard",
    //   icon: <AppstoreOutlined style={linkStyles("/admin/dashboard", true)} />,
    // },
    {
      key: "products",
      label: (
        <p
          style={
            location.pathname === "/admin/products" ||
            location.pathname === "/admin/addProduct" ||
            location.pathname === "/admin/categories"
              ? { color: "black" }
              : { color: "white" }
          }
          className={"linkColor"}
        >
          Products
        </p>
      ),
      submenu: true,
      icon: (
        <SkinOutlined
          style={
            location.pathname === "/admin/products" ||
            location.pathname === "/admin/addProduct" ||
            location.pathname === "/admin/categories"
              ? { color: "black" }
              : { color: "white" }
          }
        />
      ),
      children: [
        {
          key: "allproducts",
          route: "/admin/products",
          label: (
            <Link
              style={linkStyles("/admin/products", undefined, true)}
              to={"/admin/products"}
            >
              All Products
            </Link>
          ),
        },
        {
          key: "addProduct",
          route: "/admin/addProduct",
          label: (
            <Link
              style={linkStyles("/admin/addProduct", undefined, true)}
              to={"/admin/addProduct"}
            >
              Add New Products
            </Link>
          ),
        },
        {
          key: "manageCategories",
          route: "/admin/categories",
          label: (
            <Link
              style={linkStyles("/admin/categories", undefined, true)}
              to={"/admin/categories"}
            >
              Add / Remove Categories
            </Link>
          ),
        },
        {
          key: "adminEstimations",
          route: "/admin/estimations",
          label: (
            <Link
              style={linkStyles("/admin/estimations", undefined, true)}
              to={"/admin/estimations"}
            >
              Estimations & Requests
            </Link>
          ),
        },
      ],
    },
    {
      key: "settings",
      route: "/admin/settings",
      label: (
        <Link style={linkStyles("/admin/settings")} to={"/admin/settings"}>
          Settings
        </Link>
      ),
      icon: <SettingFilled style={linkStyles("/admin/settings", true)} />,
    },
    {
      key: "createUser",
      route: "/admin/createUser",
      label: (
        <Link style={linkStyles("/admin/createUser")} to={"/admin/createUser"}>
          Create user
        </Link>
      ),
      icon: <UserAddOutlined style={linkStyles("/admin/createUser", true)} />,
    },
    {
      key: "logout",
      route: "/admin/login",
      label: (
        <div
          className={collapsed ? "flex gap-8 pl-0" : "flex gap-4 pl-0"}
          onClick={(e) => {
            dispatch(adminLogout());
          }}
        >
          <LogoutOutlined
            style={{
              color: "black",
            }}
          />
          <p
            onClick={(e) => {}}
            className={collapsed ? "text-white" : "text-black duration-300"}
          >
            Logout
          </p>
        </div>
      ),
    },
  ];
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  const isActive = (menuItem: HeaderRoutesType) => {
    if (menuItem?.submenu) {
      return menuItem.children.some((Item) => Item.route === location.pathname);
    }
    return location.pathname === menuItem.route;
  };
  return (
    <div className="w-full h-max">
      <div className="grid grid-cols-[max-content_minmax(400px,_1fr)]  ">
        <div>
          <div className="h-screen sticky top-0 bg-main_color_darker2">
            <div className="w-full py-5 pl-2 bg-main_color_darker2">
              <Button
                type="default"
                onClick={toggleCollapsed}
                className=" w-20 flex items-center justify-center"
              >
                {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              </Button>
            </div>
            <Menu
              selectedKeys={menuItems.filter(isActive).map((item) => {
                if (item.submenu) {
                  const filteredItem: HeaderRoutesType | undefined =
                    item.children.find(
                      (item) => item.route === location.pathname
                    );
                  if (filteredItem) {
                    return filteredItem.key;
                  }
                }

                return item.key;
              })}
              mode="inline"
              className={
                collapsed
                  ? "bg-main_color_darker2 adminMenu w-24 "
                  : "bg-main_color_darker2 adminMenu  w-96"
              }
              style={{
                transition: "all 200",
              }}
              inlineCollapsed={collapsed}
              items={menuItems.map((menuItem) => {
                return {
                  ...menuItem,
                  submenu: undefined,
                };
              })}
            />
          </div>
        </div>
        <div className=" h-max flex flex-col">
          <div className=" bg-white h-40 border-b-1">
            <div className="container justify-between flex items-center mx-auto">
              <div className="flex items-center justify-center">
                <Link
                  to={"/admin/products"}
                  className="text-2xl font-semibold italic"
                >
                  G-Fast
                </Link>
              </div>
              <div className="w-96 h-20 my-10 flex items-center justify-center  ">
                <p className="text-2xl font-semibold">Welcome, {admin?.name}</p>
              </div>
            </div>
          </div>
          <div
            className="w-full py-24 bg-gray-100"
            style={{
              minHeight: "calc(100vh - 10rem)",
              height: "max-content",
            }}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
