const PolicyAndWarranty = () => {
  return (
    <div className="text-black_primary text-2xl flex flex-col gap-10 list-style">
      <p className="text-4xl font-bold mb-10 text-center">
        Warranty and Returns Policy
      </p>

      <p className="text-3xl font-semibold">Warranty Policy</p>
      <p>
        We are committed to providing high-quality vehicle body parts and
        ensuring customer satisfaction. Please read our warranty terms
        carefully:
      </p>

      <h3>1. Warranty Coverage:</h3>
      <ul>
        <li>
          All products are covered under a <strong>2 months</strong> warranty
          from the date of purchase.
        </li>
        <li>
          The warranty covers manufacturing defects, such as material flaws or
          craftsmanship issues.
        </li>
        <li>The warranty does not cover:</li>
        <li>Damages caused by improper installation, misuse, or accidents.</li>
        <li>Wear and tear due to normal usage.</li>
        <li>
          Painted Panels: All panels are sold in their original condition. If
          the panel has been painted, customized, or altered after purchase, it
          will no longer be covered under warranty.
        </li>
      </ul>

      <h3>2. Warranty Claim Process:</h3>
      <ul>
        <li>
          To file a warranty claim, contact our customer service team at{" "}
          <strong>{process.env.REACT_APP_WHATSAPP_NUMBER}</strong> with the
          following:
        </li>
        <li>Proof of purchase (invoice or order number).</li>
        <li>Description of the issue and supporting photos or videos.</li>

        <li>
          If approved, we will provide a replacement or repair for the defective
          part, or issue a refund as applicable.
        </li>
      </ul>

      <h3>3. Exclusions:</h3>
      <ul>
        <li>
          Products installed by unauthorized technicians or workshops are not
          eligible for warranty claims.
        </li>
        <li>Custom or modified parts are excluded from warranty coverage.</li>
      </ul>

      <h2 className="text-3xl font-semibold">Returns Policy</h2>
      <p>
        We strive to ensure your satisfaction with every purchase. If you’re not
        completely satisfied, our returns policy outlines the terms for
        returning items:
      </p>

      <h3>1. Eligibility for Returns:</h3>
      <ul>
        <li>
          Returns are accepted within <strong>7</strong> days of delivery.
        </li>
        <li>
          Items must be unused, in their original condition, and include all
          original packaging, labels, and accessories.
        </li>
      </ul>

      <h3>2. Return Process:</h3>
      <ul>
        <li>
          Contact our customer support team at{" "}
          <strong>{process.env.REACT_APP_WHATSAPP_NUMBER}</strong> to initiate
          the return process.
        </li>
        <li>Provide your order number and reason for return.</li>
        <li>
          Once your return is approved, we will share the return shipping
          address or arrange for a pickup.
        </li>
      </ul>

      <h3>3. Return Shipping:</h3>
      <ul>
        <li>
          The customer is responsible for return shipping costs, except in cases
          where the product is defective or incorrect.
        </li>
      </ul>

      <h3>4. Refunds:</h3>
      <ul>
        <li>
          Refunds will be processed within <strong>7</strong> business days
          after we receive and inspect the returned item.
        </li>
        <li>Refunds will be issued to the original payment method.</li>
        <li>Shipping fees are non-refundable.</li>
      </ul>

      <p className="text-3xl font-semibold">Exchange Policy</p>
      <p>
        We offer product exchanges for defective or incorrectly shipped items.
        If you need a replacement:
      </p>
      <ul>
        <li>
          Contact us within <strong>7</strong> days of receiving your order.
        </li>
        <li>Provide details of the issue along with proof (photos/videos).</li>
        <li>Exchanges are subject to product availability.</li>
      </ul>

      <h2>Contact Us</h2>
      <p>
        For any questions about our warranty, returns, or exchange policies,
        please contact us at:
      </p>
      <ul>
        <li>
          Email: <strong>gfast.egy@gmail.com</strong>
        </li>
        <li>
          Phone: <strong>{process.env.REACT_APP_WHATSAPP_NUMBER}</strong>
        </li>
        <li>
          Whatsapp Chat:{" "}
          <strong>{process.env.REACT_APP_WHATSAPP_NUMBER}</strong>
        </li>
      </ul>
    </div>
  );
};
export default PolicyAndWarranty;
