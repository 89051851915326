import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clearError, setError } from "./errorSlice";
import { FetchResponse } from "../../util/adminTypes";

type Make = { name: string; _id: string };
type Model = { name: string };
type makeModelState = {
  makeLoading: boolean;
  modelLoading: boolean;
  makes: Make[];
  models: Model[];
};
const initialState: makeModelState = {
  makeLoading: true,
  modelLoading: false,
  makes: [],
  models: [],
};

const getMakeModels = createSlice({
  name: "makeModels",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMakesThunk.pending, (state) => {
      state.makeLoading = true;
    });
    builder.addCase(
      getMakesThunk.fulfilled,
      (state, action: PayloadAction<FetchResponse<Make[]>>) => {
        state.makeLoading = false;
        if (action.payload.error) {
          state.makes = [];
          return;
        }
        state.makes = action.payload.data;
      }
    );
    builder.addCase(getMakesThunk.rejected, (state) => {
      state.makeLoading = false;
    });
    // getting models
    builder.addCase(getModelsThunk.pending, (state) => {
      state.modelLoading = true;
    });
    builder.addCase(
      getModelsThunk.fulfilled,
      (state, action: PayloadAction<FetchResponse<Model[]>>) => {
        state.modelLoading = false;
        if (action.payload.error) {
          state.makes = [];
          return;
        }
        state.models = action.payload.data;
      }
    );
    builder.addCase(getModelsThunk.rejected, (state) => {
      state.modelLoading = false;
    });
  },
});

export const getMakesThunk = createAsyncThunk(
  "makeModels/getMakesThunk",
  async (_, { dispatch }) => {
    const data: FetchResponse<Make[]> = await fetch(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/admin/makes`,
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data: FetchResponse<Make[]>) => {
        if (data.error) {
          throw data;
        }

        dispatch(clearError());
        return data;
      })
      .catch((err) => {
        dispatch(setError(err));
        return err;
      });

    return data;
  }
);
export const getModelsThunk = createAsyncThunk(
  "makeModels/getModelsThunk",
  async (makeId: string, { dispatch }) => {
    const data: FetchResponse<Model[]> = await fetch(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/admin/models`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          makeId,
        }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data: FetchResponse<Model[]>) => {
        if (data.error) {
          throw data;
        }

        dispatch(clearError());
        return data;
      })
      .catch((err) => {
        dispatch(setError(err));
        return err;
      });

    return data;
  }
);

export const makeModelReducer = getMakeModels.reducer;
