import { Button } from "antd";
import Footer from "../footer/footer";
import Header from "../header/header";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <div className="pt-52 pb-32 px-10">
        <div className="container mx-auto">
          <div className="lg:w-[60%] mx-auto text-center">
            <p className="text-3xl text-red_primary">404 error</p>
            <p className="text-8xl font-semibold text-red_primary">
              No Page Found
            </p>
            <div className="flex gap-5 items-center justify-center mt-24">
              <Button
                className="w-[150px] bg-white_primary flex items-center justify-center gap-2"
                onClick={() => {
                  navigate("/");
                }}
              >
                <ArrowLeftOutlined className="text-black_primary" />
                <p className="text-black_primary">Home</p>
              </Button>
              <Button
                className="w-[150px] hover:!bg-red_primary bg-red_primary text-white_primary  hover:!text-white_primary"
                onClick={() => {
                  navigate("/products/allproducts");
                }}
              >
                Products
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-auto mb-0">
        <Footer />
      </div>
    </div>
  );
};

export default NotFound;
