import { useEffect } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../hooks/useAppDispatchSelector";
import { ArrowRightOutlined, LoadingOutlined } from "@ant-design/icons";
import { formatDate } from "../../util/util";
import { OrderStatusType, UserOrder } from "../../util/types";
import { Button } from "antd";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { userOrderThunk } from "../state/userOrdersSlice";

const OrderCart = ({ order }: { order: UserOrder }) => {
  const productsName = order.products
    .map((product) => `${product?.product?.name} X ( ${product?.count} )`)
    .join(" | ");
  return (
    <div className="w-full p-4 border rounded-xl flex flex-col gap-3">
      <div className="flex items-center gap-4 h-[25px]">
        <div
          className={`px-4 py-2 rounded-2xl ${
            order.orderStatus === OrderStatusType["DELIVERED "]
              ? "bg-green-50"
              : "bg-orange-50"
          }`}
        >
          <p
            className={`
                text-base font-semibold capitalize ${
                  order.orderStatus === OrderStatusType["DELIVERED "]
                    ? "text-graen-600"
                    : "text-orange-600"
                }`}
          >
            {order.orderStatus}
          </p>
        </div>
        <div className="w-[1px] h-2/3 bg-gray-400"></div>
        <p className="font-semibold text-base ">
          {formatDate(order.orderDate)}
        </p>
      </div>
      <div className="flex">
        <p className="text-lg font-bold text-blue_primary">
          Order ID: {order._id}
        </p>
      </div>
      <p className="text-xl font-semibold">{productsName}</p>
      <p className="text-xl font-semibold capitalize">
        {order.totalPrice} EGP | {order.paymentMethod}
      </p>
    </div>
  );
};
const Orders = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loadingOrders = useAppSelector(
    (state) => state.userOrderReducer.loadingResponse
  );
  const userOrders = useAppSelector(
    (state) => state.userOrderReducer.userOrders
  );

  useEffect(() => {
    dispatch(userOrderThunk());
  }, []);

  return (
    <div>
      {loadingOrders ? (
        <div className="flex items-center justify-center h-[20vh]">
          <LoadingOutlined className="text-7xl" />
        </div>
      ) : userOrders?.length ? (
        <>
          <div className="flex flex-col gap-10">
            {userOrders?.map((order) => (
              <OrderCart key={order._id} order={order} />
            ))}
          </div>

          <div
            className="flex justify-end mt-14"
            onClick={() => {
              navigate("/products/allproducts");
            }}
          >
            <Button className="bg-blue_primary text-white flex items-center justify-between gap-3">
              <p>Browse Products</p>
              <ArrowRightOutlined />
            </Button>
          </div>
        </>
      ) : (
        <div className="flex flex-col items-center justify-center h-[50vh] gap-12 pt-24">
          <FontAwesomeIcon icon={faCartShopping} width={120} size="9x" />
          <p className="text-4xl font-semibold capitalize">
            Your cart is empty
          </p>
          <Button
            className="bg-blue_primary text-white flex items-center justify-between gap-3"
            onClick={() => {
              navigate("/products/allproducts");
            }}
          >
            <p>Back to products</p>
            <ArrowRightOutlined />
          </Button>
        </div>
      )}
    </div>
  );
};
export default Orders;
