import { FileItem } from "../components/state/adminProductsSlice";
import { FetchResponse } from "./adminTypes";

export function validateUserName(_: any, value: string) {
  if (value && value.trim().length !== 0) {
    // If the value is not empty and doesn't contain any whitespace
    return Promise.resolve();
  }
  return Promise.reject(new Error("Please enter a valid user name"));
}

const removeSpecialCharactes = (text: string) => {
  return text
    .replace(/_/g, "")
    .replace(/-/g, "")
    .replace(/%20/g, "")
    .replace(/\s+/g, "")
    .replace(/\(/g, "")
    .replace(/\)/g, "");
};

export async function uploadSignleImageToS3({
  fileNames,
  imageType,
  image,
  folderName,
}: {
  fileNames: string;
  imageType: string;
  image: File;
  folderName: string;
}) {
  const modifiedFileName = `${removeSpecialCharactes(
    fileNames
  )}-${new Date().getTime()}`;
  return fetch(`${process.env.REACT_APP_BACKEND_DOMAIN}/admin/upload`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ fileNames: [modifiedFileName], folderName }),
  })
    .then((res) => res.json())
    .then(async (data: FetchResponse<{ urls: string[] }>) => {
      return await fetch(data.data.urls[0], {
        method: "PUT",
        headers: {
          "Content-Type": imageType,
        },
        body: image,
      });
    });
}

export async function uploadMultipeImagesToS3({
  fileNames,
  images,
  folderName,
}: {
  fileNames: string[];
  images: FileItem[];
  folderName: string;
}) {
  const modifiedFileNames = fileNames.map(
    (fileName) => `${removeSpecialCharactes(fileName)}-${new Date().getTime()}`
  );
  return fetch(`${process.env.REACT_APP_BACKEND_DOMAIN}/admin/upload`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ fileNames: modifiedFileNames, folderName }),
  })
    .then((res) => res.json())
    .then(async (data: FetchResponse<{ urls: string[] }>) => {
      return await Promise.all(
        images.map((file, index) => {
          if (!file) return null;
          else {
            return fetch(data.data.urls[index], {
              method: "PUT",
              headers: {
                "Content-Type": file.type || "",
              },
              body: file as File,
            });
          }
        })
      );
    });
}

export const yearsArray = [
  "2010",
  "2011",
  "2012",
  "2013",
  "2014",
  "2015",
  "2016",
  "2017",
  "2018",
  "2019",
  "2020",
  "2021",
  "2022",
  "2023",
  "2024",
  "2025",
];
