import { Button, Form, Input, Select } from "antd";
import { useEffect } from "react";
import { validateUserName } from "../util/adminUtil";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../components/state/store";
import { NewUserCred, createUserThunk } from "../components/state/createUser";
export type SignupFormValues = {
  email: string;
  password: string;
  confirmPassword: string;
  name: string;
  role: "ADMIN" | "USER" | "SUPER_ADMIN";
};
export default function AdminSignup() {
  const dispatch = useDispatch<AppDispatch>();

  const [form] = Form.useForm();
  useEffect((): void => {
    const initialValus: SignupFormValues = {
      email: "test@test.com",
      password: "123456789",
      confirmPassword: "123456789",
      name: "test2",
      role: "ADMIN",
    };
    form.setFieldsValue(initialValus);
  }, []);
  const validatePassword = (_: any, value: string) => {
    // Password must be at least 8 characters long
    if (!value) {
      return Promise.reject("Password must be at least 8 characters long");
    }
    if (value.length < 8) {
      return Promise.reject("Password must be at least 8 characters long");
    }

    // Check if the password contains at least one lowercase letter
    const lowercaseRegex = /[a-z]/;
    if (!lowercaseRegex.test(value)) {
      return Promise.reject(
        "Password must contain at least one lowercase letter"
      );
    }

    // Check if the password contains at least one uppercase letter
    const uppercaseRegex = /[A-Z]/;
    if (!uppercaseRegex.test(value)) {
      return Promise.reject(
        "Password must contain at least one uppercase letter"
      );
    }

    // Check if the password contains at least one number
    const numberRegex = /[0-9]/;
    if (!numberRegex.test(value)) {
      return Promise.reject("Password must contain at least one number");
    }

    // Check if the password contains at least one special character
    const specialCharacterRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    if (!specialCharacterRegex.test(value)) {
      return Promise.reject(
        "Password must contain at least one special character"
      );
    }
    if (_.field === "confirmPassword") {
      const password = form.getFieldValue("password");
      if (password !== value) {
        return Promise.reject(
          "New Password and current password must be the same"
        );
      }
    }
    // If all checks pass, the password is valid
    return Promise.resolve();
  };

  const onSubmit = (): void => {
    form.validateFields().then(async (values: NewUserCred) => {
      dispatch(createUserThunk(values));
    });
  };
  return (
    <>
      <h2 className="mb-24 text-6xl text-center font-semibold">Create User</h2>
      <Form form={form} onFinish={onSubmit} className="container mx-auto">
        <Form.Item
          required
          labelCol={{ span: 24 }}
          name={"name"}
          label="Name"
          rules={[
            {
              validator: validateUserName,
            },
          ]}
        >
          <Input placeholder="name" />
        </Form.Item>
        <Form.Item
          required
          labelCol={{ span: 24 }}
          name={"email"}
          label="Email"
          rules={[
            {
              validateTrigger: "onSubmit",
              type: "email",
              message: "The input is not a valid email",
            },
            {
              required: true,
              message: "Please enter your email",
            },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item
          labelCol={{ span: 24 }}
          required
          name={"password"}
          label="password"
          rules={[
            {
              validateTrigger: "onSubmit",
              validator: validatePassword,
            },
          ]}
        >
          <Input.Password placeholder="Password" />
        </Form.Item>
        <Form.Item
          labelCol={{ span: 24 }}
          required
          name={"confirmPassword"}
          label="Confirm Password"
          rules={[
            {
              validateTrigger: "onSubmit",
              validator: validatePassword,
            },
          ]}
        >
          <Input.Password placeholder="Confirm Password" />
        </Form.Item>
        <Form.Item
          label="Role"
          labelCol={{ span: 24 }}
          required
          name={"authDegree"}
          rules={[
            {
              required: true,
              message: "Please select a valid value",
            },
          ]}
        >
          <Select>
            {/* <Select.Option key={"USER"}>User</Select.Option> */}
            <Select.Option key={"ADMIN"}>Admin</Select.Option>
            <Select.Option key={"SUPER_ADMIN"}>Super Admin</Select.Option>
            {/* <Select.Option key={"TESTER"}>Tester</Select.Option> */}
          </Select>
        </Form.Item>
        <div className="w-full flex justify-center">
          <Button
            onClick={() => {
              form.submit();
            }}
            className="generalAdminButton"
          >
            Create New User
          </Button>
        </div>
      </Form>
    </>
  );
}
