import { Spin, Table, TableProps } from "antd";
import { EstimationType } from "../util/adminTypes";
import { useEffect } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../hooks/useAppDispatchSelector";
import { getEstimationsThunk } from "../components/state/adminEstimationSlice";
import { LoadingOutlined } from "@ant-design/icons";

const AdminEstimations = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getEstimationsThunk());
  }, []);
  const estimationsData = useAppSelector(
    (state) => state.estimationsReducer.estimations
  );
  const loadingData = useAppSelector(
    (state) => state.estimationsReducer.loadingData
  );
  const columns: TableProps<EstimationType>["columns"] = [
    {
      title: "Customer Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        return (
          <div className="flex gap-10 items-center">
            <p>{text}</p>
          </div>
        );
      },
      sorter: (a, b) => {
        return a.name.localeCompare(b.name);
      },
    },
    {
      title: "Customer Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => {
        return a.email.localeCompare(b.email);
      },
    },
    {
      title: "Customer Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      sorter: (a, b) => {
        return a.phoneNumber.localeCompare(b.phoneNumber);
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Has Images",
      dataIndex: "images",
      key: "images",
      render: (_, record) => {
        const hasImages = record.images.length;
        return (
          <div
            onClick={() => {}}
            className="text-blue_primary font-semibold cursor-pointer"
          >
            {hasImages ? "See images" : "No"}
          </div>
        );
      },
    },
    {
      title: "Estimation Status",
      key: "estimationStatus",
      dataIndex: "estimationStatus",
      render: (text, record) => {
        return (
          <p
            className="cursor-pointer capitalize"
            onClick={() => {
              // setEditedProduct(record);
              // openEditModal();
            }}
          >
            {text.split("_").join(" ")}
          </p>
        );
      },
    },
  ];

  return (
    <div>
      <Spin
        indicator={<LoadingOutlined />}
        size="large"
        fullscreen
        className="addProductSpinner"
        style={{
          transform: "scale(1.2)",
        }}
        tip="Loading Estimations..."
        spinning={loadingData}
      ></Spin>
      <h2 className="mb-24 text-6xl text-center font-semibold">
        Estimations & Requests
      </h2>
      <div className="container mx-auto">
        <Table
          onRow={(record, rowIndex) => {
            return {
              onClick: (e) => {
                window.open(`/admin/estimations/${record._id}`, "_blank");
                // if (e.target.dataset.openpopup === "no") return;
                // setUserId(record.user_id);
                // setModalOpened(true);
                // setPopupData(record);
              },
            };
          }}
          style={{
            cursor: "pointer",
          }}
          className="adminProductsTable"
          columns={columns}
          dataSource={estimationsData}
        />
      </div>
    </div>
  );
};
export default AdminEstimations;
