import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

export type SuccessType = {
  success: { message: string } | null;
};

const initialState: SuccessType = { success: null };

const successSlice = createSlice({
  name: "success",
  initialState: initialState,
  reducers: {
    setSuccessMessage(state, action: PayloadAction<string>) {
      state.success = {
        message: action.payload,
      };
    },
    clearSuccessMessage(state) {
      state.success = null;
    },
  },
});

export const { setSuccessMessage, clearSuccessMessage } = successSlice.actions;

export const successReducer = successSlice.reducer;
