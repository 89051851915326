import "./styles/home.css";
import bodyCardImage from "./images/body_card.jpeg";
import lightsCard from "./images/headlight_card.jpeg";
import accessoriesCard from "./images/accessories_card.jpeg";
import HomeCard from "./home_card";
export default function HomeSecondSection() {
  return (
    <div className="relative z-20 bg-white px-10  w-full">
      <div className=" py-40 container mx-auto flex xl:gap-20 gap-5 sm:flex-row flex-col lg:px-5 xl:px-0 w-[85vw] sm:w-full px-0">
        <HomeCard
          source={bodyCardImage}
          title="Our Body Parts Collection"
          buttonContent="Shop Now"
          url="/products/bodyparts"
        />
        <HomeCard
          source={lightsCard}
          title="Our Headlights Collection"
          buttonContent="Shop Now"
          url="/products/lights"
        />
        <HomeCard
          source={accessoriesCard}
          title="Our Accessories Collection"
          buttonContent="Shop Now"
          url="/products/accessories"
        />
      </div>
    </div>
  );
}
