// src/Carousel.js
import { Image } from "antd";
import { useState } from "react";
import { CaretRightOutlined, CaretLeftOutlined } from "@ant-design/icons";
const CarouselIndicators = ({
  images,
  activeIndex,
  onClick,
}: {
  images: string[];
  activeIndex: number;
  onClick: (index: number) => void;
}) => {
  return (
    <div className="h-max flex gap-5 items-center justify-center absolute bottom-5 bg-[rgba(107,114,128,0.6)] w-max px-5 py-3 mx-auto right-0 left-0 rounded-2xl courselIndicator">
      {images.map((image: string, index: number) => {
        return (
          <div
            key={index}
            className="cursor-pointer h-16 w-16 sm:w-20 sm:h-20 bg-gray-50 flex rounded-xl overflow-hidden"
            style={
              index === activeIndex
                ? {
                    border: "2px solid red",
                  }
                : {}
            }
            onClick={() => onClick(index)}
          >
            <img
              src={image}
              alt={`Slide ${index}`}
              className="w-full h-full object-cover"
            />
          </div>
        );
      })}
    </div>
  );
};
const Carousell = ({ images }: { images: string[] }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const nextSlide = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };
  const prevSlide = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };
  const goToSlide = (index: number) => {
    setActiveIndex(index);
  };
  return (
    <div className="carousel flex justify-center ">
      <div className="flex relative h-full  justify-center items-center bg-[rgba(0,0,0,0.1)] w-full">
        <button
          onClick={prevSlide}
          className="carousel__btn carousel__btn--prev"
        >
          <CaretLeftOutlined />
        </button>
        <Image
          src={images[activeIndex]}
          alt={`Slide ${activeIndex}`}
          className="carousel__img object-contain"
        />
        <button
          onClick={nextSlide}
          className="carousel__btn carousel__btn--next"
        >
          <CaretRightOutlined />
        </button>
      </div>
      <CarouselIndicators
        images={images}
        activeIndex={activeIndex}
        onClick={goToSlide}
      />
    </div>
  );
};
export default Carousell;
