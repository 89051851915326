import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { unAuthicatedAdmin } from "./adminAuthSlice";

export type ErrorObject = {
  message: string;
  code: number;
  errorField?: string;
};
export type ErrorType = {
  authenticated?: false;
  error: ErrorObject | null;
};

const initialState: ErrorType = { error: null };
const errorSlice = createSlice({
  name: "error",
  initialState: initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setError.pending, (state) => {});
    builder.addCase(setError.fulfilled, (state, action) => {
      if (action.payload.error) {
        state.error = {
          message: action.payload.error?.message,
          code: action.payload.error?.code,
          errorField: action.payload.error.errorField,
        };
      }
    });
    builder.addCase(setError.rejected, (state) => {});
  },
});

export const errorReducer = errorSlice.reducer;
export const { clearError } = errorSlice.actions;

export const setError = createAsyncThunk(
  "authentication/setError",
  (data: ErrorType, { dispatch }) => {
    if (data.authenticated === false) {
      dispatch(unAuthicatedAdmin());
    }
    return data;
  }
);
