import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CartItem, CartSliceType } from "../../util/types";
import { fetchRequest } from "../../util/util";
import { FetchResponse } from "./productsSlice";

const initialState: CartSliceType = {
  cartItems: [],
  totalItemsCount: 0,
  totalPrice: 0,
  loadingProducts: true,
};
const saveCartToSession = (
  cartState: Omit<CartSliceType, "cartItemsFromBackend" | "loadingProducts">
) => {
  sessionStorage.setItem("cart", JSON.stringify(cartState));
};
const loadCartFromSession = (): CartSliceType => {
  const cartData = sessionStorage.getItem("cart");
  return cartData ? JSON.parse(cartData) : initialState;
};

const userCart = createSlice({
  name: "cart",
  initialState: loadCartFromSession(),
  reducers: {
    addToCart: (
      state,
      action: PayloadAction<{
        product: CartItem;
      }>
    ) => {
      const { product } = action.payload;
      const { count } = product;
      const existingItem = state.cartItems.find(
        (item) => item.product._id === product.product._id
      );
      if (existingItem) {
        existingItem.count += product.count;
      } else {
        state.cartItems.push({ ...product, count: count });
      }
      state.totalItemsCount++;
      const itemHasDiscout = product.product.hasDiscount;
      state.totalPrice +=
        itemHasDiscout && product.product.discountPrice
          ? product.product.discountPrice * count
          : product.product.price * count;
      const storedItem = {
        cartItems: state.cartItems,
        totalItemsCount: state.totalItemsCount,
        totalPrice: state.totalPrice,
      };
      saveCartToSession(storedItem);
    },
    deleteFromCart: (state, action: PayloadAction<{ _id: string }>) => {
      const { _id } = action.payload;
      const itemIndex = state.cartItems.findIndex(
        (item) => item.product._id === _id
      );

      if (itemIndex !== -1) {
        const cartItem = state.cartItems[itemIndex];
        const itemHasDiscout = cartItem.product.hasDiscount;
        state.totalItemsCount -= cartItem.count;
        state.totalPrice -=
          itemHasDiscout && cartItem.product.discountPrice
            ? cartItem.product.discountPrice * cartItem.count
            : cartItem.product.price * cartItem.count;
        state.cartItems.splice(itemIndex, 1);
      }
      const storedItem = {
        cartItems: state.cartItems,
        totalItemsCount: state.totalItemsCount,
        totalPrice: state.totalPrice,
      };
      saveCartToSession(storedItem);
    },
    minusFromCart: (
      state,
      action: PayloadAction<{
        _id: string; // The unique identifier of the product
      }>
    ) => {
      const { _id } = action.payload;

      // Find the item in the cart
      const existingItem = state.cartItems.find(
        (item) => item.product._id === _id
      );

      if (existingItem) {
        // Decrease the count of the item
        existingItem.count--;

        // Check if the count has reached 0
        if (existingItem.count <= 0) {
          // Remove the product from the cart
          state.cartItems = state.cartItems.filter(
            (item) => item.product._id !== _id
          );
        }

        // Recalculate totals
        const itemHasDiscount = existingItem.product.hasDiscount;
        state.totalPrice -=
          itemHasDiscount && existingItem.product.discountPrice
            ? existingItem.product.discountPrice
            : existingItem.product.price;

        state.totalItemsCount--;
      }

      // Persist updated cart state to session storage
      const storedItem = {
        cartItems: state.cartItems,
        totalItemsCount: state.totalItemsCount,
        totalPrice: state.totalPrice,
      };
      saveCartToSession(storedItem);
    },
    resetCart: (state) => {
      state.cartItems = [];
      state.totalItemsCount = 0;
      state.totalPrice = 0;
      state.loadingProducts = true;
      saveCartToSession(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCartProductsThunk.pending, (state) => {
      state.loadingProducts = true;
    });
    builder.addCase(
      getCartProductsThunk.fulfilled,
      (state, action: PayloadAction<FetchResponse>) => {
        state.loadingProducts = false;
        if (action.payload.error) {
          return;
        }
        // Fetched products from the backend (e.g., array of products without `count`)
        const fetchedProducts = action.payload.products; // Adjust based on your API response structure
        // Create a map of fetched products for quick lookup by `_id`
        const fetchedProductsMap = new Map(
          fetchedProducts?.map((product) => [product._id, product])
        );

        // Update cartItems with new product data while preserving `count`
        state.cartItems = state.cartItems.map((oldCartItem) => {
          const updatedProduct = fetchedProductsMap.get(
            oldCartItem.product._id
          );

          if (updatedProduct) {
            // Replace old product details with new ones, preserving the count
            return {
              ...oldCartItem,
              product: updatedProduct,
            };
          }
          return oldCartItem; // Retain old cartItem if not found in fetched data
        });

        // Recalculate totals
        let totalItemsCount = 0;
        let totalPrice = 0;

        state.cartItems.forEach((cartItem) => {
          const { count, product } = cartItem;
          totalItemsCount += count;

          const itemHasDiscount = product.hasDiscount;
          totalPrice +=
            itemHasDiscount && product.discountPrice
              ? product.discountPrice * count
              : product.price * count;
        });

        state.totalItemsCount = totalItemsCount;
        state.totalPrice = totalPrice;

        // Persist updated cart state to session storage
        const storedItem = {
          cartItems: state.cartItems,
          totalItemsCount: state.totalItemsCount,
          totalPrice: state.totalPrice,
        };
        saveCartToSession(storedItem);
      }
    );
    builder.addCase(getCartProductsThunk.rejected, (state) => {
      state.loadingProducts = false;
    });
  },
});

export const getCartProductsThunk = createAsyncThunk(
  "products/getSomeProducts",
  async (ids: string[]) => {
    const data: FetchResponse = await fetchRequest<FetchResponse>({
      url: `${process.env.REACT_APP_BACKEND_DOMAIN}/getSomeProducts`,
      method: "POST",
      body: { ids },
    });
    return data;
  }
);

export const { addToCart, deleteFromCart, minusFromCart, resetCart } =
  userCart.actions;
export const CartReducer = userCart.reducer;
