import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getProductDetailsThunk } from "./state/productsSlice";
import { Button, Divider, Skeleton } from "antd";
import { ShoppingCartOutlined, WhatsAppOutlined } from "@ant-design/icons";
import ProductCard from "./common/productCard";
import Carousell from "./carousel";
import { useWindowDimensions } from "./home/home";
import {
  useAppDispatch,
  useAppSelector,
} from "../hooks/useAppDispatchSelector";
import {
  capitalizeWord,
  formatMakeAndModel,
  formatYearString,
} from "../util/util";
import { addToCart } from "./state/cartSlice";
export default function ProductDetail() {
  const { width } = useWindowDimensions();

  const product = useAppSelector(
    (state) => state.productsReducer.productDetails
  );
  const loading = useAppSelector(
    (state) => state.productsReducer.loadingDetailedProduct
  );

  const randomProducts = useAppSelector(
    (state) => state.productsReducer.randomProducts
  ).filter((randomProduct) => randomProduct._id !== product?._id);

  const params = useParams();

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!params.id) return;
    dispatch(getProductDetailsThunk(params.id));
  }, [params.id]);
  useEffect(() => {
    if (!loading) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [loading]);

  const handleWhatsAppClick = () => {
    const phoneNumber = process.env.REACT_APP_WHATSAPP_NUMBER;
    const message = `Hello, I am asking about this product: ${window.location.href}`;
    const encodedMessage = encodeURIComponent(message);
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodedMessage}`;
    window.open(whatsappUrl, "_blank");
  };

  const images: string[] = [];
  product && images.push(product?.coverPhoto.url);
  product?.additionalPhotos?.forEach((image) => {
    images.push(image.url);
  });

  return !loading && product ? (
    <div className="w-full px-10 pt-24">
      <div className="container mx-auto md:my-40 my-20">
        <div className="flex flex-col lg:flex-row items-center justify-between relative">
          <div className=" lg:w-3/6 w-full  h-[25rem] sm:h-[50rem] rounded-xl overflow-auto mb-10 lg:mb-0 ">
            <Carousell images={images} />
          </div>
          <div className=" lg:w-3/5 sm:h-[50rem] flex px-0 sm:px-14 flex-col items-center sm:items-start w-full">
            <p className="capitalize text-2xl text-gray-600">
              {product.category.join(" / ")}
            </p>
            <div className="mt-5 flex flex-col gap-5 items-start w-full">
              <div className="flex flex-col gap-5">
                <p className="capitalize text-5xl text-black mx-auto mb-3">
                  {capitalizeWord(product.name)}
                </p>
                <p className="text-2xl">
                  <span className="font-bold">Car Brand:</span>{" "}
                  {formatMakeAndModel({
                    make: product.make,
                    model: product.model,
                  })}
                </p>
                <p className="text-2xl">
                  {formatYearString({
                    yearFrom: product.yearFrom,
                    yearTo: product.yearTo,
                  })}
                </p>
              </div>
              <div className="flex gap-5 items-center flex-wrap sm:justify-start justify-center">
                {product.productQuality && (
                  <div
                    className="w-max px-7 py-2 bg-blue_primary rounded-full mt-3 shadow-2xl"
                    style={{
                      boxShadow: " 0px 0px 8px 0px rgba(0,0,0,0.40)",
                    }}
                  >
                    <p className="text-white text-xl ">
                      {capitalizeWord(
                        product.productQuality?.replaceAll("_", " ")
                      )}
                    </p>
                  </div>
                )}
                {product.hasDiscount && (
                  <div
                    className="w-max px-7 py-2 bg-blue_primary rounded-full mt-3 shadow-2xl flex gap-2"
                    style={{
                      boxShadow: " 0px 0px 8px 0px rgba(0,0,0,0.40)",
                    }}
                  >
                    <p className="text-white text-xl">Sale</p>
                    <p className="text-white text-xl">
                      {product.discountPrice &&
                        (
                          ((product.price - product?.discountPrice) /
                            product.price) *
                          100
                        ).toFixed(0)}{" "}
                      %
                    </p>
                  </div>
                )}
                {product.inStock ? (
                  <div
                    className="w-max px-7 py-2 bg-blue_primary rounded-full mt-3 shadow-2xl capitalize"
                    style={{
                      boxShadow: " 0px 0px 8px 0px rgba(0,0,0,0.40)",
                    }}
                  >
                    <p className="text-white text-xl ">In Stock</p>
                  </div>
                ) : (
                  <div
                    className="w-max px-7 py-2 bg-red-700 rounded-full mt-3 shadow-2xl capitalize"
                    style={{
                      boxShadow: " 0px 0px 8px 0px rgba(0,0,0,0.40)",
                    }}
                  >
                    <p className="text-white text-xl ">Out Of Stock</p>
                  </div>
                )}
              </div>
              <div className="flex gap-5 w-full mt-5">
                {product.hasDiscount && (
                  <p className="capitalize text-4xl text-black">
                    {product?.discountPrice?.toFixed(2)} EGP
                  </p>
                )}
                <p
                  className={
                    product.hasDiscount
                      ? "capitalize text-4xl text-gray-600 line-through"
                      : "capitalize text-4xl text-black"
                  }
                >
                  {product.price.toFixed(2)} EGP
                </p>
              </div>
            </div>

            <div className="w-full">
              <p
                className="text-2xl mt-10 whitespace-pre-wrap "
                style={
                  product.descriptionLanguage === "ar"
                    ? {
                        direction: "rtl",
                      }
                    : {
                        direction: "ltr",
                      }
                }
              >
                {product.description.charAt(0).toUpperCase() +
                  product.description.slice(1)}
              </p>
            </div>
            <div className="flex w-full md:mt-24 mt-10 gap-10 md:flex-row flex-col">
              <Button
                className="flex items-center justify-center gap-5 bg-blue_primary hover:!bg-blue_primary !py-7 !px-3 w-full"
                onClick={() => {
                  dispatch(
                    addToCart({
                      product: {
                        count: 1,
                        product,
                      },
                    })
                  );
                }}
              >
                <p className="text-white">Add to cart</p>
                <ShoppingCartOutlined className="text-white text-3xl" />
              </Button>
              <Button
                className="flex items-center justify-center gap-5 bg-gray-50 mx-auto font-semibold w-full !py-7"
                onClick={() => {
                  handleWhatsAppClick();
                }}
              >
                <p>Ask For This Product on Whatsapp</p>
                <WhatsAppOutlined className="text-whatsap_color" />
              </Button>
            </div>
          </div>
        </div>

        <div className="w-full mt-20 sm:mt-0 lg:mt-40">
          <div className="w-full h-[1px] bg-gray-300 " />
          <p className="text-3xl py-4 border-t-2 w-max border-black pr-10 font-semibold -translate-y-[1px]">
            Description
          </p>
          <div className="flex flex-col mt-20 sm:mt-32">
            <p className="capitalize sm:text-5xl text-4xl text-black font-semibold pb-5 px-12  sm:pr-12 border-b-2 border-blue_primary w-max mx-auto">
              Product Description
            </p>
            {product.additionalInfo && product.additionalInfo.length > 0 && (
              <p
                className="text-2xl whitespace-pre-wrap text-black mt-14 leading-9"
                style={
                  product.descriptionLanguage === "ar"
                    ? {
                        wordSpacing: "1px",
                        direction: "rtl",
                      }
                    : {
                        wordSpacing: "1px",
                        direction: "ltr",
                      }
                }
              >
                {product.additionalInfo}
              </p>
            )}
          </div>
          <Divider className="bg-gray-300 my-16 sm:my-32" />

          <p className="capitalize sm:text-5xl text-4xl text-black font-semibold pb-5 sm:pr-12 px-12  border-b-2 border-blue_primary w-max mb-14 mx-auto">
            Related Products
          </p>
          <div className="flex gap-20  w-full randomProducts">
            {randomProducts.map((product, i) => {
              if (i > 3) return null;
              return (
                <ProductCard
                  key={product._id}
                  product={product}
                  imageSource={product.coverPhoto.url}
                  targetUrl={`/products/${product.category[0]}/${product._id}`}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="container flex flex-col lg:flex-row mx-auto my-40 gap-10  items-center">
      <Skeleton.Avatar
        shape={"square"}
        size={width > 560 ? 600 : width > 440 ? 400 : 300}
      />
      <Skeleton
        className="px-10"
        paragraph={{ rows: width > 550 ? 15 : 3 }}
      ></Skeleton>
    </div>
  );
}
