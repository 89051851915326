import { removeLocalStorage } from "./../../util/util";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AdminLoginValues } from "../../admin/login";
import { ErrorObject, clearError, setError } from "./errorSlice";
import { fetchRequest, removeCookie, setLocalStorage } from "../../util/util";
export type UserType = {
  name: string;
  email: string;
  token: string;
  role: "USER";
};
export type UserSignupValues = {
  email: string;
  password: string;
  confirmPassword: string;
  name: string;
};
type verifyOtpValues = {
  email: string;
  otp: string;
};
type UserAuthenticationState = {
  loading: boolean;
  loginLoading: boolean;
  signupLoading: boolean;
  updateUserSettingsLoading: boolean;
  userAuthenticated: boolean;
  user: UserType | null;
  errorMessage?: string;
  successMessage?: string;
  verifyOtpLoading: boolean;
  openLoginPopup: boolean;
};

export type FetchResponse = {
  userAuthenticated: boolean;
  user: UserType;
  error?: ErrorObject;
  message: string;
};
const initialState: UserAuthenticationState = {
  loading: true,
  loginLoading: false,
  signupLoading: false,
  userAuthenticated: false,
  user: null,
  updateUserSettingsLoading: false,
  errorMessage: undefined,
  verifyOtpLoading: false,
  successMessage: undefined,
  openLoginPopup: false,
};

const userAuthenticationSlice = createSlice({
  name: "userAuthentication",
  initialState: initialState,
  reducers: {
    unAuthicatedUser: (state) => {
      state.userAuthenticated = false;
      state.user = null;
    },
    clearUserAuthMessageError: (state) => {
      state.errorMessage = undefined;
    },
    setopenLoginPopup: (state, payload: PayloadAction<{ state: boolean }>) => {
      state.openLoginPopup = payload.payload.state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fethcingUserCredentials.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fethcingUserCredentials.fulfilled,
      (state, action: PayloadAction<FetchResponse>) => {
        if (action.payload.error) {
        }
        state.loading = false;
        state.userAuthenticated = action.payload.userAuthenticated;
        state.user = action.payload.user;
      }
    );
    // USER LOIGN
    builder.addCase(userLogin.rejected, (state, action) => {
      state.loginLoading = false;
      state.errorMessage = action.error?.message;
    });
    builder.addCase(userLogin.pending, (state) => {
      state.loginLoading = true;
      state.errorMessage = undefined;
    });
    builder.addCase(
      userLogin.fulfilled,
      (state, action: PayloadAction<FetchResponse>) => {
        state.loginLoading = false;
        state.errorMessage = action.payload.error?.message;
        state.userAuthenticated = action.payload.userAuthenticated;
        state.user = action.payload.user;
        state.successMessage = action.payload.message;

        if (action.payload.user) {
          state.user.token = action.payload?.user?.token;
          setLocalStorage({
            name: "spare_auth_token",
            value: action.payload.user.token,
          });
        }
      }
    );
    // USER CREATE
    builder.addCase(userCreate.rejected, (state, action) => {
      state.signupLoading = false;
      state.errorMessage = action.error?.message;
    });
    builder.addCase(userCreate.pending, (state) => {
      state.signupLoading = true;
      state.errorMessage = undefined;
    });
    builder.addCase(
      userCreate.fulfilled,
      (state, action: PayloadAction<FetchResponse>) => {
        state.errorMessage = action.payload.error?.message;
        state.signupLoading = false;
        state.userAuthenticated = action.payload.userAuthenticated;
        state.user = action.payload.user;
        state.successMessage = action.payload.message;
      }
    );
    // VERIFY OTP
    builder.addCase(userVerifyOtp.rejected, (state, action) => {
      state.verifyOtpLoading = false;
      state.errorMessage = action.error?.message;
    });
    builder.addCase(userVerifyOtp.pending, (state) => {
      state.verifyOtpLoading = true;
      state.errorMessage = undefined;
    });
    builder.addCase(
      userVerifyOtp.fulfilled,
      (state, action: PayloadAction<FetchResponse>) => {
        state.errorMessage = action.payload.error?.message;
        state.verifyOtpLoading = false;
        state.userAuthenticated = action.payload.userAuthenticated;
        state.user = action.payload.user;
        state.successMessage = action.payload.message;
        if (action.payload?.user?.token) {
          setLocalStorage({
            name: "spare_auth_token",
            value: action.payload.user.token,
          });
        }
      }
    );
    // RESEND OTP
    builder.addCase(userResendOtp.rejected, (state, action) => {});
    builder.addCase(userResendOtp.pending, (state) => {
      state.errorMessage = undefined;
    });
    builder.addCase(
      userResendOtp.fulfilled,
      (state, action: PayloadAction<FetchResponse>) => {
        state.errorMessage = action.payload.error?.message;
      }
    );

    builder.addCase(userLogout.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      userLogout.fulfilled,
      (state, action: PayloadAction<FetchResponse>) => {
        removeLocalStorage({ name: "spare_auth_token" });
        state.loading = false;
        state.userAuthenticated = action.payload.userAuthenticated;
        state.user = action.payload.user;
      }
    );
    builder.addCase(userLogout.rejected, (state) => {
      state.loading = false;
    });
  },
});
export const fethcingUserCredentials = createAsyncThunk(
  "userAuthentication/fethcingUserCredentials",
  async (_, { dispatch }) => {
    const data: FetchResponse = await fetchRequest<FetchResponse>({
      url: `${process.env.REACT_APP_BACKEND_DOMAIN}/checkAuth`,
      method: "GET",
      dispatch,
    });
    return data;
  }
);
export const userLogin = createAsyncThunk(
  "userAuthentication/userLogin",
  async (credentials: AdminLoginValues, { dispatch }) => {
    const data = await fetch(`${process.env.REACT_APP_BACKEND_DOMAIN}/login`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(credentials),
    })
      .then((res) => res.json())
      .then((data: FetchResponse) => {
        if (data.error) throw data;
        dispatch(clearError());
        return data;
      })
      .catch((err) => {
        dispatch(setError(err));
        return err;
      });

    return data;
  }
);
export const userResendOtp = createAsyncThunk(
  "userAuthentication/userResendOtp",
  async (credentials: { email: string }, { dispatch }) => {
    const data = await fetch(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/resend-otp`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(credentials),
      }
    )
      .then((res) => res.json())
      .then((data: FetchResponse) => {
        if (data.error) throw data;
        dispatch(clearError());

        return data;
      })
      .catch((err) => {
        dispatch(setError(err));
        return err;
      });

    return data;
  }
);
export const userVerifyOtp = createAsyncThunk(
  "userAuthentication/userVerifyOtp",
  async (credentials: verifyOtpValues, { dispatch }) => {
    const data = await fetch(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/verify-otp`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(credentials),
      }
    )
      .then((res) => res.json())
      .then((data: FetchResponse) => {
        if (data.error) throw data;
        dispatch(clearError());
        return data;
      })
      .catch((err) => {
        dispatch(setError(err));
        return err;
      });

    return data;
  }
);
export const userCreate = createAsyncThunk(
  "userAuthentication/userCreate",
  async (credentials: UserSignupValues, { dispatch }) => {
    const newUser = { ...credentials };
    const data = await fetch(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/create-user`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ newUser }),
      }
    )
      .then((res) => res.json())
      .then((data: FetchResponse) => {
        if (data.error) throw data;
        dispatch(clearError());

        return data;
      })
      .catch((err) => {
        dispatch(setError(err));
        return err;
      });

    return data;
  }
);

export const userLogout = createAsyncThunk(
  "userAuthentication/userLogout",
  async (_, { dispatch }) => {
    const data = await fetch(`${process.env.REACT_APP_BACKEND_DOMAIN}/logout`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data: FetchResponse) => {
        if (data.error) throw data;
        dispatch(clearError());

        return data;
      })
      .catch((err) => {
        dispatch(setError(err));
        return err;
      });

    return data;
  }
);
export const {
  unAuthicatedUser,
  clearUserAuthMessageError,
  setopenLoginPopup,
} = userAuthenticationSlice.actions;
export const userAuthenticationReducer = userAuthenticationSlice.reducer;
