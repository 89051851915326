import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clearSuccessMessage, setSuccessMessage } from "./successSlice";
import { ErrorObject, clearError, setError } from "./errorSlice";

type FetchResponse = {
  message: string;
  error?: ErrorObject;
  success: boolean;
};

type AdminResetPasswordState = {
  loading: boolean;
  errorMessage?: string;
};
const initialState: AdminResetPasswordState = {
  loading: false,
  errorMessage: undefined,
};

const adminResetPassword = createSlice({
  name: "adminResetPassword",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(adminResetPasswordThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      adminResetPasswordThunk.fulfilled,
      (state, action: PayloadAction<FetchResponse>) => {
        state.loading = false;
        if (action.payload.error) {
          state.errorMessage = action.payload.error.message;
          //   state.errorMessage = action.payload;
          // state.categories = [];
          return;
        }
        state.errorMessage = undefined;
      }
    );
    builder.addCase(adminResetPasswordThunk.rejected, (state) => {
      state.loading = false;
    });
  },
});
export const adminResetPasswordThunk = createAsyncThunk(
  "resetPassword/resetPassword",
  async (
    passwordData: {
      currentPasswrod: string;
      newPassword: string;
      confirmPasswords: string;
    },
    { dispatch }
  ) => {
    const data: FetchResponse = await fetch(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/admin/reset-password`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          passwordData,
        }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data: FetchResponse) => {
        if (data.error) {
          throw data;
        }
        dispatch(setSuccessMessage(data.message));
        dispatch(clearError());
        return data;
      })
      .catch((err) => {
        dispatch(clearSuccessMessage());
        dispatch(setError(err));
        return err;
      });

    return data;
  }
);

export const adminResetPasswordReducer = adminResetPassword.reducer;
