import { Button, Form, Input, Select, Spin, Upload, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "./styles/addProduct.css";
import { useEffect, useState } from "react";
import type { FormInstance } from "antd";
import { InboxOutlined } from "@ant-design/icons";

import { getCategoriesThunk } from "../components/state/categoriesSlice";
import {
  AdminProduct,
  FileItem,
  NewProductValues,
  addProductThunk,
  editProductThunk,
} from "../components/state/adminProductsSlice";
import AdminUploadImage from "../components/common/adminUploadImage";
import { ProductQualityTypes } from "../util/adminTypes";
import {
  getMakesThunk,
  getModelsThunk,
} from "../components/state/adminMakeModelsSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../hooks/useAppDispatchSelector";
import { yearsArray } from "../util/adminUtil";

type AddEditProps =
  | {
      addOrEdit: "edit";
      product: AdminProduct | null;
      closeEditModal: () => void;
      editForm: FormInstance;
    }
  | {
      addOrEdit: "add";
    };

export default function AddEditProduct(props: AddEditProps) {
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useAppDispatch();
  const categoriesSelector = useAppSelector(
    (state) => state.categoriesReducer.categories
  );
  const makesSelector = useAppSelector((state) => state.makeModelReducer).makes;
  const loadingMake = useAppSelector(
    (state) => state.makeModelReducer
  ).makeLoading;
  const modelsSelector = useAppSelector(
    (state) => state.makeModelReducer
  ).models;
  const loadingModel = useAppSelector(
    (state) => state.makeModelReducer
  ).modelLoading;
  const loadingCategoriesSelector = useAppSelector(
    (state) => state.categoriesReducer.loading
  );
  const loadingAddingProductSelector = useAppSelector(
    (state) => state.adminProductsReducer.addProductLoading
  );
  const [disableDiscountPrice, setDisableDiscountPrice] =
    useState<boolean>(true);
  const [coverPhoto, setCoverPhoto] = useState<FileItem | null>(null);
  const [largeCoverPhoto, setLargeCoverPhoto] = useState<boolean>(false);
  const [additionalPhotos, setAdditionalPhotos] = useState<FileItem[] | []>([]);
  const [deletedCoverPhoto, setDeletedCoverPhoto] = useState<
    {
      Key?: string;
    }[]
  >([]);
  const [deletedAdditionalPhotos, setDeletedAdditionalPhotos] = useState<
    {
      Key?: string;
    }[]
  >([]);

  const [disableModelField, setDisableModelField] = useState(true);
  const [disableYearTo, setDisableYearTo] = useState(false);
  const { Dragger } = Upload;
  let [addForm] = Form.useForm();
  const removeCoverImage = () => {
    setCoverPhoto(null);
    setLargeCoverPhoto(false);
  };
  const removeAdditionalImage = (fileName: FileItem) => {
    const newAdditionalPhotos = additionalPhotos?.filter(
      (photo) => photo.uid !== fileName.uid
    ) as FileItem[] | [];
    if (props.addOrEdit === "edit") {
      const deletedAddPhotos = props.product?.additionalPhotos?.filter(
        (photo) => {
          // I am doing this because previously I assigned the uid value to the key value for the existing photos
          return photo.key === fileName.uid;
        }
      );

      if (deletedAddPhotos && deletedAddPhotos?.length > 0) {
        setDeletedAdditionalPhotos((prev) => {
          return [...prev, { Key: fileName.key }];
        });
      }
    }
    setAdditionalPhotos(newAdditionalPhotos);
  };
  useEffect(() => {
    dispatch(getCategoriesThunk());
    dispatch(getMakesThunk());
  }, []);
  useEffect(() => {
    if (props.addOrEdit === "edit" && props.product) {
      if (makesSelector.length) {
        const makeId = makesSelector?.filter(
          (make) => make.name === props.product?.make
        )[0]?._id;

        dispatch(getModelsThunk(makeId));
        setDisableModelField(false);
      }
    }
  }, [makesSelector]);
  useEffect(() => {
    async function fetchData() {
      if (props.addOrEdit === "add") {
        const initialValues: NewProductValues = {
          name: "test",
          description: "test description",
          additionalInfo: "testAdd",
          price: 100,
          stockNumber: 1,
          inStock: true,
          category: [],
          discountPrice: null,
          hasDiscount: false,
          descriptionLanguage: "en",
          colors: ["white", "black"],
          coverPhoto: null,
          additionalPhotos: [],
          isShown: true,
          productQuality: ProductQualityTypes.ORIGINAL,
          make: "",
          model: "",
          yearFrom: "2020",
          yearTo: "2025",
          productNumber: "test",
        };

        addForm.setFieldsValue(initialValues);
      } else if (props.addOrEdit === "edit" && props.product) {
        if (!props.product || !props.product.coverPhoto.key) return;

        if (props.product.coverPhoto)
          setCoverPhoto({
            uid: props.product.coverPhoto.key,
            key: props.product.coverPhoto.key,
            url: props.product.coverPhoto.url,
            name: props.product.coverPhoto.name,
          });
        if (props.product.additionalPhotos) {
          const addPhotos: FileItem[] = props.product.additionalPhotos.map(
            (photo) => {
              return {
                uid: photo.key,
                key: photo.key,
                url: photo.url,
                name: photo.name,
              };
            }
          );
          setAdditionalPhotos(addPhotos);
        }
        props.product.hasDiscount
          ? setDisableDiscountPrice(false)
          : setDisableDiscountPrice(true);

        const initialValues: AdminProduct = {
          _id: props.product._id,
          name: props.product.name,
          description: props.product.description,
          additionalInfo: props.product.additionalInfo,
          price: props.product.price,
          inStock: props.product.inStock,
          category: props.product.category,
          yearFrom: props.product.yearFrom,
          yearTo: props.product.yearTo,
          productNumber: props.product.productNumber,
          hasDiscount: props.product.hasDiscount,
          discountPrice: props.product.discountPrice,
          descriptionLanguage: props.product.descriptionLanguage,
          colors: props.product.colors,
          coverPhoto: {
            key: props.product.coverPhoto.key,
            uid: props.product.coverPhoto.key,
            url: props.product.coverPhoto.url,
            name: props.product.coverPhoto?.name,
          },
          isShown: props.product.isShown,
          stockNumber: props.product?.stockNumber,
          productQuality: props.product.productQuality,
          make: makesSelector?.filter(
            (make) => make.name === props.product?.make
          )[0]?._id,
          model: props.product.model,
        };
        props.editForm.setFieldsValue(initialValues);
      }
    }
    fetchData();
  }, [props.addOrEdit === "edit" && props.product, props, makesSelector]);

  const handleSubmitAddProduct = (): void => {
    addForm.validateFields().then(async (values: NewProductValues) => {
      if (!coverPhoto || props.addOrEdit !== "add") return;
      const updateValues = {
        ...values,
        make: makesSelector.filter((make) => values.make === make._id)[0].name,
      };
      await dispatch(
        addProductThunk({
          values: updateValues,
          coverPhoto,
          additionalPhotos,
        })
      );
      setAdditionalPhotos([]);
      setCoverPhoto(null);
      addForm.resetFields();
    });
  };

  const handleSubmitEditProduct = (): void => {
    if (props.addOrEdit === "add") return;
    props.editForm.validateFields().then(async (values: NewProductValues) => {
      if (!props.product?._id) return;
      const urlsForDeletedAdditionalImages =
        deletedAdditionalPhotos.length > 1
          ? deletedAdditionalPhotos.map((image) => image?.Key || "")
          : null;
      const updateValues = {
        ...values,
        make: makesSelector.filter((make) => values.make === make._id)[0].name,
      };

      await dispatch(
        editProductThunk({
          values: { ...updateValues, additionalPhotos, coverPhoto },
          id: props.product?._id,
          deletedCoverPhoto: deletedCoverPhoto[0]?.Key || null,
          deletedAdditionalPhotos: urlsForDeletedAdditionalImages,
        })
      );
      setCoverPhoto(null);
      setLargeCoverPhoto(false);
      setDeletedCoverPhoto([]);
      setAdditionalPhotos([]);
      props.closeEditModal();
    });
  };
  function compareArraysByProperty(
    arr1: FileItem[],
    arr2: FileItem[]
  ): FileItem[] {
    const map = new Map<string, FileItem>();

    // Add all objects from arr1 to the map
    arr1.forEach((item) => {
      if (item.name) {
        map.set(item["name"], item);
      }
    });

    // Add objects from arr2 to the map only if their 'name' property doesn't already exist
    arr2.forEach((item) => {
      if (item.name) {
        if (!map.has(item["name"])) {
          map.set(item["name"], item);
        }
      }
    });

    // Return an array of unique objects
    return Array.from(map.values());
  }

  return (
    <>
      <Spin
        indicator={<LoadingOutlined />}
        size="large"
        fullscreen
        className="addProductSpinner"
        style={{
          transform: "scale(1.2)",
        }}
        tip="Loading form"
        spinning={loadingCategoriesSelector || loadingMake}
      ></Spin>
      {contextHolder}
      <h2 className="mb-24 text-6xl text-center font-semibold">
        {props.addOrEdit === "add" && "Add New Product"}
        {props.addOrEdit === "edit" && "Edit Product"}
      </h2>
      <Form
        onFinish={
          props.addOrEdit === "add"
            ? handleSubmitAddProduct
            : props.addOrEdit === "edit"
            ? handleSubmitEditProduct
            : undefined
        }
        form={
          props.addOrEdit === "add"
            ? addForm
            : props.addOrEdit === "edit"
            ? props.editForm
            : undefined
        }
        className="addProductForm flex-col flex gap-8 container mx-auto h-max"
      >
        <div className="w-full flex gap-24">
          <Form.Item
            className="w-full"
            rules={[
              {
                validator: (_, value) => {
                  if (value && value.trim().length !== 0) {
                    // If the value is not empty and doesn't contain any whitespace
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Please enter a valid product name")
                  );
                },
              },
            ]}
            required
            name={"name"}
            label="Product Name"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={"price"}
            className="w-full"
            label="Product Price"
            rules={[
              {
                required: true,
                message: "Please enter a valid positive number!",
              },
              {
                validator: (_, value) => {
                  if (!value || /^\d*\.?\d+$/.test(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Please enter a valid positive number!")
                  );
                },
              },
            ]}
          >
            <Input addonAfter="EGP" />
          </Form.Item>
        </div>
        <div className="w-full flex gap-24">
          <Form.Item
            required
            name={"category"}
            className="w-full"
            label="Product Category"
            rules={[
              {
                required: true,
                message: "Please select a category",
              },
            ]}
          >
            <Select
              mode="multiple"
              options={[
                ...categoriesSelector.map((category) => {
                  return {
                    value: category.id,
                    label: <p>{category.category}</p>,
                  };
                }),
              ]}
            ></Select>
          </Form.Item>
          <Form.Item
            name={"hasDiscount"}
            className="w-full"
            label="Has Discount"
          >
            <Select
              onChange={(e: boolean) => {
                if (!e && props.addOrEdit === "edit") {
                  props.editForm.setFieldsValue({ discountPrice: null });
                }

                if (!e && props.addOrEdit === "add") {
                  addForm.setFieldsValue({ discountPrice: null });
                }
                setDisableDiscountPrice(!e);
              }}
              options={[
                {
                  value: true,
                  label: <p>Yes</p>,
                },
                {
                  value: false,
                  label: <p>No</p>,
                },
              ]}
            ></Select>
          </Form.Item>
        </div>

        <div className="w-full flex gap-24">
          <Form.Item
            name={"inStock"}
            className="w-full"
            label="Stock Availability"
            required
          >
            <Select
              options={[
                {
                  value: true,
                  label: <p>In Stock</p>,
                },
                {
                  value: false,
                  label: <p>Out Of Stock</p>,
                },
              ]}
            ></Select>
          </Form.Item>
          <Form.Item
            name={"discountPrice"}
            className="w-full"
            label="Discount Price"
            rules={[
              {
                required: !disableDiscountPrice,
                message: "Please enter a valid positive number",
              },
              {
                validator: (_, value) => {
                  if (!value || /^\d*\.?\d+$/.test(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Please enter a valid positive number!")
                  );
                },
              },
            ]}
          >
            <Input disabled={disableDiscountPrice} addonAfter="EGP" />
          </Form.Item>
        </div>
        <div className="w-full flex gap-24">
          <Form.Item
            name={"make"}
            className="w-full"
            label="Car Make"
            required
            rules={[
              {
                required: true,
                message: "Please select car make",
              },
            ]}
          >
            <Select
              loading={loadingMake}
              onChange={(e: string) => {
                if (e) {
                  setDisableModelField(false);
                  dispatch(getModelsThunk(e));
                  if (props.addOrEdit === "add") {
                    addForm.resetFields(["model"]);
                  } else if (props.addOrEdit === "edit") {
                    props.editForm.resetFields(["model"]);
                  }
                }
              }}
              options={makesSelector.map((make) => {
                return {
                  value: make._id,
                  label: <p>{make.name}</p>,
                };
              })}
            ></Select>
          </Form.Item>
          <Form.Item
            name={"model"}
            className="w-full"
            label="Car Model"
            required
            rules={[
              {
                required: true,
                message: "Please select car model",
              },
            ]}
          >
            <Select
              disabled={disableModelField || loadingModel}
              loading={loadingModel}
              options={modelsSelector?.map((model) => {
                return {
                  value: model.name,
                  label: <p>{model.name}</p>,
                };
              })}
            ></Select>
          </Form.Item>
        </div>
        <div className="w-full flex gap-24">
          <Form.Item
            name={"yearFrom"}
            className="w-full"
            label="Year From"
            required
            rules={[
              {
                required: true,
                message: "Please select a year",
              },
            ]}
          >
            <Select
              onChange={(e) => {
                setDisableYearTo(false);
                if (props.addOrEdit === "add") {
                  addForm.resetFields(["yearTo"]);
                } else if (props.addOrEdit === "edit") {
                  props.editForm.resetFields(["yearTo"]);
                }
              }}
              showSearch={true}
              options={yearsArray.map((year) => {
                return {
                  value: year,
                  label: <p>{year}</p>,
                };
              })}
            ></Select>
          </Form.Item>
          <Form.Item
            name={"yearTo"}
            className="w-full"
            label="Year To"
            required
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  if (props.addOrEdit === "add" && value) {
                    if (+value < +addForm.getFieldValue("yearFrom")) {
                      return Promise.reject(
                        new Error("Year to can't be less than year form")
                      );
                    } else if (+value >= +addForm.getFieldValue("yearFrom")) {
                      return Promise.resolve();
                    }
                  } else if (props.addOrEdit === "edit" && value) {
                    if (+value < +props.editForm.getFieldValue("yearFrom")) {
                      return Promise.reject(
                        new Error("Year to can't be less than year form")
                      );
                    } else if (
                      +value >= +props.editForm.getFieldValue("yearFrom")
                    ) {
                      return Promise.resolve();
                    }
                  } else
                    return Promise.reject(new Error("Please select a year"));
                },
              },
            ]}
          >
            <Select
              disabled={disableYearTo}
              showSearch={true}
              options={yearsArray.map((year) => {
                return {
                  value: year,
                  label: <p>{year}</p>,
                };
              })}
            ></Select>
          </Form.Item>
        </div>
        {/* add stock number here */}
        <div className="w-full flex gap-24">
          <Form.Item
            name={"stockNumber"}
            className="w-full"
            label="Stock number"
            rules={[
              {
                required: true,
                message: "Please enter a valid positive number!",
              },
              {
                validator: (_, value) => {
                  if (!value || /^\d*\.?\d+$/.test(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Please enter a valid positive number!")
                  );
                },
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={"productQuality"}
            className="w-full"
            label="Product Quality"
            required
          >
            <Select
              options={[
                {
                  value: ProductQualityTypes.ORIGINAL,
                  label: <p>Original</p>,
                },
                {
                  value: ProductQualityTypes.HIGH_QUALITY,
                  label: <p>High Quality</p>,
                },
              ]}
            ></Select>
          </Form.Item>
        </div>
        <div className="w-full flex gap-24">
          <Form.Item
            name={"descriptionLanguage"}
            className="w-full"
            required
            label="Description Lang"
            rules={[
              {
                required: true,
                message: "Please Select the description language",
              },
            ]}
          >
            <Select
              options={[
                {
                  value: "en",
                  label: <p>English</p>,
                },
                {
                  value: "ar",
                  label: <p>Arabic</p>,
                },
              ]}
            ></Select>
          </Form.Item>
          <Form.Item
            name={"colors"}
            className="w-full"
            label="Available Colors"
          >
            <Select
              mode="multiple"
              options={[
                {
                  value: "white",
                  label: <p>White</p>,
                },
                {
                  value: "red",
                  label: <p>Red</p>,
                },
                {
                  value: "black",
                  label: <p>Black</p>,
                },
                {
                  value: "orange",
                  label: <p>Orange</p>,
                },
                {
                  value: "green",
                  label: <p>Green</p>,
                },
                {
                  value: "yellow",
                  label: <p>Yellow</p>,
                },
                {
                  value: "gray",
                  label: <p>Gray</p>,
                },
                {
                  value: "blue",
                  label: <p>Blue</p>,
                },
                {
                  value: "purple",
                  label: <p>Purple</p>,
                },
                {
                  value: "beige",
                  label: <p>Beige</p>,
                },
              ]}
            ></Select>
          </Form.Item>
        </div>
        <div className="w-full flex gap-24">
          <Form.Item
            name={"description"}
            label="Main Description"
            className="w-full"
            required
            rules={[
              {
                required: true,
                message: "Please enter a description",
              },
              {
                validateTrigger: "onSubmit",
                validator: (_: any, value: string) => {
                  if (value && (value.length >= 5 || value.length === 0)) {
                    // If the value is not empty and meets the minimum length requirement
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "Description must be at least 100 characters long"
                    )
                  );
                },
              },
            ]}
          >
            <Input.TextArea
              placeholder="Please enter description for the product (min of 100 characters)"
              showCount
              maxLength={1200}
              minLength={5}
              rows={5}
              style={{
                resize: "none",
              }}
            />
          </Form.Item>
          <Form.Item
            label="Additional Info"
            className="w-full"
            name="additionalInfo"
          >
            <Input.TextArea
              showCount
              maxLength={10000}
              rows={5}
              style={{
                resize: "none",
              }}
            />
          </Form.Item>
        </div>
        <div className="w-full flex gap-24">
          <Form.Item
            className="w-full"
            rules={[
              {
                validator: (_, value) => {
                  if (value && value.trim().length !== 0) {
                    // If the value is not empty and doesn't contain any whitespace
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Please enter a valid product number")
                  );
                },
              },
            ]}
            required
            name={"productNumber"}
            label="product Number"
          >
            <Input />
          </Form.Item>
          <div className="w-full flex flex-col">
            <Form.Item
              name={"isShown"}
              className="w-full mb-0"
              label="Show product"
              rules={[
                {
                  required: true,
                  message: "Please select wether to hide or show the product",
                },
              ]}
            >
              <Select
                options={[
                  {
                    value: true,
                    label: <p>Show</p>,
                  },
                  {
                    value: false,
                    label: <p>Hide</p>,
                  },
                ]}
              ></Select>
            </Form.Item>
            <p className="ml-auto text-gray-400 w-max">
              To show or hide product from the website
            </p>
          </div>
        </div>
        <div className="w-full flex gap-24 ">
          <div className="w-full h-max flex flex-col">
            <Form.Item
              onReset={() => {
                setLargeCoverPhoto(false);
                setCoverPhoto(null);
                setDeletedCoverPhoto([]);
              }}
              label="Cover Photo"
              className="w-full h-max mb-5"
              name="coverPhoto"
              required
              rules={[
                {
                  required: true,
                  validateTrigger: "onSubmit",
                  validator: (_, value) => {
                    if (largeCoverPhoto) {
                      // If the value is not empty and doesn't contain any whitespace
                      return Promise.reject(new Error("Error: File Size"));
                    } else if (coverPhoto) {
                      // If the value is not empty and doesn't contain any whitespace
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Please upload a valid photo")
                    );
                  },
                },
              ]}
            >
              <Dragger
                accept=".jpg, .jpeg, .png"
                // customRequest={({ onError }) => {
                //   onError && onError(new Error("Upload failed"));
                // }}
                name="file"
                // onRemove={() => {
                //   setCoverPhoto(null);
                // }}
                beforeUpload={(file) => {
                  const coverPhotoFile: FileItem = file;
                  coverPhotoFile.url = URL.createObjectURL(file);
                  setCoverPhoto(coverPhotoFile);
                  const isLt5M = file.size / 1024 / 1024 <= 5;
                  if (!isLt5M) {
                    // setCoverPhoto(null);
                    setLargeCoverPhoto(true);
                    return false;
                  }
                  if (
                    props.addOrEdit === "edit" &&
                    props.product?.coverPhoto?.key
                  ) {
                    setDeletedCoverPhoto((prev) => {
                      const keyExists = prev.filter(
                        (photo) => photo.Key === props.product?.coverPhoto.key
                      );
                      if (keyExists.length > 0) return [...prev];

                      return [...prev, { Key: props.product?.coverPhoto.key }];
                    });
                  }

                  setLargeCoverPhoto(false);
                  // Prevent upload
                  return false;
                }}
                listType="picture"
                itemRender={(originNode, file, fileList, { remove }) => {
                  return null;
                }}
                multiple={false}
                maxCount={1}
                // {...imagesProps}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Support jpg, .jpeg, .png, Max images number for cover is one
                  image. The image should not be more than 5MB
                </p>
              </Dragger>
            </Form.Item>
            <div
              className="h-max"
              style={{
                width: "calc(100% - 150px)",
                marginLeft: "auto",
              }}
            >
              {coverPhoto && !largeCoverPhoto && (
                <AdminUploadImage
                  type="coverPhoto"
                  name={coverPhoto.name}
                  remove={removeCoverImage}
                  thumbUrl={coverPhoto.url}
                  // error={file.error}
                  // errorMessage={
                  //   file.error
                  //     ? "Error: Please upload a file that is smaller than 5MB"
                  //     : undefined
                  // }
                  error={false}
                  errorMessage={undefined}
                />
              )}
              {coverPhoto && largeCoverPhoto && (
                <AdminUploadImage
                  type="coverPhoto"
                  name={coverPhoto.name}
                  remove={removeCoverImage}
                  thumbUrl={coverPhoto.url}
                  // error={file.error}
                  // errorMessage={
                  //   file.error
                  //     ? "Error: Please upload a file that is smaller than 5MB"
                  //     : undefined
                  // }
                  error={true}
                  errorMessage={
                    "Error: Please upload a file that is smaller than 5MB"
                  }
                />
              )}
              <p className="ml-auto text-gray-400 w-max">
                {coverPhoto ? "1/1 file uploaded" : "0/1 file uploaded"}
              </p>
            </div>
          </div>
          <div className="w-full h-max flex flex-col">
            <Form.Item
              onReset={() => {
                setAdditionalPhotos([]);
                setDeletedAdditionalPhotos([]);
              }}
              label="Additional Photos"
              className="w-full h-max mb-5"
              name="additionalPhotos"
              rules={[
                {
                  required: false,
                  validateTrigger: "onSubmit",
                  validator: (_, value) => {
                    const hasError = additionalPhotos.filter(
                      (photo) => photo.error === true
                    );

                    if (hasError.length > 0) {
                      return Promise.reject(new Error("Error: File Size"));
                    } else return Promise.resolve();
                  },
                },
              ]}
            >
              <Dragger
                accept=".jpg, .jpeg, .png"
                name="file"
                customRequest={({ onError }) => {
                  onError && onError(new Error("Upload failed"));
                }}
                beforeUpload={(file, fileList) => {
                  const additionalPhotoFile: FileItem = file;
                  additionalPhotoFile.url = URL.createObjectURL(file);
                  let filesArray =
                    fileList.length <= 4 ? fileList : fileList.slice(0, 5);
                  const isLt5M = file.size / 1024 / 1024 <= 5;
                  if (!isLt5M) {
                    additionalPhotoFile.error = true;
                    additionalPhotoFile.errorMessage =
                      "Error: Please upload a file that is smaller than 5MB";
                    setAdditionalPhotos((prev) => [
                      ...prev,
                      additionalPhotoFile,
                    ]);
                    return;
                  }
                  if (additionalPhotos.length <= 4) {
                    const uniqueItems = compareArraysByProperty(
                      filesArray,
                      additionalPhotos
                    );
                    setAdditionalPhotos(uniqueItems);

                    // Prevent upload
                    return false;
                  }
                }}
                listType="picture"
                itemRender={(originNode, file, fileList, { remove }) => {
                  return null;
                }}
                multiple={true}
                maxCount={5}

                // {...imagesProps}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Support jpg, .jpeg, .png, Max images number for cover is one
                  image. The image should not be more than 5MB
                </p>
              </Dragger>
            </Form.Item>
            <div
              className="h-max"
              style={{
                width: "calc(100% - 150px)",
                marginLeft: "auto",
              }}
            >
              {additionalPhotos &&
                additionalPhotos.slice(0, 5).map((photo) => {
                  if (photo.error) {
                    return (
                      <AdminUploadImage
                        type="additionalPhoto"
                        key={photo.uid}
                        name={photo.name}
                        remove={removeAdditionalImage}
                        thumbUrl={photo.url}
                        originalPhoto={photo}
                        error={photo.error}
                        errorMessage={photo.errorMessage}
                      />
                    );
                  }
                  return (
                    <AdminUploadImage
                      type="additionalPhoto"
                      key={photo.uid}
                      name={photo.name}
                      remove={removeAdditionalImage}
                      thumbUrl={photo.url}
                      originalPhoto={photo}
                      // error={file.error}
                      // errorMessage={
                      //   file.error
                      //     ? "Error: Please upload a file that is smaller than 5MB"
                      //     : undefined
                      // }
                      error={false}
                      errorMessage={undefined}
                    />
                  );
                })}
              <p className="ml-auto text-gray-400 w-max">
                {additionalPhotos.length}/5 files uploaded
              </p>
            </div>
          </div>
        </div>

        <div className="w-full flex justify-center gap-5">
          {props.addOrEdit === "edit" && (
            <Button
              className="h-16 w-48"
              onClick={() => {
                props.closeEditModal();
              }}
            >
              Cancel
            </Button>
          )}
          <Button
            loading={loadingAddingProductSelector}
            className="h-16 generalAdminButton min-w-48"
            onClick={() => {
              props.addOrEdit === "add" && addForm.submit();
              props.addOrEdit === "edit" && props.editForm.submit();
            }}
          >
            {props.addOrEdit === "add" && "Add Product"}
            {props.addOrEdit === "edit" && "Edit Product"}
          </Button>
        </div>
      </Form>
    </>
  );
}
// const imagesProps: UploadProps = {
//   onChange(info) {
//     const { status } = info.file;

//     if (status !== "uploading") {
//     }
//     if (status === "done") {
//       message.success(`${info.file.name} file uploaded successfully.`);
//     } else if (status === "error") {
//       message.error(`${info.file.name} file upload failed.`);
//     }
//   },
//   onDrop(e) {
//     console.log("Dropped files", e.dataTransfer.files);
//   },
// };
