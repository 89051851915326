import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clearSuccessMessage, setSuccessMessage } from "./successSlice";
import { ErrorType, clearError, setError } from "./errorSlice";
import { EstimationStatus, EstimationType } from "../../util/adminTypes";
import { fetchRequest } from "../../util/util";

type FetchResponse = {
  message: string;
  error?: ErrorType;
  estimations: EstimationType[];
  success: boolean;
};

type EstimationState = {
  loadingData: boolean;
  loadingDetailedEstimation: boolean;
  estimations: EstimationType[];
  estimationDetails: EstimationType | null;
  loadingEditingEstimation: boolean;
};
const initialState: EstimationState = {
  loadingData: true,
  estimations: [],
  estimationDetails: null,
  loadingDetailedEstimation: false,
  loadingEditingEstimation: false,
};

const getEstimations = createSlice({
  name: "categories",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEstimationsThunk.pending, (state) => {
      state.loadingData = true;
    });
    builder.addCase(
      getEstimationsThunk.fulfilled,
      (state, action: PayloadAction<FetchResponse>) => {
        state.loadingData = false;
        if (action.payload.error) {
          state.estimations = [];
          return;
        }
        state.estimations = action.payload.estimations;
      }
    );
    builder.addCase(getEstimationsThunk.rejected, (state) => {
      state.loadingData = false;
    });
    builder.addCase(getDetailedEstimationsThunk.pending, (state) => {
      state.loadingDetailedEstimation = true;
    });
    builder.addCase(
      getDetailedEstimationsThunk.fulfilled,
      (
        state,
        action: PayloadAction<{
          message: string;
          error?: ErrorType;
          estimation: EstimationType;
          success: boolean;
        }>
      ) => {
        state.loadingDetailedEstimation = false;
        if (action.payload.error) {
          state.estimationDetails = null;
          return;
        }
        state.estimationDetails = action.payload.estimation;
      }
    );
    builder.addCase(getDetailedEstimationsThunk.rejected, (state) => {
      state.loadingDetailedEstimation = false;
    });
    builder.addCase(editDetailedEstimationsThunk.pending, (state) => {
      state.loadingEditingEstimation = true;
    });
    builder.addCase(
      editDetailedEstimationsThunk.fulfilled,
      (
        state,
        action: PayloadAction<{
          message: string;
          error?: ErrorType;
          estimation: EstimationType;
          success: boolean;
        }>
      ) => {
        state.loadingEditingEstimation = false;
        if (action.payload.error) {
          state.estimationDetails = null;
          return;
        }
        state.estimationDetails = action.payload.estimation;
      }
    );
    builder.addCase(editDetailedEstimationsThunk.rejected, (state) => {
      state.loadingEditingEstimation = false;
    });
  },
});

export const getEstimationsThunk = createAsyncThunk(
  "estimations/getEstimationsThunk",
  async (_, { dispatch }) => {
    const data: FetchResponse = await fetch(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/admin/estimations`,
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data: FetchResponse) => {
        if (data.error) {
          throw data;
        }
        dispatch(clearError());
        return data;
      })
      .catch((err) => {
        dispatch(setError(err));
        return err;
      });

    return data;
  }
);
export const getDetailedEstimationsThunk = createAsyncThunk(
  "estimations/getDetailedEstimationsThunk",
  async ({ id }: { id: string }, { dispatch }) => {
    const data: {
      message: string;
      error?: ErrorType;
      estimation: EstimationType;
      success: boolean;
    } = await fetchRequest({
      method: "POST",
      url: `${process.env.REACT_APP_BACKEND_DOMAIN}/admin/estimation-details`,
      body: { id },
      dispatch,
    });

    if (data.success) {
      dispatch(clearError());
    } else if (data.error) {
      dispatch(clearSuccessMessage());
      dispatch(setError(data.error));
    }

    // const dataa: FetchResponse = await fetch(
    //   `${process.env.REACT_APP_BACKEND_DOMAIN}/admin/estimation-details`,
    //   {
    //     method: "POST",
    //     credentials: "include",
    //   }
    // )
    //   .then((res) => {
    //     return res.json();
    //   })
    //   .then((data: FetchResponse) => {
    //     if (data.error) {
    //       throw data;
    //     }
    //     dispatch(clearError());
    //     return data;
    //   })
    //   .catch((err) => {
    //     dispatch(setError(err));
    //     return err;
    //   });

    return data;
  }
);
export const editDetailedEstimationsThunk = createAsyncThunk(
  "estimations/editDetailedEstimationsThunk",
  async (
    {
      id,
      estimationStatus,
    }: { id: string; estimationStatus: EstimationStatus },
    { dispatch }
  ) => {
    const data: {
      message: string;
      error?: ErrorType;
      estimation: EstimationType;
      success: boolean;
    } = await fetchRequest({
      method: "POST",
      url: `${process.env.REACT_APP_BACKEND_DOMAIN}/admin/edit-estimation`,
      body: { data: { id, estimationStatus } },
      dispatch,
    });

    if (data.success) {
      dispatch(clearError());
      dispatch(setSuccessMessage(data.message));
    } else if (data.error) {
      dispatch(clearSuccessMessage());
      dispatch(setError(data.error));
    }

    return data;
  }
);

// export const { logOut } = getEstimations.actions;
export const estimationsReducer = getEstimations.reducer;
