import { Button, Collapse, Pagination, Select, Slider, Tag } from "antd";
import "./styles/productPagesLayout.css";
import { useEffect, useState } from "react";
import { footerRoutes } from "../footer/footer";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../state/store";
import {
  clearKeywordFilter,
  filterPrice,
  pagination,
  resetFilterPrice,
  setSearchKeyword,
  sortByPrice,
} from "../state/productsSlice";
import { useWindowDimensions } from "../home/home";
import { CaretDownOutlined } from "@ant-design/icons";
import { isArrayIncludeText } from "../../util/util";
import { useAppSelector } from "../../hooks/useAppDispatchSelector";
export default function ProductsPagesLayout() {
  const { width } = useWindowDimensions();
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();
  const productsNumber = useAppSelector(
    (state) => state.productsReducer.productsNumber
  );
  const activeFilters = useAppSelector(
    (state) => state.productsReducer.activeFilters
  );
  const highestPrice = useAppSelector(
    (state) => state.productsReducer.highestPrice
  );
  const loadingProducts = useAppSelector(
    (state) => state.productsReducer.loadingProducts
  );
  const [sliderValue, setSliderValue] = useState<{
    min: number;
    max: number;
  }>({
    min: 0,
    max: 1000,
  });
  useEffect(() => {
    if (highestPrice === 0) return;
    setSliderValue((prev) => {
      return { min: prev.min, max: highestPrice };
    });
  }, [highestPrice]);
  const pageNumebr = useAppSelector(
    (state) => state.productsReducer.pageNumber
  );

  const defaultProducts = useAppSelector(
    (state) => state.productsReducer.defaultProducts
  );
  const endingNumber = pageNumebr * 12;
  const startinNumber = endingNumber - 12;
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, [location, pageNumebr]);

  function countProductsByCategory(category: string) {
    let count = 0;
    if (category === "allproducts") {
      return (count = defaultProducts?.length);
    } else {
      defaultProducts?.forEach((product) => {
        if (
          isArrayIncludeText({
            arrayStrings: product.category,
            searchTerm: category,
          })
        ) {
          count++;
        }
      });
    }
    return count;
  }
  const resetPriceFilter = () => {
    setSliderValue({ min: 0, max: Math.round(highestPrice / 2) });
    dispatch(resetFilterPrice());
  };

  return (
    <div className="bg-gray-50 px-10">
      <div className="container mx-auto pb-40 pt-56 md:pt-80 h-max lg:grid lg:grid-cols-[minmax(120px,320px)_minmax(600px,_1fr)]">
        {width >= 1024 && (
          <div className="flex flex-col h-max gap-28 pr-10">
            <div className="flex flex-col w-full">
              <h2 className="capitalize text-4xl font-semibold text-gray_primary">
                Filter by price
              </h2>
              <Slider
                styles={{
                  track: {
                    background: "transparent",
                  },
                  tracks: {
                    background: `#333399`,
                  },
                }}
                onChange={(value: number[]) => {
                  setSliderValue({
                    min: value[0],
                    max: value[1],
                  });
                }}
                range={{ draggableTrack: true }}
                defaultValue={[sliderValue.min, sliderValue.max]}
                value={[sliderValue.min, sliderValue.max]}
                max={highestPrice}
                step={10}
              />
              <div className="flex justify-between items-start flex-col gap-5">
                <div className="text-2xl flex">
                  <p className="mr-5 text-gray_primary">Price:</p>
                  <div className="font-semibold flex gap-1">
                    <p>{sliderValue.min} EGP</p>
                    <p>-</p>
                    <p>{sliderValue.max} EGP</p>
                  </div>
                </div>
                <div className="flex gap-5 items-center">
                  <Button
                    onClick={() => {
                      resetPriceFilter();
                    }}
                  >
                    Reset
                  </Button>
                  <Button
                    onClick={() => {
                      dispatch(
                        filterPrice({
                          min: sliderValue.min,
                          max: sliderValue.max,
                        })
                      );
                    }}
                    className="filterButton"
                  >
                    Filter
                  </Button>
                </div>
              </div>
            </div>
            <div>
              <h2 className="capitalize text-4xl font-semibold flex flex-col mb-10 text-gray_primary">
                Categories
              </h2>
              <div className="flex flex-col gap-5">
                {footerRoutes.map((route) => {
                  if (!route.shouldAppearInCategories) return null;
                  let className: string = `flex justify-between items-center text-2xl text-gray_primary `;
                  if (location.pathname === route.route) {
                    className = `flex justify-between items-center text-2xl !text-blue_primary `;
                  }
                  if (route.key === "allproducts") {
                    className = className + `-order-1`;
                  }

                  return (
                    <div key={route.key} className={className}>
                      <Link
                        to={route.route}
                        className={
                          location.pathname === route.route
                            ? ""
                            : "cursor-pointer hover:text-main_color_darker"
                        }
                      >
                        {route.title}
                      </Link>
                      {loadingProducts ? (
                        <div className="flex items-end justify-end ">
                          <LoadingOutlined className="text-2xl" />
                        </div>
                      ) : (
                        <p>({countProductsByCategory(route.key)})</p>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        <div className="bg-white flex flex-col items-center gap-20 px-5 sm:px-12 xl:px-20 2xl:px-32 py-20">
          <h2 className=" text-5xl sm:text-6xl font-semibold border-b-1 capitalize border-b-blue_primary px-10 pb-5 w-[85vw] sm:w-[34rem] text-center text-gray_primary">
            {activeFilters.find((filter) => filter.type === "keyword") ===
            undefined
              ? footerRoutes?.filter(
                  (category) => category.route === location.pathname
                )[0]?.title
              : "Filtered Products"}
          </h2>
          <div className="w-full flex flex-col gap-10 sm:gap-20">
            <div className="text-2xl flex flex-col sm:gap0 gap-10 sm:flex-row justify-between w-full items-center">
              <div className="flex flex-col gap-3">
                <p className="text-gray_primary">
                  Showing{" "}
                  <span className="font-semibold">
                    {startinNumber + 1} -{" "}
                    {endingNumber > productsNumber
                      ? productsNumber
                      : endingNumber}
                  </span>{" "}
                  of <span className="font-semibold">{productsNumber}</span>{" "}
                  Results
                </p>
                {activeFilters?.length > 0 && width >= 1024 && (
                  <p className="flex gap-2">
                    Active Filters:
                    {activeFilters.map((filter, i) => (
                      <Tag
                        closable={true}
                        className="font-semibold capitalize flex text-xl w-max mt-1"
                        key={i}
                        onClose={() => {
                          if (filter.type === "price") {
                            resetPriceFilter();
                          } else if (filter.type === "keyword") {
                            dispatch(setSearchKeyword(undefined));
                            navigate({
                              pathname: "/products/allproducts",
                            });
                            dispatch(clearKeywordFilter());
                          }
                        }}
                      >
                        {filter.keyword}
                      </Tag>
                    ))}
                  </p>
                )}
              </div>
              <Select
                onChange={(e) => {
                  dispatch(sortByPrice(e));
                }}
                defaultValue={"default"}
                className="lg:w-96 sm:w-96 w-full sorting"
                options={[
                  { value: "default", label: "Default Sorting" },
                  { value: "priceHigh", label: "Sort By Price: High to Low " },
                  { value: "priceLow", label: "Sort By Price: Low to High " },
                ]}
              />
            </div>
            {width < 1024 && (
              <div className="flex flex-col w-full">
                <h2 className="capitalize text-4xl font-semibold text-center sm:text-left text-gray_primary">
                  Filter by price
                </h2>
                <Slider
                  styles={{
                    track: {
                      background: "transparent",
                    },
                    tracks: {
                      background: `#333399`,
                    },
                  }}
                  onChange={(value: number[]) => {
                    setSliderValue({
                      min: value[0],
                      max: value[1],
                    });
                  }}
                  range={{ draggableTrack: true }}
                  defaultValue={[sliderValue.min, sliderValue.max]}
                  value={[sliderValue.min, sliderValue.max]}
                  max={highestPrice}
                  step={10}
                />
                <div className="flex items-start flex-col gap-5">
                  <div className="text-2xl flex w-full justify-center sm:justify-start">
                    <p className="mr-5 text-gray_primary">Price:</p>
                    <div className="font-semibold flex gap-1">
                      <p>{sliderValue.min} EGP</p>
                      <p>-</p>
                      <p>{sliderValue.max} EGP</p>
                    </div>
                  </div>
                  <div className="flex gap-5 items-center justify-center sm:justify-start w-full">
                    <Button
                      onClick={() => {
                        resetPriceFilter();
                      }}
                    >
                      Reset
                    </Button>
                    <Button
                      onClick={() => {
                        dispatch(
                          filterPrice({
                            min: sliderValue.min,
                            max: sliderValue.max,
                          })
                        );
                      }}
                      className="filterButton"
                    >
                      Filter
                    </Button>
                  </div>
                </div>
              </div>
            )}
            {activeFilters?.length > 0 && width < 1024 && (
              <p className="flex gap-2 text-2xl justify-center sm:justify-start">
                Active Filters:{" "}
                {activeFilters.map((filter: any, i) => (
                  <Tag
                    closable={true}
                    className="font-semibold capitalize flex text-xl w-max "
                    key={i}
                    onClose={() => {
                      if (filter.type === "price") {
                        resetPriceFilter();
                      } else if (filter.type === "keyword") {
                        dispatch(setSearchKeyword(undefined));
                        navigate({
                          pathname: "/products/allproducts",
                        });
                        dispatch(clearKeywordFilter());
                      }
                    }}
                  >
                    {filter.keyword}
                  </Tag>
                ))}
              </p>
            )}
            {width < 1024 && (
              <div>
                <Collapse
                  ghost
                  style={{
                    width: "max-content !important",
                  }}
                  expandIcon={() => {
                    return undefined;
                  }}
                  expandIconPosition="end"
                  items={[
                    {
                      label: (
                        <div
                          className="flex items-center w-full justify-center gap-5 border-1 border-main_color_darker2 rounded-2xl
                        py-3"
                        >
                          <h2 className="capitalize text-4xl font-semibold text-center sm:text-left text-gray_primary">
                            Categories
                          </h2>
                          <CaretDownOutlined className="text-3xl mt-2" />
                        </div>
                      ),
                      key: "1",
                      children: (
                        <div className="flex flex-col gap-5">
                          {footerRoutes.map((route) => {
                            if (route.shouldAppearInCategories === false)
                              return null;

                            let className: string = `flex justify-between items-center text-2xl `;
                            if (location.pathname === route.route) {
                              className = `flex justify-between items-center text-2xl text-main_color_darker `;
                            }
                            if (route.key === "allproducts") {
                              className = className + `-order-1`;
                            }

                            return (
                              <div key={route.key} className={className}>
                                <Link
                                  to={route.route}
                                  className={
                                    location.pathname === route.route
                                      ? ""
                                      : "cursor-pointer hover:text-main_color_darker"
                                  }
                                >
                                  {route.title}
                                </Link>
                                <p>({countProductsByCategory(route.key)})</p>
                              </div>
                            );
                          })}
                        </div>
                      ),
                    },
                  ]}
                />
                {/* <h2 className="capitalize text-4xl font-semibold flex flex-col mb-10">
                  Categories
                </h2>
                <div className="flex flex-col gap-5">
                  {footerRoutes.map((route) => {
                    if (route.route === "/") return null;

                    let className: string = `flex justify-between items-center text-2xl `;
                    if (location.pathname === route.route) {
                      className = `flex justify-between items-center text-2xl text-main_color_darker `;
                    }
                    if (route.key === "allproducts") {
                      className = className + `-order-1`;
                    }

                    return (
                      <div key={route.key} className={className}>
                        <Link
                          to={route.route}
                          className={
                            location.pathname === route.route
                              ? ""
                              : "cursor-pointer hover:text-main_color_darker"
                          }
                        >
                          {route.title}
                        </Link>
                        <p>({countProductsByCategory(route.key)})</p>
                      </div>
                    );
                  })}
                </div> */}
              </div>
            )}
            {loadingProducts ? (
              <div className="flex items-center justify-center h-[20vh] mx-auto">
                <LoadingOutlined className="text-7xl" />
              </div>
            ) : (
              <div className="w-full grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-20 ">
                <Outlet />
              </div>
            )}
            <Pagination
              onChange={(e) => {
                dispatch(pagination({ pageNumber: e }));
              }}
              className="mx-auto"
              defaultCurrent={1}
              current={pageNumebr}
              pageSize={12}
              total={productsNumber}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
