import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clearSuccessMessage, setSuccessMessage } from "./successSlice";
import { ErrorType, clearError, setError } from "./errorSlice";

export type FetchResponse = {
  message: string;
  error?: ErrorType;
  categories: { category: string; id: string }[];
  success: boolean;
};

type CategoriesState = {
  loading: boolean;
  addButtonLoading: boolean;
  removeButtonLoading: boolean;
  categories: { category: string; id: string }[];
};
const initialState: CategoriesState = {
  loading: true,
  addButtonLoading: false,
  removeButtonLoading: false,
  categories: [],
};

const getCategories = createSlice({
  name: "categories",
  initialState: initialState,
  reducers: {
    setCategories(
      state,
      action: PayloadAction<{ category: string; id: string }[]>
    ) {
      state.categories = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCategoriesThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getCategoriesThunk.fulfilled,
      (state, action: PayloadAction<FetchResponse>) => {
        state.loading = false;
        if (action.payload.error) {
          state.categories = [];
          return;
        }
        state.categories = action.payload.categories;
      }
    );
    builder.addCase(getCategoriesThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(addCategoryThunk.pending, (state) => {
      state.addButtonLoading = true;
    });
    builder.addCase(
      addCategoryThunk.fulfilled,
      (state, action: PayloadAction<FetchResponse>) => {
        state.addButtonLoading = false;
        if (action.payload.error) {
          // state.categories = [];
          return;
        }
        state.categories = action.payload.categories;
      }
    );
    builder.addCase(addCategoryThunk.rejected, (state) => {
      state.addButtonLoading = false;
    });
    builder.addCase(removeCategoryThunk.pending, (state) => {
      state.removeButtonLoading = true;
    });
    builder.addCase(
      removeCategoryThunk.fulfilled,
      (state, action: PayloadAction<FetchResponse>) => {
        state.removeButtonLoading = false;
        if (action.payload.error) {
          return;
        }
        state.categories = action.payload.categories;
      }
    );
    builder.addCase(removeCategoryThunk.rejected, (state) => {
      state.removeButtonLoading = false;
    });
  },
});
export const addCategoryThunk = createAsyncThunk(
  "categories/addCategory",
  async (category: string, { dispatch }) => {
    const data: FetchResponse = await fetch(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/admin/add-category`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          category,
        }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data: FetchResponse) => {
        if (data.error) {
          throw data;
        }
        dispatch(setSuccessMessage(data.message));
        dispatch(clearError());
        return data;
      })
      .catch((err) => {
        dispatch(clearSuccessMessage());
        dispatch(setError(err));
        return err;
      });

    return data;
  }
);
export const removeCategoryThunk = createAsyncThunk(
  "categories/removeCategory",
  async (id: string, { dispatch }) => {
    const data: FetchResponse = await fetch(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/admin/remove-category`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          id,
        }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data: FetchResponse) => {
        if (data.error) {
          throw data;
        }
        dispatch(setSuccessMessage(data.message));
        dispatch(clearError());
        return data;
      })
      .catch((err) => {
        dispatch(clearSuccessMessage());
        dispatch(setError(err));
        return err;
      });

    return data;
  }
);

export const getCategoriesThunk = createAsyncThunk(
  "categories/getCategoriesThunk",
  async (_, { dispatch }) => {
    const data: FetchResponse = await fetch(
      `${process.env.REACT_APP_BACKEND_DOMAIN}/admin/get-categories`,
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data: FetchResponse) => {
        if (data.error) {
          throw data;
        }

        dispatch(clearError());
        return data;
      })
      .catch((err) => {
        dispatch(setError(err));
        return err;
      });

    return data;
  }
);

// export const { logOut } = getCategories.actions;
export const categoriesReducer = getCategories.reducer;
