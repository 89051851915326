import { Button, Form, Input } from "antd";
import {
  useAppDispatch,
  useAppSelector,
} from "../../hooks/useAppDispatchSelector";
import {
  clearErrorMessage,
  forgetPasswordThunk,
  resetSuccessVariable,
} from "../state/userResetPasswordSlice";
import { BackendStringResponses } from "../../util/constants";
import { useEffect } from "react";
import { CheckOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
const ForgetPasswordForm = () => {
  const [forgetPassword] = Form.useForm();
  const dispatch = useAppDispatch();
  const errorMessage = useAppSelector(
    (state) => state.userResetPasswordReducer.errorMessage
  );
  const loading = useAppSelector(
    (state) => state.userResetPasswordReducer.loadingForgetPassword
  );
  const navigate = useNavigate();
  const success = useAppSelector(
    (state) => state.userResetPasswordReducer.successForgetPassword
  );
  useEffect(() => {
    dispatch(resetSuccessVariable());
  }, []);

  const submitForm = () => {
    forgetPassword
      .validateFields()
      .then(async (values: { email: string }) => {
        dispatch(
          forgetPasswordThunk({
            email: values.email,
          })
        );
      })
      .catch((err) => {});
  };
  return (
    <div>
      <p className="text-center text-4xl font-semibold capitalize mb-20">
        Forgot password
      </p>
      {success ? (
        <div className="flex flex-col items-center bg-gray-50 rounded-lg py-10 gap-5  mx-auto mt-44">
          <div className="flex items-center justify-center border p-3 rounded-full border-blue_primary">
            <CheckOutlined className="text-5xl text-blue_primary" />
          </div>
          <p className="text-4xl font-semibold capitalize">Email Sent</p>
          <p className="text-2xl font text-center">
            Please check your email to reset your password
          </p>
          <Button
            onClick={() => {
              navigate("/products/allproducts");
            }}
            className="bg-blue_primary text-white flex items-center justify-between gap-3 w-max "
          >
            <p>Shop Now</p>
          </Button>
        </div>
      ) : (
        <Form form={forgetPassword} onFinish={submitForm}>
          <div className="flex flex-col w-full justify-between relative">
            <Form.Item
              labelCol={{ span: 24 }}
              name={"email"}
              className="flex-1"
              label="E-Mail"
              required
              validateTrigger="onBlur"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Email field is required ",
                },
              ]}
            >
              <Input
                onChange={() => {
                  dispatch(clearErrorMessage());
                }}
                type="email"
                placeholder="Password"
                className="formInput !rounded-lg"
              />
            </Form.Item>
            {errorMessage &&
              (errorMessage === BackendStringResponses.FORGET_EMAIL_NOT_EXIST ||
                errorMessage ===
                  BackendStringResponses.FORGET_PROVIDE_EMAIL) && (
                <p className=" absolute bottom-0 text-lg text-red_primary">
                  Invalid email or account does not exist
                </p>
              )}
          </div>
          <Button
            loading={loading}
            className=" px-10 w-max flex items-center justify-center relative z-10 h-full bg-none border-none text-white hover:!text-white disabled:!text-[rgba(0,0,0,0.25)] text-lg font-medium cursor-pointer rounded-3xl bg-blue_primary py-5 hover:!bg-blue_primary disabled:!bg-[rgba(0,0,0,0.04)] mx-auto "
            onClick={() => {
              forgetPassword.submit();
            }}
          >
            <p className=" text-2xl">Reset Password</p>
          </Button>
        </Form>
      )}
    </div>
  );
};
export default ForgetPasswordForm;
