import { Button, Form, FormInstance, Input } from "antd";
import React, { useEffect, useState } from "react";
import {
  setopenLoginPopup,
  userCreate,
  userLogin,
  userResendOtp,
  userVerifyOtp,
} from "../state/userAuthSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../hooks/useAppDispatchSelector";
import { BackendStringResponses } from "../../util/constants";
import { useNavigate } from "react-router-dom";

const UserRegistrationForm: React.FC<{
  setShowOtp: React.Dispatch<React.SetStateAction<boolean>>;
  showOtp: boolean;
  loginForm: FormInstance<any>;
  signupForm: FormInstance<any>;
  setIsLogin: React.Dispatch<React.SetStateAction<boolean>>;
  isLogin: boolean;
  redirectTo?: string;
}> = ({
  loginForm,
  signupForm,
  setShowOtp,
  showOtp,
  setIsLogin,
  isLogin,
  redirectTo,
}) => {
  const [countdown, setCountdown] = useState(30); // 30 seconds countdown
  const [isDisabled, setIsDisabled] = useState(true); // Disable the resend button initially
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isLogin) {
      signupForm.resetFields();
      setCountdown(30);
    } else {
      loginForm.resetFields();
      setCountdown(30);
    }
    setShowOtp(false);
  }, [isLogin]);

  const {
    errorMessage,
    loginLoading,
    verifyOtpLoading,
    successMessage,
    signupLoading,
    userAuthenticated,
  } = useAppSelector((state) => state.userAuthenticationReducer);

  useEffect(() => {
    if (
      errorMessage === BackendStringResponses.ACTIVATION_ERROR ||
      successMessage === BackendStringResponses.USER_CREATED_WITHOUT_ACTIVATION
    ) {
      setShowOtp(true);
    }
  }, [errorMessage, successMessage]);
  useEffect(() => {
    if (userAuthenticated) {
      redirectTo && navigate(redirectTo);
      dispatch(setopenLoginPopup({ state: false }));
    }
  }, [userAuthenticated]);

  useEffect(() => {
    if (showOtp) {
      if (countdown > 0) {
        const timer = setTimeout(() => setCountdown(countdown - 1), 1000); // Decrease the countdown every second
        return () => clearTimeout(timer); // Clear the timer on component unmount
      } else {
        setIsDisabled(false); // Enable the button once the countdown reaches 0
      }
    }
  }, [countdown, showOtp]);

  const handleResend = () => {
    if (!isDisabled) {
      loginForm
        .validateFields()
        .then(async (values: { login_email: string }) => {
          dispatch(userResendOtp({ email: values.login_email }));
        })
        .catch();
      // Logic to resend OTP
      setCountdown(30); // Reset countdown after resending
      setIsDisabled(true); // Disable the button again
    }
  };
  const handleLogin = () => {
    loginForm
      .validateFields()
      .then(async (values: { login_email: string; login_password: string }) => {
        await dispatch(
          userLogin({
            email: values.login_email,
            password: values.login_password,
          })
        );
      })
      .catch((err) => {});
  };
  const handleVerifyOtp = () => {
    loginForm
      .validateFields()
      .then(async (values: { login_email: string; otp: string }) => {
        if (!values.otp) return;

        await dispatch(
          userVerifyOtp({
            email: values.login_email,
            otp: values.otp,
          })
        );
      })
      .catch((err) => {});
  };
  const handleVerifyOtpForSignup = () => {
    signupForm
      .validateFields()
      .then(async (values: { signup_email: string; otp: string }) => {
        if (!values.otp) return;

        await dispatch(
          userVerifyOtp({
            email: values.signup_email,
            otp: values.otp,
          })
        );
      })
      .catch((err) => {});
  };
  const handleUserCreate = () => {
    signupForm
      .validateFields()
      .then(
        async (values: {
          signup_name: string;
          signup_email: string;
          signup_password: string;
          signup_confirm_password: string;
        }) => {
          await dispatch(
            userCreate({
              name: values.signup_name,
              email: values.signup_email,
              password: values.signup_password,
              confirmPassword: values.signup_confirm_password,
            })
          );
        }
      )
      .catch();
  };
  return (
    <div className="flex md:w-[500px]  items-center justify-center">
      <div className="justify-center rounded-lg overflow-hidden">
        <div
          className="title-text flex w-[200%] transition-transform duration-[0.6s] ease-[cubic-bezier(0.68,-0.55,0.265,1.55)]"
          style={{ transform: isLogin ? "translateX(0)" : "translateX(-50%)" }}
        >
          <div className="title w-1/2 text-3xl font-semibold text-center">
            Welcome Back
          </div>
          <div className="title w-1/2 text-3xl font-semibold text-center">
            Create your new account
          </div>
        </div>
        <div className="h-max">
          <div className="flex justify-between h-[40px] mt-8 mb-2 border border-gray-300 rounded-[15px] relative overflow-hidden">
            <label
              onClick={() => setIsLogin(true)}
              className={`text-2xl flex items-center justify-center w-full text-center  cursor-pointer transition-all duration-[0.6s] z-[2] ${
                isLogin ? "text-white" : "text-black"
              }`}
            >
              Login
            </label>
            <p
              onClick={() => setIsLogin(false)}
              className={`flex items-center justify-center w-full text-center ursor-pointer transition-all duration-[0.6s] z-[2] text-2xl cursor-pointer ${
                isLogin ? "text-black" : "text-white"
              }`}
            >
              Sign up
            </p>
            <div
              className="absolute h-full w-1/2 bg-blue_primary rounded-[15px] transition-all duration-[0.6s] ease-[cubic-bezier(0.68,-0.55,0.265,1.55)]"
              style={{ left: isLogin ? "0%" : "50%" }}
            />
          </div>
          <div
            className="flex gap-1 w-[200%] transition-transform duration-[0.6s] ease-[cubic-bezier(0.68,-0.55,0.265,1.55)]"
            style={{
              transform: isLogin ? "translateX(0)" : "translateX(-50%)",
            }}
          >
            <Form form={loginForm} onFinish={handleLogin} className="w-1/2">
              <div className=" mb-2">
                <Form.Item
                  className="formLabel"
                  labelCol={{ span: 24 }}
                  name={"login_email"}
                  label="E-Mail"
                  required
                  validateTrigger="onBlur"
                  rules={[
                    {
                      type: "email",
                      required: true,
                      message: "Email Adress is required ",
                    },
                  ]}
                >
                  <div className="relative">
                    <Input
                      disabled={!isLogin || showOtp}
                      placeholder="example@gmail.com"
                      className="formInput "
                    />
                    {errorMessage &&
                      errorMessage === BackendStringResponses.EMAIL_ERROR && (
                        <p className="text-red-600">
                          Invalid email or account does not exist
                        </p>
                      )}
                  </div>
                </Form.Item>
              </div>
              <div className=" mb-2">
                <Form.Item
                  className="formLabel"
                  labelCol={{ span: 24 }}
                  name={"login_password"}
                  label="Password"
                  required
                  validateTrigger="onBlur"
                  rules={[
                    {
                      required: true,
                      message: "Password is required ",
                    },
                  ]}
                >
                  <div>
                    <Input.Password
                      disabled={!isLogin || showOtp}
                      placeholder="password"
                      className="formInput "
                    />
                    {errorMessage &&
                      errorMessage ===
                        BackendStringResponses.PASSWORD__ERROR && (
                        <p className="text-red-600">
                          Incorrect password. Please try again.
                        </p>
                      )}
                  </div>
                </Form.Item>
              </div>
              {showOtp && (
                <Form.Item
                  className="formLabel"
                  labelCol={{ span: 24 }}
                  name={"otp"}
                  label={
                    <p className="text-blue_primary">
                      An OTP has been sent to your email. Didn’t receive it?
                      Check your spam folder or click to resend.
                    </p>
                  }
                >
                  <div className="w-full flex flex-col gap-1">
                    <div className="w-full flex gap-5">
                      <Input
                        placeholder="Please enter the OTP"
                        className="formInput"
                        type="number"
                      />
                      <Button
                        loading={verifyOtpLoading}
                        className=" px-10 w-max flex items-center justify-center relative z-10 h-full bg-none border-none text-white hover:!text-white disabled:!text-[rgba(0,0,0,0.25)] text-lg font-medium cursor-pointer rounded-3xl bg-blue_primary py-5 hover:!bg-blue_primary disabled:!bg-[rgba(0,0,0,0.04)]"
                        onClick={() => {
                          handleVerifyOtp();
                        }}
                      >
                        <p className=" text-2xl">Verify</p>
                      </Button>
                      <div
                        className={`w-max flex items-center ${
                          isDisabled
                            ? "cursor-not-allowed text-gray-400"
                            : "cursor-pointer text-blue_primary"
                        }`}
                        onClick={handleResend}
                      >
                        <p className="w-max">
                          {isDisabled
                            ? `Resend in ${countdown} seconds`
                            : "Resend OTP"}
                        </p>
                      </div>
                    </div>
                    {errorMessage === BackendStringResponses.INVALID_OTP && (
                      <p className="text-red-600">
                        Incorrect OTP, Please try again
                      </p>
                    )}
                    {errorMessage === BackendStringResponses.EXPIRED_OTP && (
                      <p className="text-red-600">
                        OTP expired, Please resend another one
                      </p>
                    )}
                  </div>
                </Form.Item>
              )}
              <div className="pass-link mb-2">
                <div
                  className="text-blue_primary hover:underline cursor-pointer"
                  onClick={() => {
                    dispatch(setopenLoginPopup({ state: false }));
                    navigate("/forget-password");
                  }}
                >
                  <p> Forgot password?</p>
                </div>
              </div>
              <div className=" btn mb-2 relative overflow-hidden">
                <Button
                  onClick={() => {
                    loginForm.submit();
                  }}
                  disabled={showOtp ? true : false}
                  loading={loginLoading}
                  className="flex items-center justify-center relative z-10 w-full h-full bg-none border-none text-white hover:!text-white disabled:!text-[rgba(0,0,0,0.25)] text-lg font-medium cursor-pointer rounded-xl bg-blue_primary py-5 hover:!bg-blue_primary disabled:!bg-[rgba(0,0,0,0.04)]"
                >
                  <p className="text-2xl">Login</p>
                </Button>
              </div>
              <div className="signup-link text-center">
                Not a member?{" "}
                <span
                  onClick={() => setIsLogin(false)}
                  className="cursor-pointer text-blue_primary hover:underline"
                >
                  Signup now
                </span>
              </div>
            </Form>
            <Form
              onFinish={handleUserCreate}
              form={signupForm}
              className="w-1/2"
            >
              <div className="mb-2">
                <Form.Item
                  className="formLabel"
                  labelCol={{ span: 24 }}
                  name={"signup_name"}
                  label="User Name"
                  required
                  validateTrigger="onBlur"
                  rules={[
                    {
                      required: true,
                      message: "User name is required ",
                    },
                  ]}
                >
                  <div>
                    <Input
                      placeholder="your name"
                      className="formInput "
                      disabled={isLogin || showOtp}
                    />
                    {errorMessage &&
                      errorMessage ===
                        BackendStringResponses.EMPTY_USERNAME && (
                        <p className="text-red-600">
                          Invalid eUsername cannot be empty.
                        </p>
                      )}
                    {errorMessage &&
                      errorMessage === BackendStringResponses.USER_EXISTS && (
                        <p className="text-red-600">
                          User already exists, please login
                        </p>
                      )}
                  </div>
                </Form.Item>
              </div>
              <div className="mb-2">
                <Form.Item
                  className="formLabel"
                  labelCol={{ span: 24 }}
                  name={"signup_email"}
                  label="E-Mail"
                  required
                  validateTrigger="onBlur"
                  rules={[
                    {
                      type: "email",
                      required: true,
                      message: "Email Address is required ",
                    },
                  ]}
                >
                  <div>
                    <Input
                      placeholder="example@gmail.com"
                      className="formInput "
                      disabled={isLogin || showOtp}
                    />
                    {errorMessage &&
                      errorMessage ===
                        BackendStringResponses.INVALID_EMAIL_FORMAT && (
                        <p className="text-red-600">
                          Email Address is required
                        </p>
                      )}
                  </div>
                </Form.Item>
              </div>
              <div className="mb-2">
                <Form.Item
                  className="formLabel"
                  labelCol={{ span: 24 }}
                  name={"signup_password"}
                  label="Password"
                  required
                  validateTrigger="onBlur"
                  rules={[
                    {
                      required: true,
                      message: "Password is required ",
                    },
                  ]}
                >
                  <div>
                    <Input.Password
                      placeholder="password"
                      className="formInput "
                      disabled={isLogin || showOtp}
                    />
                    {errorMessage &&
                      errorMessage ===
                        BackendStringResponses.SHORT_PASSWORD && (
                        <p className="text-red-600">
                          Password must include at least one letter, one number,
                          and one special character
                        </p>
                      )}
                  </div>
                </Form.Item>
              </div>
              <div className="mb-2">
                <Form.Item
                  className="formLabel"
                  labelCol={{ span: 24 }}
                  name={"signup_confirm_password"}
                  label="Confirm Password"
                  required
                  validateTrigger="onBlur"
                  rules={[
                    {
                      required: true,
                      message: "Password is required ",
                    },
                  ]}
                >
                  <div>
                    <Input.Password
                      disabled={isLogin || showOtp}
                      placeholder="password"
                      className="formInput "
                    />
                    {errorMessage &&
                      errorMessage ===
                        BackendStringResponses.PASSWORD_MISMATCH && (
                        <p className="text-red-600">Passwords do not match</p>
                      )}
                  </div>
                </Form.Item>
              </div>
              {showOtp && (
                <Form.Item
                  className="formLabel"
                  labelCol={{ span: 24 }}
                  name={"otp"}
                  label={
                    <p className="text-blue_primary">
                      An OTP has been sent to your email. Didn’t receive it?
                      Check your spam folder or click to resend.
                    </p>
                  }
                >
                  <div className="w-full flex flex-col gap-1">
                    <div className="w-full flex gap-5">
                      <Input
                        placeholder="Please enter the OTP"
                        className="formInput"
                        type="number"
                      />
                      <Button
                        loading={verifyOtpLoading}
                        className=" px-10 w-max flex items-center justify-center relative z-10 h-full bg-none border-none text-white hover:!text-white disabled:!text-[rgba(0,0,0,0.25)] text-lg font-medium cursor-pointer rounded-3xl bg-blue_primary py-5 hover:!bg-blue_primary disabled:!bg-[rgba(0,0,0,0.04)]"
                        onClick={() => {
                          handleVerifyOtpForSignup();
                        }}
                      >
                        <p className=" text-2xl">Verify</p>
                      </Button>
                      <div
                        className={`w-max flex items-center ${
                          isDisabled
                            ? "cursor-not-allowed text-gray-400"
                            : "cursor-pointer text-blue_primary"
                        }`}
                        onClick={handleResend}
                      >
                        <p className="w-max">
                          {isDisabled
                            ? `Resend in ${countdown} seconds`
                            : "Resend OTP"}
                        </p>
                      </div>
                    </div>
                    {errorMessage === BackendStringResponses.INVALID_OTP && (
                      <p className="text-red-600">
                        Incorrect OTP, Please try again
                      </p>
                    )}
                    {errorMessage === BackendStringResponses.EXPIRED_OTP && (
                      <p className="text-red-600">
                        OTP expired, Please resend another one
                      </p>
                    )}
                  </div>
                </Form.Item>
              )}
              <div className="btn relative overflow-hidden">
                <Button
                  loading={signupLoading}
                  disabled={showOtp ? true : false}
                  className="flex items-center justify-center relative z-10 w-full h-full bg-none border-none text-white hover:!text-white disabled:!text-[rgba(0,0,0,0.25)] text-lg font-medium cursor-pointer rounded-xl bg-blue_primary py-5 hover:!bg-blue_primary disabled:!bg-[rgba(0,0,0,0.04)] mt-4"
                  onClick={() => {
                    signupForm.submit();
                  }}
                >
                  <p className="text-2xl">Sign up</p>
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserRegistrationForm;

// TODO: implement the otp verification in signup form
