import { Form, Modal } from "antd";
import UserRegistrationForm from "../components/common/registeration";
import { useEffect, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../hooks/useAppDispatchSelector";
import { setopenLoginPopup } from "../components/state/userAuthSlice";

type UserRegisterProccessModalProps = {
  open: boolean;

  redirectTo?: string;
};

export default function UserRegisterProccessModal({
  open,
  redirectTo,
}: UserRegisterProccessModalProps) {
  const [loginForm] = Form.useForm();
  const [signupForm] = Form.useForm();
  const [showOtp, setShowOtp] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const dispatch = useAppDispatch();
  const { loginLoading, signupLoading } = useAppSelector(
    (state) => state.userAuthenticationReducer
  );
  useEffect(() => {
    if (!open) {
      loginForm.resetFields();
      signupForm.resetFields();
      setShowOtp(false);
      setIsLogin(true);
    }
  }, [open]);
  return (
    <Modal
      onCancel={() => {
        if (loginLoading || signupLoading) return;
        dispatch(setopenLoginPopup({ state: false }));
      }}
      footer={<></>}
      closable={true}
      open={loginLoading || signupLoading ? true : open}
      className="sm:top-96 top-56 !w-max"
      style={{
        padding: "0 !important",
      }}
    >
      <div className="relative">
        <UserRegistrationForm
          setShowOtp={setShowOtp}
          setIsLogin={setIsLogin}
          isLogin={isLogin}
          showOtp={showOtp}
          signupForm={signupForm}
          loginForm={loginForm}
          redirectTo={redirectTo}
        />
      </div>
    </Modal>
  );
}
